import styled from 'styled-components';
import { devices } from 'theme';

export const CardContainer = styled.div`
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;

  display: flex;
  font-size: small;

  @media ${devices.md} {
    font-size: 1em;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;
export const CardHeader = styled.h4`
  margin-top: 0;
`;

export const CardMain = styled.div`
  flex-grow: 1;
`;
export const CardFooter = styled.div``;
export const CardImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

export const CardImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  @media ${devices.md} {
    margin-right: 20px;
  }
`;
