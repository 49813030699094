import FormInput from 'components/FormInput';
import jsPDF from 'jspdf';
import { DownloadButton } from 'pages/Dashboard/Inventory/StyledInventory';
import {
  ItemImage,
  ItemImageContainer,
} from 'pages/Dashboard/Inventory/StyledItemDisplay';
import React, { useState } from 'react';
import { DoubleItems } from 'shared/styles';

const View = ({ item }) => {
  const [url, setUrl] = useState(null);

  let doc = new jsPDF('l');

  (async function () {
    let blob = await fetch(
      `${item.barcode_url.replace('http:', 'https:')}`
    ).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    // now do something with `dataUrl`
    setUrl(dataUrl);
  })();

  if (url) {
    doc.setProperties({
      title: `Barcode details for ${item.name}`,
      author: 'A & N Nursery',
      keywords: 'Nursery',
      creator: 'A & N Nursery',
    });

    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(55);
    doc.text(`${item.name}`, pageWidth / 2, 20, 'center');

    doc.setFontSize(30);
    doc.text(`${item.botanical_name}`, pageWidth / 2, 40, 'center');

    doc.setFontSize(20);
    doc.text(`${item.size}`, pageWidth / 2, 60, 'center');
    doc.addImage(url, 'JPEG', (pageWidth - 90) / 2, 90, 90, 90);
  }

  const saveDoc = (e) => {
    e.preventDefault();
    doc.save(`${item.name}.pdf`);
  };
  // console.log(item && typeof item.image);

  return (
    <>
      {item && (
        <div>
          <ItemImageContainer>
            {item.image && !item.image.includes('undefined') && (
              <ItemImage
                src={`https://res.cloudinary.com/iyanuashiri/${item.image}`}
                alt=""
              />
            )}
          </ItemImageContainer>
          <form>
            {url && (
              <DownloadButton onClick={saveDoc}>
                Download barcode
              </DownloadButton>
            )}
            {/* <DoubleItems> */}
            <FormInput
              label="Product Name"
              type="name"
              inputValue={item.name}
              setInputValue={(value) => {}}
              disabled
            />
            <FormInput
              label="Botanical Name"
              type="name"
              inputValue={item.botanical_name}
              setInputValue={(value) => {}}
              disabled
            />
            <FormInput
              label="Price"
              type="name"
              inputValue={`$${item.price}`}
              setInputValue={(value) => {}}
              disabled
            />
            {/* </DoubleItems> */}

            <DoubleItems>
              <FormInput
                label="Size"
                type="name"
                inputValue={item.size}
                setInputValue={(value) => {}}
                disabled
              />
              <FormInput
                label="Color"
                type="name"
                inputValue={item.color}
                setInputValue={(value) => {}}
                disabled
              />
            </DoubleItems>

            {/* <DoubleItems> */}
            <FormInput
              label="Classification"
              type="name"
              inputValue={item.classification.name || item.classification}
              setInputValue={(value) => {}}
              disabled
            />
            <FormInput
              label="Category"
              type="name"
              inputValue={item.category.name || item.category}
              setInputValue={(value) => {}}
              disabled
            />
          </form>
        </div>
      )}{' '}
    </>
  );
};

export default View;
