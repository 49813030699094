import styled from "styled-components";

// Theming
import { devices } from "../../theme";

export const NavbarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  /* border: solid red 1px; */

  & > img {
    width: 30px;
  }
  & > img:first-of-type {
    width: 40px;
  }

  @media ${devices.lg} {
    display: none;
  }
`;
