import React from 'react';
import {  Redirect, Route, Switch, withRouter } from 'react-router-dom';

import SalesError from './Error';
import Payment from './Payment';
import SalesScan from './Scan';
import Success from './Success';
import {
  SalesHeader,
  SalesContainer,
  SalesHeading,
  SalesConnectTerminal,
  SalesTerminalLink,
} from './styledSales';
import Report from './Report';

const SalesPage = ({ match: { url } }) => {
  return (
    <>
      <SalesHeading>
        <SalesHeader>Sales</SalesHeader>
        <SalesConnectTerminal>
          <SalesTerminalLink to="/dashboard/terminal">
            <span
              className="iconify"
              data-icon="majesticons:device-tablet-line"
              data-inline="false"
            ></span>
          </SalesTerminalLink>
          <SalesTerminalLink to="/dashboard/terminal">
            <span>Connect Terminal</span>
          </SalesTerminalLink>
        </SalesConnectTerminal>
      </SalesHeading>
      <SalesContainer>
        <Switch>
          <Route exact path={`${url}/scan`} component={SalesScan} />
          <Route exact path={`${url}/error`} component={SalesError} />
          <Route exact path={`${url}/payment`} component={Payment} />
          <Route exact path={`${url}/complete`} component={Success} />
          <Route exact path={`${url}/summary`} component={Report} />

          <Route
            exact
            path={`${url}`}
            render={() => <Redirect to={`${url}/scan`} />}
          />
        </Switch>
      </SalesContainer>
    </>
  );
};

export default withRouter(SalesPage);
