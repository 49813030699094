import styled from "styled-components";

// Theming
import { devices } from "../../../theme";

export const VendorsContainer = styled.div`
  /* border: solid red 1px; */
  & > h1 {
    width: max-content;
    font-size: 1.5em;
    margin: 0 auto;
    position: relative;
    top: -50px;
  }

  @media ${devices.lg} {
    & > h1 {
      top: 0;
      margin: 0;
      margin-right: auto;
      margin-bottom: 50px;
    }
  }
`;

export const VendorsMain = styled.div`
  /* border: solid red 1px; */
`;

export const VendorsTable = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const VendorsAdd = styled.div`
  width: 90%;
  margin: 0 auto;
  h2 {
    margin-bottom: 50px;
  }
  input {
    ${"" /* margin-bottom: 15px; */}
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 42px;
`;

export const ModalButton = styled.div`
  padding: 10px 20px;
  outline: none;
  border: solid 1px
    ${(props) => (props.isDelete ? "#c70000" : props.theme.colors.brandGreen)};
  border-radius: 6px;
  background-color: white;
  color: ${(props) =>
    props.isDelete ? "#c70000" : props.theme.colors.brandGreen};
  cursor: pointer;

  &:last-of-type {
    margin-left: 25px;
  }

  &:hover {
    background-color: ${(props) =>
      props.isDelete ? "#c70000" : props.theme.colors.brandGreen};
    color: white;
  }
`;
