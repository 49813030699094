export const BASE_URL = 'https://nursery-backend-api.herokuapp.com';

export const CATEGORIES = [
  'Annuals',
  'Cacti/ Succulents',
  'Evergreen Shrubs',
  'Flowering Shrubs',
  'Ornamental Grass/ Ground Covers',
  'Perennials',
  'Vines & Climbing Plants',
  'Tropicals',
  'Palm Trees',
  'Decidious Trees',
  'Evergreen Trees',
];

export const LOCATIONS = ['Primary', 'Holding'];

export const LOTS = [
  'GH3225',
  'FRT-RT',
  'FRT-LT',
  'FRT-RTNAT',
  'FRT-LTNAT',
  'FRT-MIDNAT',
  'FRT-MID1',
  'FRT-MID2',
  'REAR-RT30G',
  'REAR-LT30G',
  'REAR-65G',
  'REAR-FQ OFFICE',
  'REAR-MID (WFA)',
  'REAR-45G',
  'MID- Trop/Palms',
  'HLD3148',
  'HLD3225',
];

export const COMPANY_TYPES = [
  { name: 'Retail', id: 'retail' },
  { name: 'Wholesale', id: 'wholesale' },
];

export const SANDBOX_APPLICATION_ID = 'sandbox-sq0idb-cHb1E4eVb2T3SucUggFBzA';
export const SANDBOX_LOCATION_ID = 'L995274NMWGSX';

export const PRODUCTION_APPLICATION_ID = 'sq0idp-ksqiKEOMpeWoykklqSBY8A';
export const PRODUCTION_LOCATION_ID = 'L0B1BBHKYX6RX';
export const PRODUCTION_ACCESS_TOKEN =
  'EAAAEA-wNC2hDEEH9N26cD05Im8YErIgovC-AKH77p_-t9rv0eX2GlajrAhM9rTF';
