import React, { useContext, useState } from 'react';
import Notiflix from 'notiflix';
import { NotificationManager } from 'react-notifications';
import ImageUploading from 'react-images-uploading';
import { jsPDF } from 'jspdf';

// components
import Dropdown from 'components/Dropdown';
import FormInput from 'components/FormInput';
import { Button, DoubleItems, RightFooter } from 'shared/styles';

// helpers
import { deleteInventoryItem, editInventoryItem } from './api';
import { AppContext } from 'context/store';
import { customError } from 'shared/helpers';
import {
  DownloadButton,
  RemoveButton,
  UpdateButton,
  UploadButton,
} from './StyledInventory';

const EditInventoryItem = ({ toEdit, closeModal, fetchInventory }) => {
  const [url, setUrl] = useState('null');
  const [image, setImage] = React.useState([]);

  // context
  const {
    store: {
      token,
      totalCategories,
      totalClassifications,
      totalLots,
      totalLocations,
      user,
    },
  } = useContext(AppContext);

  let doc = new jsPDF('l');

  (async function () {
    let blob = await fetch(
      `${toEdit.barcode_url.replace('http:', 'https:')}`
    ).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    // now do something with `dataUrl`
    setUrl(dataUrl);
  })();

  if (url) {
    doc.setProperties({
      title: `Barcode details for ${toEdit.name}`,
      author: 'A & N Nursery',
      keywords: 'Nursery',
      creator: 'A & N Nursery',
    });

    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(55);
    doc.text(`${toEdit.name}`, pageWidth / 2, 20, 'center');

    doc.setFontSize(30);
    doc.text(`${toEdit.botanical_name}`, pageWidth / 2, 40, 'center');

    doc.setFontSize(20);
    doc.text(`${toEdit.size}`, pageWidth / 2, 60, 'center');
    doc.addImage(url, 'JPEG', (pageWidth - 90) / 2, 90, 90, 90);
  }

  const saveDoc = (e) => {
    e.preventDefault();
    doc.save(`${toEdit.name}.pdf`);
  };

  const [item, setItem] = useState(toEdit);

  const [loading, setLoading] = useState(false);

  const onImageUploadChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
  };

  // loading
  if (loading) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  const disableEditItem = loading || !item.name;

  const edit = (key, value) => {
    setItem({
      ...item,
      [key]: value,
    });
  };

  const editItem = async () => {
    setLoading(true);
    const {
      name,
      botanical_name,
      category,
      color,
      classification,
      quantity,
      lot,
      size,
      location,
      cost,
      price,
    } = item;
    const data = {
      name,
      botanical_name,
      category: category.id,
      color,
      classification: classification.id,
      quantity,
      lot: lot.id,
      size,
      location: location.id,
      cost,
      price,
    };

    const formData = new FormData();
    const newCost = Number(data.cost)
      ? Number(data.cost).toFixed(2)
      : data.cost;
    const newPrice = Number(data.price)
      ? Number(data.price).toFixed(2)
      : data.price;

    formData.append('name', data.name);
    formData.append('botanical_name', data.botanical_name);
    formData.append('category', data.category);
    formData.append('color', data.color);
    formData.append('classification', data.classification);
    formData.append('quantity', data.quantity);
    formData.append('lot', data.lot);
    formData.append('size', data.size);
    formData.append('location', data.location);
    formData.append('cost', newCost);
    formData.append('price', newPrice);

    image[0] && image[0].file && formData.append('image', image[0].file);

    try {
      await editInventoryItem(token, formData, item.id);
      setLoading(false);
      NotificationManager.success('Item successfully Edited', 'Success');
      closeModal();
      fetchInventory();
    } catch (e) {
      setLoading(false);
      customError(e);
      closeModal();
    }
  };

  async function startDelete() {
    setLoading(true);
    try {
      await deleteInventoryItem(token, item.id);
      setLoading(false);
      NotificationManager.success('Item successfully Deleted', 'Success');
      closeModal();
      fetchInventory();
    } catch (e) {
      setLoading(false);
      customError(e);
      closeModal();
    }
  }

  function deleteItem() {
    Notiflix.Confirm.Show(
      'Delete Item',
      'Do you want to completely delete this item?',
      'Yes',
      'No',
      () => {
        startDelete();
      },
      () => {}
    );
  }

  return (
    <form>
      {url && (
        <DownloadButton onClick={saveDoc}>Download barcode</DownloadButton>
      )}
      <DoubleItems>
        <FormInput
          label="Product Name"
          type="name"
          inputValue={item.name}
          setInputValue={(value) => {
            edit('name', value);
          }}
        />
        <FormInput
          label="Botanical Name"
          type="name"
          inputValue={item.botanical_name}
          setInputValue={(value) => {
            edit('botanical_name', value);
          }}
        />
      </DoubleItems>
      <DoubleItems>
        <Dropdown
          defaulty={item.classification.name}
          label="Classification"
          data={totalClassifications.map((item) => ({
            id: item.id,
            name: item.name,
          }))}
          func={(item) =>
            edit('classification', {
              id: item.id,
              name: item.name,
            })
          }
        />
        <Dropdown
          defaulty={item.category.name}
          label="Category"
          data={totalCategories.map((item) => ({
            id: item.id,
            name: item.name,
          }))}
          func={(item) =>
            edit('category', {
              id: item.id,
              name: item.name,
            })
          }
        />
      </DoubleItems>

      <FormInput
        label="Size"
        type="name"
        inputValue={item.size}
        setInputValue={(value) => {
          edit('size', value);
        }}
      />
      <FormInput
        label="Color"
        type="name"
        inputValue={item.color}
        setInputValue={(value) => {
          edit('color', value);
        }}
      />
      <FormInput
        label="Quantity"
        type="name"
        inputValue={item.quantity}
        setInputValue={(value) => {
          edit('quantity', value);
        }}
      />

      {user.user.user_type !== 'admin' && (
        <FormInput
          label="Price"
          type="name"
          inputValue={item.price}
          setInputValue={(value) => {
            edit('price', value);
          }}
        />
      )}

      <DoubleItems>
        <Dropdown
          defaulty={`${item.location.name || item.location || 'Location'}`}
          label="Location"
          data={totalLocations.map((item) => ({
            id: item.id,
            name: item.name,
          }))}
          func={(item) =>
            edit('location', {
              id: item.id,
              name: item.name,
            })
          }
        />

        <Dropdown
          defaulty={`${item.lot.name || item.lot || 'Lot'}`}
          label="Lot"
          data={totalLots.map((item) => ({
            id: item.id,
            name: item.name,
          }))}
          func={(item) =>
            edit('lot', {
              id: item.id,
              name: item.name,
            })
          }
        />
      </DoubleItems>

      {user.user.user_type === 'admin' && (
        <DoubleItems>
          <FormInput
            label="Cost"
            type="name"
            inputValue={item.cost}
            setInputValue={(value) => {
              edit('cost', value);
            }}
          />
          <FormInput
            label="Price"
            type="name"
            inputValue={item.price}
            setInputValue={(value) => {
              edit('price', value);
            }}
          />
        </DoubleItems>
      )}

      <h3>Update Image</h3>
      <ImageUploading
        multiple
        value={image}
        onChange={onImageUploadChange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <UploadButton
              style={isDragging ? { color: 'red' } : undefined}
              onClick={(e) => {
                e.preventDefault();
                onImageUpload();
              }}
              {...dragProps}
            >
              Click or Drop here
            </UploadButton>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <UpdateButton
                    onClick={(e) => {
                      e.preventDefault();
                      onImageUpdate(index);
                    }}
                  >
                    Update
                  </UpdateButton>
                  <RemoveButton
                    onClick={(e) => {
                      e.preventDefault();
                      onImageRemove(index);
                    }}
                  >
                    Remove
                  </RemoveButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

      <RightFooter>
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (!disableEditItem) {
              editItem();
            } else if (loading) {
              NotificationManager.warning('Please wait...');
            } else {
              NotificationManager.warning('All fields are required');
            }
          }}
        >
          Save
        </Button>
        <Button
          isDelete={true}
          onClick={(e) => {
            e.preventDefault();
            deleteItem();
          }}
        >
          Delete
        </Button>
      </RightFooter>
    </form>
  );
};

export default EditInventoryItem;
