import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import { NotificationManager } from 'react-notifications';

// External Libraries
import { useHistory } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

// Styled components
import {
  StyledContainer,
  StyledAside,
  StyledFooter,
  StyledFormHeader,
  StyledLogo,
  StyledMain,
  StyledSpacer,
  StyledFooterText,
  StyledHeading,
  StyledWrapper,
  CountryInput,
} from './StyledRegister';

// Components
import FormikInput from 'components/FormikInput';
import {
  ErrorContainer,
  FormErrorMessage,
  NormalButton,
  StyledLink,
} from 'shared/styles';

// Assets
import Logo from '@images/logo2.svg';

// API

import { RegisterSchema } from './registerSchema';
import { registerVendor } from './handleRegister';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import Dropdown from 'components/Dropdown';
import FormInput from 'components/FormInput';

const RegisterVendor = () => {
  const history = useHistory();

  // Component State
  const [formError, setFormError] = useState('');
  const [type, setType] = useState('');
  const [companyName, setCompanyName] = useState('');

  // Loader reference
  const ref = useRef(null);

  // registeration
  const handleRegister = async (values, setSubmitting) => {
    ref.current.continuousStart();
    registerVendor({
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phoneNumber,
      password: values.password,
      email: values.email,
      company: companyName,
      type,
    })
      .then(() => {
        NotificationManager.success(
          'Account successfully created',
          'Success',
          6000
        );
        history.push('/available-products');
      })
      .catch(setFormError)
      .finally(() => {
        ref.current?.complete();
        setSubmitting(false);
      });
  };

  return (
    <StyledWrapper>
      <LoadingBar color="#CD801F" ref={ref} />
      <StyledContainer>
        <StyledMain>
          <StyledFormHeader>
            <StyledLogo src={Logo} alt="logo" />
            <StyledHeading>Create An Account</StyledHeading>
          </StyledFormHeader>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              password: '',
            }}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleRegister(values, setSubmitting);
            }}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              getFieldProps,
              setFieldValue,
            }) => {
              return (
                <Form>
                  {formError && (
                    <FormErrorMessage>{formError}</FormErrorMessage>
                  )}
                  <StyledSpacer>
                    <FormikInput
                      label="Firstname"
                      type="name"
                      required
                      name="firstName"
                      errorMessage={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : undefined
                      }
                      {...getFieldProps('firstName')}
                    />
                    <FormikInput
                      label="Lastname"
                      type="name"
                      required
                      name="lastName"
                      errorMessage={
                        errors.lastName && touched.lastName
                          ? errors.lastName
                          : undefined
                      }
                      {...getFieldProps('lastName')}
                    />
                  </StyledSpacer>
                  <FormikInput
                    label="Email"
                    type="email"
                    required
                    name="email"
                    errorMessage={
                      errors.email && touched.email ? errors.email : undefined
                    }
                    {...getFieldProps('email')}
                  />

                  <CountryInput>
                    <PhoneInput
                      country={'us'}
                      name="phoneNumber"
                      onChange={(phoneNumber) =>
                        setFieldValue(
                          'phoneNumber',
                          phoneNumber.trim() ? '+' + phoneNumber : phoneNumber,
                          true
                        )
                      }
                      placeholder=""
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <ErrorContainer>{errors.phoneNumber}</ErrorContainer>
                    )}
                  </CountryInput>

                  <StyledSpacer>
                    <div>
                      {' '}
                      <FormInput
                        label="Company name"
                        type="text"
                        inputValue={companyName}
                        setInputValue={setCompanyName}
                      />
                    </div>
                    <div style={{ marginBottom: '35px' }}>
                      <Dropdown
                        defaulty="Type"
                        data={[
                          { name: 'Wholesale', id: 'wholesale' },
                          { name: 'Retail', id: 'retail' },
                        ]}
                        height={55}
                        func={(item) => {
                          setType(item.id);
                        }}
                      />
                    </div>
                  </StyledSpacer>

                  <FormikInput
                    label="Password"
                    type="password"
                    required
                    name="password"
                    errorMessage={
                      errors.password && touched.password
                        ? errors.password
                        : undefined
                    }
                    {...getFieldProps('password')}
                  />

                  <StyledFooter>
                    <StyledFooterText>
                      Got an account?{' '}
                      <StyledLink to="/login">Sign In</StyledLink>
                    </StyledFooterText>
                    <NormalButton disabled={isSubmitting} type="submit">
                      Register
                    </NormalButton>
                  </StyledFooter>
                </Form>
              );
            }}
          </Formik>
        </StyledMain>
        <StyledAside></StyledAside>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default RegisterVendor;
