import styled from "styled-components";

// Theming
import { devices } from "../../theme";

export const SettingsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -50px;
  width: max-content;
  margin: 0 auto;

  @media ${devices.lg} {
    top: 0;
    align-items: flex-start;
    width: 100%;
  }
`;

export const SettingsHeaderTitle = styled.span`
  font-size: 1.5em;
  margin-bottom: 50px;
  font-weight: bold;
`;

export const SettingsHeaderDropdown = styled.div`
  width: 150px;

  @media ${devices.sm} {
    display: none;
  }
`;
