import React from 'react';
import Modal from 'react-modal';
import { ModalHeader } from 'shared/styles';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxHeight: '95%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // width: 'max-content',
    minWidth: '300px',
    // maxWidth: '600px',
    borderRadius: '10px',
    zIndex: '1000',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.45)',
  },
};

const CustomModal = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Modal"
    >
      <ModalHeader onClick={onRequestClose}>
        <i>
          <span
            className="iconify"
            data-icon="topcoat:cancel"
            data-inline="false"
          ></span>
        </i>
      </ModalHeader>
      {children}
    </Modal>
  );
};

export default CustomModal;
