import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button, CenteredContainer, SalesPageContainer } from 'shared/styles';

import Scan from '@images/barcode-scanner.png';
import { AppContext } from 'context/store';
import { Link } from 'react-router-dom';
import {
  getAllDiscounts,
  getAllTaxes,
} from 'pages/Dashboard/Inventory/helpers';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';

const SalesScan = () => {
  const {
    store: { token },
    actions: {
      setSidebar,
      setTaxes,
      setDiscounts,
      setTotalTaxes,
      setTotalDiscounts,
    },
  } = useContext(AppContext);

  useState(() => {
    setSidebar('Sales');
  }, []);

  const getDiscounts = useCallback(getAllDiscounts, []);
  const getTaxes = useCallback(getAllTaxes, []);

  useEffect(() => {
    getTaxes(token).then((res) => setTaxes(res));
  }, [getTaxes, token, setTaxes]);
  useEffect(() => {
    getDiscounts(token).then((res) => setDiscounts(res));
  }, [getDiscounts, token, setDiscounts]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/discounts/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalDiscounts(data.results);
    });

    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/taxes/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalTaxes(data.results);
    });
  }, [token, setTotalDiscounts, setTotalTaxes]);

  return (
    <SalesPageContainer>
      <CenteredContainer>
        <h1>Scan Your Product</h1>
        <p>
          Please place barcode properly to scan. Please keep your product steady
          while scanning to ensure accurate result.
        </p>
        <img src={Scan} alt="" />
        <Link to="/dashboard/sales/summary">
          <Button>Scan</Button>
        </Link>
      </CenteredContainer>
    </SalesPageContainer>
  );
};

export default SalesScan;
