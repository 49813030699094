import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';

export const getInternalUsers = (
  token,
  firstname = '',
  lastname = '',
  usertype = ''
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/access/users/?first_name=${firstname}&last_name=${lastname}&user_type=${usertype}`,
        headers: {
          authorization: `token ${token}`,
        },
      });

      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const handleInvite = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/inviter/`,
        headers: {
          authorization: `token ${token}`,
        },
        data,
      });

      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};
