import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginateContainer } from './StyledCustomPagination';

const Paginate = ({ pageCount, onPageChange }) => {
  return (
    <PaginateContainer>
      <ReactPaginate
        previousLabel={'prev'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={onPageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        disabledClassName="disabled"
      />
    </PaginateContainer>
  );
};

export default Paginate;
