import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';

export const getAllTransactions = (token, number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/transactions/?page=${number}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};
