import React, { useContext, useEffect, useRef, useState } from 'react';
import Table from 'components/Table';

import Notiflix from 'notiflix';

import {
  ReportContainer,
  ReportSummary,
  SummaryItem,
  ReportSearch,
  ReportScan,
  DeviceCodeError,
  ClearTransaction,
} from './StyledReport';

import { Button, TableContainer } from 'shared/styles';
import { AppContext } from 'context/store';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';
import { customError } from 'shared/helpers';
import Dropdown from 'components/Dropdown';
// import firebase from 'firebase';

Notiflix.Confirm.Init({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Confirm.Merge({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Loading.Init({
  zindex: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  messageMaxLength: 50,
});

Notiflix.Report.Init({
  zindex: 0,
  backOverlay: false,
  backOverlayColor: 'rgba(0,0,0,0.6)',
});

const Report = () => {
  const [inputValue, setInputValue] = useState('');
  const [scrollBottom, setScrollBottom] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [balance, setBalance] = useState(0);

  const {
    store: { cart, token, device_code, totalDiscounts, totalTaxes },
    actions: {
      deleteItemFromCart,
      addItemToCart,
      removeOneFromItem,
      clearCart,
    },
    summaryData,
  } = useContext(AppContext);

  const handleClear = () => {
    Notiflix.Confirm.Show(
      'Delete Item',
      'Do you want to completely clear the cart?',
      'Yes',
      'No',
      () => {
        clearCart();
      },
      () => {}
    );
  };

  axios.get(BASE_URL + '/sales/devices/', {
    headers: {
      authorization: `token ${token}`,
    },
  });

  if (paymentLoading) {
    Notiflix.Loading.Hourglass('Awaiting Payment ...');
  } else {
    Notiflix.Loading.Remove();
  }

  const data = {
    colors: ['#274E24', 'black', 'black', 'black', 'black', 'black'],
    head: ['Product', 'Size', 'Quantity', 'Price', 'Actions'],
    variables: ['name', 'size', 'cart_qty', 'price', ''],
    body: cart,
  };

  const ref = useRef();

  // axios({
  //   method: 'GET',
  //   url: `${BASE_URL}/sales/checkouts/updated/`,
  //   headers: {
  //     authorization: `token ${token}`,
  //   },
  // }).then((res) => {
  //   console.log(res);
  // });

  const handleSuccess = async () => {
    const orderItems = cart.map((item, idx) => ({
      product: item.id,
      quantity: item.cart_qty,
    }));

    await axios({
      method: 'POST',
      url: `${BASE_URL}/orders/`,
      data: {
        is_paid: true,
        order_items: orderItems,
      },
      headers: {
        authorization: `token ${token}`,
      },
    });
    clearCart();
  };
  const handleFailure = () => {};

  // axios({
  //   method: 'GET',
  //   url: `${BASE_URL}/sales/checkouts/updated/`,
  //   headers: {
  //     authorization: `token ${token}`,
  //   },
  // }).then((res) => console.log(res));

  const pollSales = (id) => {
    const timer = setInterval(() => {
      axios({
        method: 'GET',
        url: `${BASE_URL}/sales/checkouts/updated/`,
        headers: {
          authorization: `token ${token}`,
        },
      }).then((res) => {
        const success = res.data.results?.find(
          (item) => item.checkout_id === id && item.status === 'COMPLETED'
        );
        const failure = res.data.results?.find(
          (item) => item.checkout_id === id && item.status === 'CANCELED'
        );
        if (success) {
          setPaymentLoading(false);
          handleSuccess(success);

          clearInterval(timer);
          Notiflix.Report.Success('Success', 'Payment Successful', 'Continue');
        }
        if (failure) {
          setPaymentLoading(false);
          handleFailure(failure);

          clearInterval(timer);
          Notiflix.Confirm.Show(
            'Transaction canceled',
            'Do you want to retry this transaction?',
            'Yes',
            'No',
            () => {
              // retry
              handlePayment();
            },
            () => {}
          );
        }
      });
    }, 1000);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const handlePayment = () => {
    setPaymentLoading(true);

    const orderItems = cart.map((item, idx) => ({
      product: item.id,
      quantity: item.cart_qty,
    }));

    const postOrder = axios({
      method: 'POST',
      url: `${BASE_URL}/orders/`,
      data: {
        is_paid: false,
        order_items: orderItems,
      },
      headers: {
        authorization: `token ${token}`,
      },
    });

    const sendAmountToTerminal = axios({
      method: 'POST',
      url: `${BASE_URL}/sales/terminal-checkout/`,
      data: {
        amount: balance * 100,
        device_id: device_code,
      },
      headers: {
        authorization: `token ${token}`,
      },
    });

    Promise.all([postOrder, sendAmountToTerminal])
      .then((res) => {
        pollSales(res[1].data.id);
      })
      .catch((e) => {
        customError(e);
        setPaymentLoading(false);
      });
  };

  const handleKeyDown = async (e) => {
    const isBarcode = !isNaN(inputValue);
    const url = isBarcode ? `${BASE_URL}/product/barcode_search/?barcode_digit=${inputValue}`: `${BASE_URL}/product/list/?name=${inputValue}`;
    if (e.key === 'Enter') {
      Notiflix.Loading.Circle();
      try {
        const scannedItem = await axios({
          method: 'GET',
          url,
          headers: {
            authorization: `token ${token}`,
          },
        });

        if (scannedItem.data.results.length === 0) {
          throw new Error('Item not found, please re-scan device');
        }
        addItemToCart(scannedItem.data.results[0]);
      } catch (e) {
        if (e.message) {
          customError(e, e.message);
        } else {
          customError(e);
        }
      } finally {
        Notiflix.Loading.Remove();
        setInputValue('');
      }
      setScrollBottom(true);
      setScrollBottom(false);
    }
  };

  const externalDelete = (entry) => {
    Notiflix.Confirm.Show(
      'Delete Item',
      'Do you want to completely delete this item?',
      'Yes',
      'No',
      () => {
        deleteItemFromCart(entry);
      },
      () => {}
    );
  };

  useEffect(() => {
    ref.current?.focus();
  }, []);

  function getBalance(totalPrice, tax, discount) {
    const removedDiscount = (discount / 100) * totalPrice;
    const afterDiscount = totalPrice - removedDiscount;
    const afterTax = afterDiscount * (1 + tax / 100);

    return Math.round((afterTax + Number.EPSILON) * 100) / 100;
  }
  useEffect(() => {
    setBalance(getBalance(summaryData.totalPrice, tax, discount));
  }, [summaryData, tax, discount]);

  return (
    <>
      {!device_code && (
        <div style={{ marginBottom: '20px' }}>
          <DeviceCodeError to="/dashboard/terminal">
            Kindly pair with a terminal device
          </DeviceCodeError>
        </div>
      )}
      <ReportSearch>
        <ReportScan
          placeholder="Press enter to add"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          type="name"
          ref={ref}
        />
      </ReportSearch>

      <ReportContainer>
        <div className="report__table">
          <TableContainer>
            <Table
              data={data}
              hasQuantity
              deleteExternal={externalDelete}
              addFunc={addItemToCart}
              removeFunc={removeOneFromItem}
              scrollToBottom={scrollBottom}
            />
          </TableContainer>
          {cart.length > 0 && (
            <ClearTransaction onClick={handleClear}>
              <span
                className="iconify"
                data-icon="carbon:shopping-cart-clear"
                data-inline="false"
              ></span>
              <span>Clear cart</span>
            </ClearTransaction>
          )}
        </div>
        <div>
          <ReportSummary>
            <h2>Summary</h2>

            <SummaryItem>
              <span>Total Amount</span>
              <span>${summaryData.totalPrice}</span>
            </SummaryItem>
            <SummaryItem>
              <span>Total Quantity</span>
              <span>{summaryData.totalQuantity}</span>
            </SummaryItem>

            <hr />
            <SummaryItem>
              <h4>Balance</h4>
              <span>${getBalance(summaryData.totalPrice, tax, discount)}</span>
            </SummaryItem>

            <div className="report__button">
              <Button
                onClick={handlePayment}
                disabled={summaryData.totalQuantity === 0 || !device_code}
              >
                Proceed
              </Button>
            </div>
          </ReportSummary>
          <div>
            <h4>Apply Discount</h4>
            <Dropdown
              defaulty="None"
              label=""
              data={totalDiscounts.map((item) => ({
                id: item.percentage,
                name: item.name,
              }))}
              func={(item) => setDiscount(item.id)}
              height="50px"
            />
            <h4>Tax</h4>
            <Dropdown
              defaulty="None"
              label=""
              data={totalTaxes.map((item) => ({
                id: item.percentage,
                name: item.name,
              }))}
              func={(item) => setTax(item.id)}
              height="50px"
            />
          </div>
        </div>
      </ReportContainer>
      {/* <CustomNotiflix shouldLoad={isLoading} /> */}
    </>
  );
};

export default Report;
