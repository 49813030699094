import React, { useContext, useEffect, useState } from 'react';
import { Button, CenteredContainer, FormErrorMessage } from 'shared/styles';
import { SalesHeader, SalesHeading } from '../styledSales';
import { TerminalContainer, DeviceCode } from './StyledTerminal';
import Connect from '../../../../assets/images/connect.svg';
import CustomNotiflix from 'components/Notiflix';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';
import { AppContext } from 'context/store';
import CustomModal from 'components/Modal';
import moment from 'moment';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

Notiflix.Report.Init({
  zindex: 0,
  backOverlay: false,
  backOverlayColor: 'rgba(0,0,0,0.6)',
});

const Terminal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [deviceCode, setNewDeviceCode] = useState('');
  const [, setDeviceId] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [isPairing, setIsPairing] = useState(false);

  const {
    store: { token, user },
    actions: { setDeviceCode, setSidebar },
  } = useContext(AppContext);

  const handlePendingPairing = () => {
    setInterval(() => {}, 1000);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setIsPairing(false);
  };

  // axios({
  //   method: 'GET',
  //   url: `${BASE_URL}/sales/devices/`,
  //   headers: {
  //     authorization: `token ${token}`,
  //   },
  // }).then((res) => {
  //   console.log(res);
  // });

  const pollDevice = (expiresAt, deviceCode) => {
    setIsPairing(true);
    const timer = setInterval(() => {
      axios({
        method: 'GET',
        url: `${BASE_URL}/sales/devices/`,
        headers: {
          authorization: `token ${token}`,
        },
      }).then((res) => {
        const paired = res.data.results?.find(
          (item) => item.code === deviceCode && item.status === 'PAIRED'
        );
        if (paired) {
          setDeviceId(paired.device_id);
          setIsPairing(false);
          clearInterval(timer);
          setDeviceCode(paired.device_id);
          closeModal();
          Notiflix.Report.Success(
            'Success',
            'You have successfully paired with a terminal device',
            'Continue'
          );
        }

        if (new Date().toISOString() > expiresAt) {
          clearInterval(timer);
          closeModal();
          Notiflix.Report.Failure(
            'Timeout',
            'Pairing time has elapsed, please try again',
            'Close'
          );
          setIsPairing(false);
        }
      });
    }, 1000);
  };

  const handleDeviceCode = () => {
    setIsLoading(true);
    setModalIsOpen(false);
    axios({
      url: `${BASE_URL}/sales/device-code/`,
      method: 'POST',
      headers: {
        authorization: `token ${token}`,
      },
      data: {
        name: user.user.first_name + ' ' + user.user.last_name,
      },
    })
      .then((res) => {
        setNewDeviceCode(res.data?.device_code.code);
        setDeviceId(res.data?.device_code.id);
        setExpiryDate(res.data?.device_code.pair_by);
        setModalIsOpen(true);
        handlePendingPairing();
        pollDevice(res.data?.device_code.pair_by, res.data?.device_code.code);
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setSidebar(null);
  }, [setSidebar]);

  if (isPairing) {
    Notiflix.Block.Circle('#awaiting', 'Awaiting pairing...');
  } else {
    Notiflix.Block.Remove('#awaiting');
  }

  return (
    <>
      <SalesHeading>
        <SalesHeader>Terminal</SalesHeader>
      </SalesHeading>
      <TerminalContainer>
        <CenteredContainer>
          <h2>Pair With Terminal Device</h2>
          {error && (
            <FormErrorMessage>
              An error occcured while trying to pair device. Please reload page
              and try again
            </FormErrorMessage>
          )}
          <p>
            Please ensure that the terminal device is available and ready to get
            paired. Select the <em>Sign in with device code option</em>
          </p>
          <img alt="" src={Connect} />

          <Button active onClick={handleDeviceCode}>
            Connect
          </Button>
          <Link
            to="/dashboard/sales/summary"
            style={{
              marginTop: '15px',
              color: theme.colors.brandYellow,
            }}
          >
            Go to Sales
          </Link>
        </CenteredContainer>
      </TerminalContainer>
      <CustomNotiflix shouldLoad={isLoading} />
      <CustomModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <CenteredContainer>
          <h2>Device Code</h2>
          <p>Kindly enter the device code into the terminal device.</p>
          <DeviceCode> {deviceCode}</DeviceCode>
          {/* <Button
            active
            style={{ marginTop: '30px' }}
            onClick={handleSuccessfulPairing}
          >
            Save device code
          </Button> */}
          {isPairing && (
            <div
              id="awaiting"
              style={{
                margin: '10px 0',
                width: '100%',
              }}
            ></div>
          )}
          {expiryDate && (
            <div style={{ marginTop: '20px', fontSize: 'small' }}>
              Expires at {moment(expiryDate).format('MMMM Do YYYY, h:mm:ss a')}{' '}
              or
              <span
                style={{ color: '#ff0000', cursor: 'pointer' }}
                onClick={handleDeviceCode}
              >
                {' '}
                retry
              </span>
            </div>
          )}
        </CenteredContainer>
      </CustomModal>
    </>
  );
};

export default Terminal;
