import Card from "components/Card";
import { AppContext } from "context/store";
import React, { useContext, useEffect } from "react";

import { InfoContainer, InfoItem } from "./StyledInfo";

const data = [
  {
    title: "gomezadrian@gmail.com placed an order 34 minutes ago.",
    category: "SALES",
    cta: "review",
  },
  {
    title: "Quantity of Rose flower is lower than 25.",
    category: "INVENTORY",
    cta: "view",
  },
  {
    title: "A new item has been added by Jane Doe 30 minutes ago.",
    category: "INVENTORY",
    cta: "view",
  },
  {
    title: "gomezadrian@gmail.com placed an order 34 minutes ago",
    category: "SALES",
    cta: "review",
  },
  {
    title: "gomez@gmail.com placed an order 34 minutes ago.",
    category: "SALES",
    cta: "view",
  },
];

const Info = () => {
  const {
    actions: { setSidebar },
  } = useContext(AppContext);

  useEffect(() => {
    setSidebar(3);
  }, [setSidebar]);

  return (
    <InfoContainer>
      <h1>Info</h1>

      {/* <InfoItems> */}
      {data.map((item, index) => (
        <InfoItem key={index}>
          <Card content={item.title} type={item.category} cta={item.cta} />
        </InfoItem>
      ))}
      {/* </InfoItems> */}
    </InfoContainer>
  );
};

export default Info;
