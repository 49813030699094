import React, { useRef, useContext, useState } from 'react';

// External Libraries
import { withRouter } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { Form, Formik } from 'formik';

// Styled components
import {
  StyledContainer,
  StyledAside,
  StyledFooter,
  StyledFormHeader,
  StyledLogo,
  StyledMain,
  StyledFooterText,
  StyledHeading,
  StyledWrapper,
} from './StyledLogin';

// Components
import FormikInput from 'components/FormikInput';

// Assets
import Logo from '@images/logo2.svg';

import { FormErrorMessage, FullButton, StyledLink } from 'shared/styles';
import { LoginSchema } from './loginSchema';
import { loginUser } from './loginUser';

// context
import { AppContext } from 'context/store';
import { getUser } from 'utils/api/users';

const Login = ({ history }) => {
  const {
    actions: { setToken, setUser },
  } = useContext(AppContext);

  const [formError, setFormError] = useState('');

  // Loader ref
  const ref = useRef(null);

  // login
  const handleLogin = async ({ email, password }, setSubmitting) => {
    setFormError('');
    setSubmitting(true);
    ref.current.continuousStart(); // Start Loading
    try {
      const response = await loginUser(email, password);
      ref.current.complete(); // Stop Loading
      setSubmitting(false);
      await setToken(response.auth_token);

      const profile = await getUser(response.auth_token);
      setUser(profile);
      history.push('/dashboard');
    } catch (e) {
      ref.current.complete(); // End Loading
      setSubmitting(false);
      setFormError(e);
    }
  };

  return (
    <StyledWrapper>
      <LoadingBar color="#CD801F" ref={ref} />
      <StyledContainer>
        <StyledMain>
          <StyledFormHeader>
            <StyledLogo src={Logo} alt="logo" />
            <StyledHeading>Login</StyledHeading>
          </StyledFormHeader>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values, setSubmitting);
            }}
          >
            {({ isSubmitting, getFieldProps, errors, touched }) => {
              return (
                <Form>
                  {formError && (
                    <FormErrorMessage>{formError}</FormErrorMessage>
                  )}
                  <FormikInput
                    label="Email"
                    type="email"
                    required
                    {...getFieldProps('email')}
                    errorMessage={
                      errors.email && touched.email ? errors.email : undefined
                    }
                  />
                  <FormikInput
                    label="Password"
                    type="password"
                    required
                    {...getFieldProps('password')}
                    errorMessage={
                      errors.password && touched.password
                        ? errors.password
                        : undefined
                    }
                  />

                  <FullButton disabled={isSubmitting} type="submit">
                    Login
                  </FullButton>

                  <StyledFooter>
                    <StyledFooterText>
                      New user?
                      <StyledLink to="/register">Create Account</StyledLink>
                    </StyledFooterText>
                    <StyledFooterText>
                      <StyledLink to="/reset-password">
                        Forgot Password
                      </StyledLink>
                    </StyledFooterText>
                  </StyledFooter>
                </Form>
              );
            }}
          </Formik>
        </StyledMain>
        <StyledAside></StyledAside>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default withRouter(Login);
