import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';

// External Libraries
import { withRouter } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

// Styled components
import {
  StyledContainer,
  StyledAside,
  StyledFooter,
  StyledFormHeader,
  StyledLogo,
  StyledMain,
  StyledSpacer,
  StyledFooterText,
  StyledHeading,
  StyledWrapper,
  CountryInput,
} from './StyledRegister';

// Components
import FormikInput from 'components/FormikInput';
import {
  ErrorContainer,
  FormErrorMessage,
  NormalButton,
  StyledLink,
} from 'shared/styles';

// Assets
import Logo from '@images/logo2.svg';

// API

import { RegisterSchema } from './registerSchema';
import { registerUser } from './handleRegister';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

const Register = ({ history, userType }) => {
  // Component State
  const [formError, setFormError] = useState('');

  // Loader reference
  const ref = useRef(null);

  // registeration
  const handleRegister = async (
    { firstName, lastName, email, password, phoneNumber },
    setSubmitting
  ) => {
    const registrationData = {
      first_name: firstName,
      last_name: lastName,
      user_type: userType,
      phone_number: phoneNumber,
      email,
      password,
    };
    setFormError('');
    ref.current.continuousStart(); // Start loading
    setSubmitting(true);
    try {
      await registerUser(registrationData);
      ref.current.complete(); // End loading
      setSubmitting(false);
      history.push('/login');
    } catch (e) {
      setSubmitting(false);
      ref.current.complete(); // End Loading
      setFormError(e);
    }
  };

  return (
    <StyledWrapper>
      <LoadingBar color="#CD801F" ref={ref} />
      <StyledContainer>
        <StyledMain>
          <StyledFormHeader>
            <StyledLogo src={Logo} alt="logo" />
            <StyledHeading>Create An Account</StyledHeading>
          </StyledFormHeader>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              password: '',
            }}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleRegister(values, setSubmitting);
            }}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              getFieldProps,
              setFieldValue,
            }) => {
              return (
                <Form>
                  {formError && (
                    <FormErrorMessage>{formError}</FormErrorMessage>
                  )}
                  <StyledSpacer>
                    <FormikInput
                      label="Firstname"
                      type="name"
                      required
                      name="firstName"
                      errorMessage={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : undefined
                      }
                      {...getFieldProps('firstName')}
                    />
                    <FormikInput
                      label="Lastname"
                      type="name"
                      required
                      name="lastName"
                      errorMessage={
                        errors.lastName && touched.lastName
                          ? errors.lastName
                          : undefined
                      }
                      {...getFieldProps('lastName')}
                    />
                  </StyledSpacer>
                  <FormikInput
                    label="Email"
                    type="email"
                    required
                    name="email"
                    errorMessage={
                      errors.email && touched.email ? errors.email : undefined
                    }
                    {...getFieldProps('email')}
                  />

                  <CountryInput>
                    <PhoneInput
                      country={'us'}
                      name="phoneNumber"
                      onChange={(phoneNumber) =>
                        setFieldValue('phoneNumber', '+' + phoneNumber, true)
                      }
                      placeholder=""
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <ErrorContainer>{errors.phoneNumber}</ErrorContainer>
                    )}
                  </CountryInput>

                  <FormikInput
                    label="Password"
                    type="password"
                    required
                    name="password"
                    errorMessage={
                      errors.password && touched.password
                        ? errors.password
                        : undefined
                    }
                    {...getFieldProps('password')}
                  />
                  <StyledFooter>
                    <StyledFooterText>
                      Got an account?{' '}
                      <StyledLink to="/login">Sign In</StyledLink>
                    </StyledFooterText>
                    <NormalButton disabled={isSubmitting} type="submit">
                      Register
                    </NormalButton>
                  </StyledFooter>
                </Form>
              );
            }}
          </Formik>
        </StyledMain>
        <StyledAside></StyledAside>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default withRouter(Register);
