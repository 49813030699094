import axios from 'axios';
import { customErrorMessage } from 'shared/helpers';
import { BASE_URL } from 'utils/api/constants';

export const loginUser = (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/auth/token/login/`,
        data: {
          email,
          password,
        },
      });
      resolve(response.data);
    } catch (e) {
      // console.log(e.response);
      const { errorBody } = customErrorMessage(e);
      reject(errorBody);
    }
  });
};
