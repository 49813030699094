// external libraries
import axios from 'axios';

// constants
import { BASE_URL } from '../constants';

export const getAllInventory = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/list/`,
        headers: {
          authorization: `token ${token}`,
        },
      });

      resolve(response.data);
      //   console.log(response.data);

      // if successful
      // console.log(response.data);
      //   resolve("");
    } catch (e) {
      // console.log(e.response);
      reject(e);
      //   NotificationManager.error(
      //     "An error occured while trying to register you. Ensure that your password is longer than 7 characters."
      //   );
    }
  });
};
