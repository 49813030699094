import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import Notiflix from 'notiflix';
import LoadingBar from 'react-top-loading-bar';

// components
import SearchBox from 'components/SearchBox';
// import Dropdown from "components/Dropdown";
import Table from 'components/Table';

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

// Styled component
import {
  VendorsContainer,
  VendorsMain,
  VendorsTable,
  VendorsAdd,
  ModalFooter,
  ModalButton,
} from './StyledVendors';

import FormInput from 'components/FormInput';

// shared
import {
  Button,
  DashboardFilter,
  DashboardHeader,
  DashboardHeaderOptions,
  DashboardSearch,
  DashboardSearchBox,
  DoubleItems,
  PaginateContainer,
  RightFooter,
} from 'shared/styles';

// Context
import { AppContext } from 'context/store';
import { BASE_URL, COMPANY_TYPES } from 'utils/api/constants';
import { getAllContacts } from 'utils/api/vendors';
import Dropdown from 'components/Dropdown';
import { FilterGroup } from '../Inventory/StyledInventory';
import ToggleSwitch from 'components/ToggleSwitch';
import ReactPaginate from 'react-paginate';
import CustomModal from 'components/Modal';
import { CountryInput } from 'pages/RegisterVendor/StyledRegister';

Modal.setAppElement('#root');

Notiflix.Confirm.Init({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Confirm.Merge({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

const filterData = {
  type: [
    { name: 'All', value: '' },
    ...COMPANY_TYPES.reduce((acc, curr) => {
      return [...acc, { name: curr.name, value: curr.id }];
    }, []),
  ],
};

const tableData = {
  head: [
    'First Name',
    'Last Name',
    'Email',
    'Company',
    'Phone Number',
    'Type',
    'Actions',
  ],
  variables: [
    'first_name',
    'last_name',
    'email',
    'company',
    'mobile_number',
    'type',
    'actions',
  ],
  body: null,
};

const Vendors = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [editItem, setEditItem] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [type, setType] = useState('');
  const [pageCount, setPageCount] = useState('');
  const [data, setData] = useState(tableData);
  const [loading, setLoading] = useState(false);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [isFilter, setisFilter] = useState(false);
  const [filterType, setFilterType] = useState('');

  const {
    store: { token },
    actions: { setSidebar },
  } = useContext(AppContext);

  async function getVendors() {
    setLoadingVendors(true);
    const response = await getAllContacts(token);
    setData((data) => ({ ...data, body: response.results }));
    setLoadingVendors(false);
  }

  if (loadingVendors || loading) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  if (hasSearched && !searchTerm) {
    setHasSearched(false);
    getVendors();
  }

  const ref = useRef();

  const diasableAddItem =
    !firstName || !lastName || !type || !email || !phoneNumber || !company;

  const disableSaveItem =
    !editItem.first_name ||
    !editItem.last_name ||
    !editItem.type ||
    !editItem.email ||
    !editItem.phone_number ||
    !editItem.company;

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setFirstName('');
    setLastName('');
    setCompany('');
    setEmail('');
    setPhoneNumber('');
    setIsOpen(false);
    setIsAddNew(false);
    setisFilter(false);
  }

  function handleNew() {
    setIsAddNew(true);
    openModal();
  }

  function handleEdit(item) {
    setIsAddNew(false);
    setEditItem(item);
    openModal();
  }
  function edit(key, value) {
    setEditItem({
      ...editItem,
      [key]: value,
    });
  }

  const handleFilterReset = () => {
    setFilterType('');
  };

  const handleFilterApply = async () => {
    setLoadingVendors(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/vendor/list/?first_name=${searchTerm}&type=${filterType}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      setPageCount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: response.data.results }));
      setLoadingVendors(false);
      searchTerm && setHasSearched(true);
      closeModal();
    } catch (e) {
      setLoadingVendors(false);
      closeModal();
    }
  };

  async function handleSearch() {
    setLoadingVendors(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/vendor/list/?user__first_name=${searchTerm}&type=${filterType}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      setPageCount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: response.data.results }));
      setLoadingVendors(false);
      setHasSearched(true);
      closeModal();
    } catch (e) {
      setLoadingVendors(false);
      closeModal();
    }
  }

  async function addItem() {
    setLoading(true);
    ref.current.continuousStart();
    const capitalised = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    try {
      await axios({
        method: 'post',
        url: `${BASE_URL}/auth/vendors/`,
        headers: {
          authorization: `token ${token}`,
        },
        data: {
          type,
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phoneNumber,
          company,
          password: capitalised + '123.com',
        },
      });

      // if successful
      setLoading(false);
      ref.current.complete();
      NotificationManager.success('Item successful updated', 'Item Updated');
      closeModal();
      getVendors();
    } catch (e) {
      let errorBody = null;
      let errorHead = null;
      if (e.response && e.response.data) {
        const errorObject = e.response.data;
        const errorKey = Object.keys(errorObject);
        errorBody = `${errorObject[errorKey[0]]}`;
        errorHead = `${errorKey[0]}`;
      }

      ref.current.complete();
      setLoading(false);
      NotificationManager.error(
        errorBody || 'An error occured',
        errorHead || ''
      );
    }
  }

  async function saveItem() {
    setLoading(true);
    ref.current.continuousStart();

    try {
      await axios({
        method: 'put',
        url: `${BASE_URL}/vendors/${editItem.id}/`,
        headers: {
          authorization: `token ${token}`,
        },
        data: {
          first_name: editItem.first_name,
          last_name: editItem.last_name,
          email: editItem.email,
          company: editItem.company,
          phone_number: editItem.phone_number,
          type: editItem.type,
        },
      });
      // if successful
      setLoading(false);
      ref.current.complete();
      NotificationManager.success(
        'Contact successful updated',
        'Contact Updated'
      );
      closeModal();
      getVendors();
    } catch (e) {
      let errorBody = null;
      let errorHead = null;
      if (e.response && e.response.data) {
        const errorObject = e.response.data;
        const errorKey = Object.keys(errorObject);
        errorBody = `${errorObject[errorKey[0]]}`;
        errorHead = `${errorKey[0]}`;
      }

      ref.current.complete();
      setLoading(false);
      NotificationManager.error(
        errorBody || 'An error occured',
        errorHead || ''
      );
    }
  }

  async function startDelete() {
    setLoading(true);
    ref.current.continuousStart();
    try {
      await axios({
        method: 'delete',
        url: `${BASE_URL}/vendors/${editItem.id}/`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      // if successful
      setLoading(false);
      ref.current.complete();
      NotificationManager.success(
        'Contact successful deleted',
        'Contact Deleted'
      );
      closeModal();
      getVendors();
    } catch (e) {
      let errorBody = null;
      let errorHead = null;
      if (e.response && e.response.data) {
        const errorObject = e.response.data;
        const errorKey = Object.keys(errorObject);
        errorBody = `${errorObject[errorKey[0]]}`;
        errorHead = `${errorKey[0]}`;
      }

      ref.current.complete();
      setLoading(false);
      NotificationManager.error(
        errorBody || 'An error occured',
        errorHead || ''
      );
    }
  }

  function deleteUser() {
    Notiflix.Confirm.Show(
      'Delete User',
      'Do you want to completely delete this User?',
      'Yes',
      'No',
      () => {
        startDelete();
      },
      () => {}
    );
  }

  async function handlePageClick({ selected }) {
    setLoadingVendors(true);
    try {
      const response = await axios(
        `${BASE_URL}/vendors/?page=${selected + 1}`,
        {
          method: 'get',
          headers: {
            authorization: `token ${token}`,
          },
        }
      );
      setData((data) => ({ ...data, body: response.data.results }));
      setLoadingVendors(false);
    } catch (e) {
      setLoadingVendors(false);
    }
  }

  useEffect(() => {
    setSidebar('Vendors');
  }, [setSidebar]);

  useEffect(() => {
    async function getVendorsData() {
      setLoadingVendors(true);
      try {
        const response = await getAllContacts(token);
        setPageCount(Math.ceil(response.count / 15));
        setData((data) => ({ ...data, body: response.results }));
        setLoadingVendors(false);
      } catch (e) {
        setLoadingVendors(false);
      }
    }
    getVendorsData();
  }, [token]);

  return (
    <VendorsContainer>
      <LoadingBar color="#CD801F" ref={ref} />
      <h1>Vendors</h1>
      <DashboardHeader>
        <DashboardSearch>
          <DashboardSearchBox>
            <SearchBox
              inputValue={searchTerm}
              setInputValue={setSearchTerm}
              onSubmit={handleSearch}
            />
          </DashboardSearchBox>
          <DashboardFilter
            onClick={() => {
              setisFilter(true);
              openModal();
            }}
          >
            Filters
          </DashboardFilter>
        </DashboardSearch>

        <DashboardHeaderOptions>
          <Button onClick={handleNew}>
            <span
              className="iconify"
              data-icon="bx:bx-message-square-add"
              data-inline="false"
            ></span>
            <span>Add</span>
          </Button>
        </DashboardHeaderOptions>
      </DashboardHeader>

      <VendorsMain>
        <VendorsTable>
          <Table
            data={data}
            onClickTableItem={handleEdit}
            emptyText="No vendor available"
          />
        </VendorsTable>
        <PaginateContainer>
          <ReactPaginate
            previousLabel={'prev'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            disabledClassName="disabled"
          />
        </PaginateContainer>
        <CustomModal isOpen={modalIsOpen} onRequestClose={closeModal}>
          {modalIsOpen && (
            <>
              {isFilter ? (
                <>
                  <FilterGroup>
                    <h2>Company Type</h2>
                    {filterData.type.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <span>{item.name}</span>
                          <ToggleSwitch
                            checked={filterType === item.value}
                            setChecked={(state) => setFilterType(item.value)}
                          />
                        </div>
                      );
                    })}
                  </FilterGroup>
                  <RightFooter>
                    <Button isDelete onClick={handleFilterReset}>
                      Reset
                    </Button>
                    <Button onClick={handleFilterApply}>Apply</Button>
                  </RightFooter>
                </>
              ) : (
                <>
                  {isAddNew ? (
                    <VendorsAdd>
                      <h2>Add new Vendor</h2>
                      <form>
                        <FormInput
                          label="First Name"
                          type="name"
                          inputValue={firstName}
                          setInputValue={setFirstName}
                          required
                        />
                        <FormInput
                          label="Last Name"
                          type="name"
                          inputValue={lastName}
                          setInputValue={setLastName}
                          required
                        />
                        <FormInput
                          label="Email"
                          type="email"
                          inputValue={email}
                          setInputValue={setEmail}
                          required
                        />

                        <CountryInput>
                          <PhoneInput
                            country={'us'}
                            name="phoneNumber"
                            onChange={(phoneNumber) =>
                              setPhoneNumber('+' + phoneNumber)
                            }
                            placeholder=""
                          />
                        </CountryInput>
                        <DoubleItems>
                          <FormInput
                            label="Company"
                            type="name"
                            inputValue={company}
                            setInputValue={setCompany}
                          />
                          <Dropdown
                            data={[
                              { id: 'retail', name: 'Retail' },
                              { id: 'wholesale', name: 'Wholesale' },
                            ]}
                            defaulty="Type"
                            func={(item) => setType(item.id)}
                          ></Dropdown>
                        </DoubleItems>

                        <ModalFooter>
                          <ModalButton
                            onClick={() => {
                              if (!diasableAddItem) {
                                addItem();
                              } else if (loading) {
                                NotificationManager.warning('Please wait...');
                              } else {
                                NotificationManager.warning(
                                  'All fields are required'
                                );
                              }
                            }}
                          >
                            Save
                          </ModalButton>
                        </ModalFooter>
                      </form>
                    </VendorsAdd>
                  ) : (
                    <VendorsAdd>
                      <h2>Edit</h2>
                      <form>
                        <FormInput
                          label="First Name"
                          type="name"
                          inputValue={editItem.first_name}
                          setInputValue={(value) => {
                            edit('first_name', value);
                          }}
                        />
                        <FormInput
                          label="Last Name"
                          type="name"
                          inputValue={editItem.last_name}
                          setInputValue={(value) => {
                            edit('last_name', value);
                          }}
                        />
                        <FormInput
                          label="Email"
                          type="name"
                          inputValue={editItem.email}
                          setInputValue={(value) => {
                            edit('email', value);
                          }}
                        />

                        <FormInput
                          label="Phone Number"
                          type="name"
                          inputValue={editItem.phone_number}
                          setInputValue={(value) => {
                            edit('phone_number', value);
                          }}
                        />
                        <DoubleItems>
                          <Dropdown
                            defaulty={editItem.type}
                            data={[
                              { id: 'retail', name: 'Retail' },
                              { id: 'wholesale', name: 'Wholesale' },
                            ]}
                            func={(item) => edit('type', item.id)}
                          />
                          <FormInput
                            label="Company"
                            type="name"
                            inputValue={editItem.company}
                            setInputValue={(value) => {
                              edit('company', value);
                            }}
                          />
                        </DoubleItems>

                        <ModalFooter>
                          <ModalButton
                            onClick={() => {
                              if (!disableSaveItem) {
                                saveItem();
                              } else if (loading) {
                                NotificationManager.warning('Please wait...');
                              } else {
                                NotificationManager.warning(
                                  'All fields are required'
                                );
                              }
                            }}
                          >
                            Save
                          </ModalButton>
                          <ModalButton isDelete={true} onClick={deleteUser}>
                            Delete
                          </ModalButton>
                        </ModalFooter>
                      </form>
                    </VendorsAdd>
                  )}
                </>
              )}
            </>
          )}
        </CustomModal>
      </VendorsMain>
    </VendorsContainer>
  );
};

export default Vendors;
