import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { devices } from '../../theme';

const StyledReveal = css`
  width: 100vw;
  max-width: 500px;
  opacity: 1;
  background-color: white;
  z-index: 1000;
  color: ${(props) => props.theme.colors.brandOffGreen};
  padding: 18px 26px 43px 25px;
`;

export const StyledMobileNavContainer = styled.div`
  display: none;
  @media ${devices.llg} {
    display: flex;
    max-width: 500px;
    margin-left: auto;
  }
`;

export const StyledMobileNavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: solid red 1px; */

  & > div {
    display: flex;
    align-items: center;

    img {
      width: 35px;
      margin-right: 15px;
    }
    span {
      font-size: 1.1em;
      font-weight: bold;
      color: ${(props) => props.theme.colors.brandGreen};
    }
  }
  & > i {
    .iconify {
      width: 30px;
      height: 40px;
    }
  }
`;

export const StyledMobileNavBurger = styled.img`
  height: 45px;
`;

export const StyledMobileNavReveal = styled.div`
  width: 0;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease;

  ${(props) => (props.reveal ? StyledReveal : '')}
`;

export const StyledMobileNavContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledMobileNavClose = styled.div`
  width: 100%;
  height: 40px;
  /* margin-bottom: 30px; */
  display: flex;
  justify-content: flex-end;
`;

export const StyledMobileNavlist = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const StyledMobileNavItem = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin: 30px 0;
  padding-bottom: 25px;
  text-decoration: none;
  color: inherit;

  .iconify {
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.colors.brandOffGreen};
  }
  & > i {
    margin: 0;
    margin-right: 20px;
    display: flex;
  }

  & > span {
    font-size: 1.2em;
    line-height: 0;
  }
  &:first-of-type {
    margin-top: 10px;
  }
  &:last-of-type {
    border-bottom: none;
    margin-top: 60px;
  }
`;
