// external libraries
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// constants
import { BASE_URL } from '../constants';

export const registerUser = (firstName, lastName, email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios({
        method: 'post',
        url: `${BASE_URL}/auth/users/`,
        data: {
          first_name: firstName,
          last_name: lastName,
          is_admin: false,
          email,
          password,
        },
      });

      // if successful
      // console.log(response.data);
      NotificationManager.success('Successfully registered, please login');
      resolve('');
    } catch (e) {
      // console.log(e.response.data);
      if (e.response && e.response.data) {
        const errorObject = e.response.data;
        const errorKey = Object.keys(errorObject);
        const errorBody = `${errorObject[errorKey[0]]}`;
        NotificationManager.error(errorBody);
      }

      reject(e);
    }
  });
};

export const getUser = (token) => {
  // console.log(token);
  return new Promise(async (resolve, reject) => {
    try {
      const userProfile = await axios({
        method: 'get',
        url: `${BASE_URL}/profile/`,
        headers: {
          Authorization: `token ${token}`,
        },
      });

      // console.log(userProfile.data);

      resolve(userProfile.data);
    } catch (e) {
      // console.log(e.response);
      e.response &&
        e.response.data &&
        e.response.data.detail &&
        NotificationManager.error(e.response.data.detail);

      reject(e);
    }
  });
};
