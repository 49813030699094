import React from 'react';

// external libraries
import { VictoryChart, VictoryBar, VictoryGroup, VictoryAxis } from 'victory';

// Styled components
import { BarChartContainer } from './StyledBarChart';

const BarChart = ({ colors, dataOne, dataTwo, title, money }) => {
  return (
    <BarChartContainer>
      <span>{title}</span>
      <VictoryChart minDomain={{ x: 0, y: 0 }}>
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed

          tickFormat={(x) => {
            if (money) {
              return x > 9999 ? `$${x / 1000}k` : `$${x}`;
            } else {
              return x > 9999 ? `${x / 1000}k` : `${x}`;
            }
          }}
        />
        <VictoryAxis />
        <VictoryGroup
          offset={18}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          colorScale={[colors[0], colors[1]]}
        >
          <VictoryBar data={dataOne} />
          <VictoryBar data={dataTwo} />
        </VictoryGroup>
      </VictoryChart>
    </BarChartContainer>
  );
};

export default BarChart;
