import styled from 'styled-components';

export const PaginateContainer = styled.div`
  margin-top: 15px;

  .pagination {
    display: flex;
    list-style-type: none;
    width: fit-content;
    padding: 10px;
    margin-left: auto;

    a {
      outline: 0;
      border: 0;
    }
    a:focus {
      outline: 0;
      border: 0;
    }

    li:not(:first-of-type) {
      margin-left: 10px;
      cursor: pointer;
    }
    li.previous {
      cursor: pointer;
    }

    .active {
      color: ${(props) => props.theme.colors.brandGreen};
      font-weight: bold;
    }
    .disabled {
      opacity: 0.5;
      cursor: text !important;
    }
  }
`;
