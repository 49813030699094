// external libraries
import axios from 'axios';

// constants
import { BASE_URL } from '../constants';

export const getAllContacts = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/vendor/list/`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};
