import { InventorySearch } from 'pages/Dashboard/Inventory/StyledInventory';
import styled from 'styled-components';
import { devices } from 'theme';

export const ExternalContainer = styled.div`
  padding: 10px;
  padding-top: 0;
  h1 {
    margin: 0;
    font-size: 2em;
  }
  @media ${devices.md} {
    width: 95%;
    max-width: 1600px;
    margin: 0 auto;
  }
`;

export const ExternalInventorySearch = styled(InventorySearch)`
  margin-bottom: 30px;
`;

export const ExternalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 20px 0 30px;

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }

  .home:hover {
    color: #eca128;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.8em;

    li {
      cursor: pointer;
    }
    li:last-of-type {
      margin-left: 30px;
    }
  }

  @media ${devices.sm} {
    ul {
      font-size: 1em;
    }
  }
`;

export const ExternalSearch = styled.div`
  width: 80%;
  margin: 20px auto;
`;

export const ExternalButton = styled.button`
  padding: 15px 25px;
  background-color: #eca128;
  color: black;
  border-radius: 10px;
  outline: 0;
  cursor: pointer;
  border: 0;
  font-size: 1em;

  &:hover {
    transform: scale(1.05);
  }
`;
