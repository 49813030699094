import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Notiflix from 'notiflix';
import LoadingBar from 'react-top-loading-bar';
import ReactPaginate from 'react-paginate';

// components
import SearchBox from 'components/SearchBox';
import Table from 'components/Table';
import AddInventory from './AddInventory';
import EditInventoryItem from './EditInventoryItem';
import ToggleSwitch from 'components/ToggleSwitch';

// shared
import {
  Button,
  PaginateContainer,
  RightFooter,
  ModalHeader,
} from 'shared/styles';

// Styled component
import {
  InventoryContainer,
  InventoryHeader,
  InventoryFilter,
  InventoryHeaderOptions,
  InventoryMain,
  InventorySearch,
  InventorySearchBox,
  InventoryTable,
  InventoryAdd,
  FilterGroup,
} from './StyledInventory';

import { getAllInventory } from 'utils/api/inventory';

// Context
import { AppContext } from 'context/store';

// CONST
import { BASE_URL, CATEGORIES, LOCATIONS } from 'utils/api/constants';

// Setups

Modal.setAppElement('#root');

Notiflix.Confirm.Init({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Confirm.Merge({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Loading.Init({
  zindex: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
});

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxHeight: '95%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    borderRadius: '10px',
    zIndex: '1000',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.45)',
  },
};

const filterData = {
  location: [
    { name: 'All', value: '' },
    ...LOCATIONS.reduce((acc, curr) => {
      return [...acc, { name: curr, value: curr }];
    }, []),
  ],

  categories: [
    { name: 'All', value: '' },
    ...CATEGORIES.reduce((acc, curr) => {
      return [...acc, { name: curr, value: curr }];
    }, []),
  ],
};

const tableData = {
  colors: ['#274E24', 'black', 'black', 'black', 'black', 'black'],
  head: [
    'Product',
    'Botanical Name',
    'Category',
    'Classification',
    'Size',
    'Location',
    'Lot',
    'Quantity',
    'Cost',
    'Price',
    'Actions',
  ],
  variables: [
    'name',
    'botanical_name',
    'category',
    'classification',
    'size',
    'location',
    'lot',
    'cost',
    'price',
  ],
  body: null,
};

// main

const Inventory = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [editItem, setEditItem] = useState('');
  const [data, setData] = useState(tableData);
  const [isFilter, setIsFilter] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [filterLocation, setFilterLocation] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [scrollTableTop, setScrollTableTop] = useState(false);

  const {
    store: { token },
    actions: { setSidebar },
  } = useContext(AppContext);

  async function getInventory() {
    setLoadingInventory(true);
    try {
      const response = await getAllInventory(token);
      setPageCount(Math.ceil(response.count / 15));
      setData((data) => ({ ...data, body: response.results }));
      setLoadingInventory(false);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  if (loadingInventory) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  if (hasSearched && !searchTerm) {
    setHasSearched(false);
    getInventory();
  }

  const ref = useRef();

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setIsAddNew(false);
    setIsFilter(false);
  }

  function handleEdit(item) {
    setIsAddNew(false);
    setEditItem(item);
    openModal();
  }

  async function handleSearch() {
    setLoadingInventory(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/list/?name=${searchTerm}&location=${filterLocation}&category=${filterCategory}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      setPageCount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: response.data.results }));
      setLoadingInventory(false);
      setHasSearched(true);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  async function handlePageClick({ selected }) {
    setLoadingInventory(true);
    try {
      const response = await axios(
        `${BASE_URL}/products/?page=${selected + 1}`,
        {
          method: 'get',
          headers: {
            authorization: `token ${token}`,
          },
        }
      );
      setData((data) => ({ ...data, body: response.data.results }));
      setLoadingInventory(false);
      setScrollTableTop(true);
      setScrollTableTop(false);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  const handleFilterReset = () => {
    setFilterCategory('');
    setFilterLocation('');
  };

  const handleFilterApply = async () => {
    setLoadingInventory(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/list/?name=${searchTerm}&location=${filterLocation}&category=${filterCategory}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      setPageCount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: response.data.results }));
      setLoadingInventory(false);
      setHasSearched(true);
      closeModal();
    } catch (e) {
      setLoadingInventory(false);
      closeModal();
    }
  };

  useEffect(() => {
    setSidebar(null);
  }, [setSidebar]);

  useEffect(() => {
    async function getInventoryData() {
      setLoadingInventory(true);
      try {
        const response = await getAllInventory(token);
        setPageCount(Math.ceil(response.count / 15));
        setData((data) => ({ ...data, body: response.results }));
        setLoadingInventory(false);
      } catch (e) {
        setLoadingInventory(false);
      }
    }
    getInventoryData();
  }, [token]);

  return (
    <InventoryContainer>
      <LoadingBar color="#CD801F" ref={ref} />
      <h1>Inventory</h1>
      <InventoryHeader>
        <InventorySearch>
          <InventorySearchBox>
            <SearchBox
              inputValue={searchTerm}
              setInputValue={setSearchTerm}
              onSubmit={handleSearch}
            />
          </InventorySearchBox>
          <InventoryFilter
            onClick={() => {
              setIsFilter(true);
              openModal();
            }}
          >
            Filters
          </InventoryFilter>
        </InventorySearch>

        <InventoryHeaderOptions></InventoryHeaderOptions>
      </InventoryHeader>
      <InventoryMain>
        <InventoryTable>
          <Table
            data={data}
            onClickTableItem={handleEdit}
            scrollTop={scrollTableTop}
          />
        </InventoryTable>
        <PaginateContainer>
          <ReactPaginate
            previousLabel={'prev'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            disabledClassName="disabled"
          />
        </PaginateContainer>
        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          {modalIsOpen && (
            <>
              <ModalHeader onClick={closeModal}>
                <i>
                  <span
                    className="iconify"
                    data-icon="topcoat:cancel"
                    data-inline="false"
                  ></span>
                </i>
              </ModalHeader>
              {isFilter ? (
                <div>
                  <FilterGroup>
                    <h2>Location</h2>
                    {filterData.location.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <span>{item.name}</span>
                          <ToggleSwitch
                            checked={filterLocation === item.value}
                            setChecked={(state) =>
                              setFilterLocation(item.value)
                            }
                          />
                        </div>
                      );
                    })}
                  </FilterGroup>

                  <FilterGroup>
                    <h2>Category</h2>
                    {filterData.categories.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <span>{item.name}</span>
                          <ToggleSwitch
                            checked={filterCategory === item.value}
                            setChecked={(state) =>
                              setFilterCategory(item.value)
                            }
                          />
                        </div>
                      );
                    })}
                  </FilterGroup>

                  <RightFooter>
                    <Button isDelete onClick={handleFilterReset}>
                      Reset
                    </Button>
                    <Button onClick={handleFilterApply}>Apply</Button>
                  </RightFooter>
                </div>
              ) : (
                <>
                  {isAddNew ? (
                    <InventoryAdd>
                      <h2>Create Item</h2>
                      <AddInventory
                        closeModal={closeModal}
                        fetchInventory={getInventory}
                      />
                    </InventoryAdd>
                  ) : (
                    <InventoryAdd>
                      <h2>Product</h2>
                      <EditInventoryItem
                        toEdit={editItem}
                        closeModal={closeModal}
                        fetchInventory={getInventory}
                      />
                    </InventoryAdd>
                  )}
                </>
              )}
            </>
          )}
        </Modal>
      </InventoryMain>
    </InventoryContainer>
  );
};

export default Inventory;
