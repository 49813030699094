import React, { useState, useEffect } from 'react';

// styles (sass)
import './Dropdown.scss';

const Dropdown = ({
  data,
  height,
  func,
  defaulty,
  disabled,
  label,
  required,
  yes,
}) => {
  // Component State
  const [selected, setSelected] = useState(defaulty || data[0].name);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [optionsList] = useState(data);

  yes && console.log(data);
  // hide component when outside is clicked
  const handleDocumentClick = (e) => {
    if (
      !e.target.classList.contains('custom-select-option') &&
      !e.target.classList.contains('select-options')
    ) {
      setShowOptionsList(false);
    }
  };

  const handleListDisplay = () => {
    setShowOptionsList((previousState) => !previousState);
  };

  const handleOptionClick = (option) => {
    if (func) {
      func(option);
    }
    setSelected(option.name);
    handleListDisplay();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  return (
    <div
      className="custom-select-container"
      style={{
        height: `${height ? `${height}px` : '100%'}`,
      }}
    >
      <div
        className={showOptionsList ? 'selected-text active' : 'selected-text'}
        onClick={!disabled ? handleListDisplay : () => {}}
      >
        {label && (
          <span className={`dropdown__label ${required ? 'required' : null}`}>
            {label}
          </span>
        )}
        {selected}
      </div>
      {showOptionsList && (
        <ul className="select-options">
          {optionsList.map((option) => {
            return (
              <li
                className="custom-select-option"
                data-name={option.name}
                key={option.id}
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
