import styled from 'styled-components';

export const TerminalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DeviceCode = styled.div`
  border: solid 1px ${(props) => props.theme.colors.brandGreen};
  padding: 15px 30px;
  font-size: xx-large;
  border-radius: 10px;
`;
