import React, { useState } from 'react';
import Notiflix from 'notiflix';
// import { NotificationManager } from 'react-notifications';

// components
import Dropdown from 'components/Dropdown';
import FormInput from 'components/FormInput';
import { DoubleItems } from 'shared/styles';

// helpers
// import { deleteInventoryItem } from './api';

// con
// import { AppContext } from 'context/store';
// import { customError } from 'shared/helpers';
import { CATEGORIES, LOCATIONS, LOTS } from 'utils/api/constants';

const EditInventoryItem = ({ toEdit, closeModal, fetchInventory }) => {
  const [item, setItem] = useState(toEdit);
  const [loading] = useState(false);

  // context
  // const {
  //   store: { token },
  // } = useContext(AppContext);

  // loading
  if (loading) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  const edit = (key, value) => {
    setItem({
      ...item,
      [key]: value,
    });
  };

  // async function startDelete() {
  //   setLoading(true);
  //   try {
  //     await deleteInventoryItem(token, item.id);
  //     setLoading(false);
  //     NotificationManager.success('Item successfully Deleted', 'Success');
  //     closeModal();
  //     fetchInventory();
  //   } catch (e) {
  //     setLoading(false);
  //     customError(e);
  //     closeModal();
  //   }
  // }

  return (
    <form>
      <DoubleItems>
        <FormInput
          label="Product Name"
          type="name"
          inputValue={item.name}
          setInputValue={(value) => {}}
        />
        <FormInput
          label="Botanical Name"
          type="name"
          inputValue={item.botanical_name}
          setInputValue={(value) => {}}
        />
      </DoubleItems>
      <DoubleItems>
        <FormInput
          label="Classification"
          type="name"
          inputValue={item.classification}
          setInputValue={(value) => {}}
        />
        <Dropdown
          defaulty={item.category}
          data={CATEGORIES.map((item, idx) => ({
            id: idx,
            name: item,
          }))}
          disabled
        />
      </DoubleItems>

      <FormInput
        label="Size"
        type="name"
        inputValue={item.size}
        setInputValue={(value) => {}}
      />
      <FormInput
        label="Color"
        type="name"
        inputValue={item.color}
        setInputValue={(value) => {}}
      />
      <FormInput
        label="Quantity"
        type="name"
        inputValue={item.quantity}
        setInputValue={(value) => {}}
      />

      <DoubleItems>
        <Dropdown
          defaulty={`${item.location || 'Location'}`}
          data={LOCATIONS.map((item, idx) => ({
            id: idx,
            name: item,
          }))}
          func={(item) => edit('location', item.name)}
          disabled
        />

        <Dropdown
          defaulty={`${item.lot || 'Lot'}`}
          data={LOTS.map((item, idx) => ({
            id: idx,
            name: item,
          }))}
          func={(item) => edit('lot', item.name)}
          disabled
        />
      </DoubleItems>

      {/* <DoubleItems> */}

      {/* <FormInput
          label="Total Stock Quantity"
          type="name"
          inputValue={item.totalQuantity}
          setInputValue={(value) => {
            edit("totalQuantity", value);
          }}
        /> */}
      {/* </DoubleItems> */}

      <DoubleItems>
        <FormInput
          label="Cost"
          type="name"
          inputValue={item.cost}
          setInputValue={(value) => {
            edit('cost', value);
          }}
        />
        <FormInput
          label="Price"
          type="name"
          inputValue={item.price}
          setInputValue={(value) => {
            edit('price', value);
          }}
        />
      </DoubleItems>
    </form>
  );
};

export default EditInventoryItem;
