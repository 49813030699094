import ActivityCard from 'components/ActivityCard';
import CustomNotiflix from 'components/Notiflix';
import { AppContext } from 'context/store';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { PaginateContainer } from 'shared/styles';
import { getActivities } from './handlers';
import {
  ActivitiesTitleHeading,
  ActivitiesMain,
  ActivitiesContent,
} from './StyledActivities';

const Activities = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [scrollTop, setScrollTop] = useState(false);

  const ref = useRef(null);

  const {
    store: { token },
    actions: { setSidebar },
  } = useContext(AppContext);

  async function handlePageClick({ selected }) {
    setIsLoading(true);
    getActivities(token, selected + 1)
      .then((res) => {
        setData(res.results);
      })
      .finally(() => {
        setIsLoading(false);
        setScrollTop(true);
        setScrollTop(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    getActivities(token)
      .then((res) => {
        setPageCount(Math.ceil(res.count / 15));
        setData(res.results);
        setScrollTop(true);
        setScrollTop(false);
      })
      .finally(() => setIsLoading(false));
  }, [token]);

  useEffect(() => {
    setSidebar('Activity');
  }, [setSidebar]);

  useEffect(() => {
    if (scrollTop) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [scrollTop]);

  console.log(data);

  return (
    <div>
      <ActivitiesTitleHeading>
        <span>Activities</span>
      </ActivitiesTitleHeading>
      <ActivitiesMain>
        <ActivitiesContent>
          {data ? (
            <div>
              <div ref={ref}></div>
              {data.map((item, index) => (
                <ActivityCard
                  key={index}
                  heading={item.verb}
                  date={item.created}
                  token={token}
                  user={item.user}
                  item={item}
                />
              ))}
            </div>
          ) : (
            <em>Loading...</em>
          )}
        </ActivitiesContent>
        <CustomNotiflix shouldLoad={isLoading} />
        <PaginateContainer>
          <ReactPaginate
            previousLabel={'prev'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            disabledClassName="disabled"
          />
        </PaginateContainer>
      </ActivitiesMain>
    </div>
  );
};

export default Activities;
