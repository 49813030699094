import moment from 'moment';
import React from 'react';
import { Button } from 'shared/styles';
import { getUserImgUrl } from './handler';
import { useHistory } from 'react-router-dom';

import {
  CardContainer,
  CardFooter,
  CardHeader,
  CardImage,
  CardImageContainer,
  CardMain,
} from './StyledActivityCard';

const ActivityCard = ({ heading, date, token, user, item }) => {
  const history = useHistory();

  const handleOrder = () => {
    history.push({
      pathname: '/dashboard/activities/order',
      state: {
        order: item.target.order_items,
      },
    });
  };

  return (
    user && (
      <CardContainer>
        <CardImageContainer>
          <CardImage src={getUserImgUrl(user.first_name, user.last_name)} />
        </CardImageContainer>
        <CardMain>
          <CardHeader>
            {user.first_name + ' ' + user.last_name + ' '}
            {heading}
          </CardHeader>
          <CardFooter>
            Activity on {moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            {/* Activity on {moment(date).format('dddd, MMMM Do YYYY')} */}
          </CardFooter>
          <div style={{ marginTop: '20px' }}>
            <>
              {heading === 'created an order' && (
                <Button active style={{ padding: '8px' }} onClick={handleOrder}>
                  View
                </Button>
              )}
            </>
          </div>
        </CardMain>
      </CardContainer>
    )
  );
};

export default ActivityCard;
