import styled from "styled-components";

// Shared styles
import { TableContainer } from "shared/styles";

// Theming
import { devices } from "../../../theme";

export const InventoryContainer = styled.div`
  /* border: solid red 1px; */
  & > h1 {
    width: max-content;
    font-size: 1.5em;
    margin: 0 auto;
    position: relative;
    top: -50px;
  }

  @media ${devices.lg} {
    & > h1 {
      top: 0;
      margin: 0;
      margin-right: auto;
      margin-bottom: 50px;
    }
  }
`;

export const InventoryHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media ${devices.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const InventoryMain = styled.div``;

export const InventorySearch = styled.div`
  max-width: 350px;
  flex-grow: 1;
  display: flex;
  margin-bottom: 30px;
  @media ${devices.md} {
    margin-bottom: 0;
  }
`;

export const InventorySearchBox = styled.div`
  margin-right: 10px;
  flex-grow: 1;
`;
export const InventoryFilter = styled.button`
  padding: 10px;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: #f1f1f1;
  color: black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #cdcdcd;
  }
`;

export const InventoryHeaderOptions = styled.div`
  margin: 0;
  display: flex;
  button {
    display: flex;
    background-color: ${(props) => props.theme.colors.brandGreen};
    align-items: center;
    color: white;

    .iconify {
      margin-right: 10px;
    }
  }
`;

export const InventoryTable = styled(TableContainer)`
  @media ${devices.lg} {
    ${"" /* max-height: calc(100vh - 306px); */}
  }
`;

export const InventoryAdd = styled.div`
  width: 90%;
  margin: 0 auto;
  h2 {
    margin-bottom: 50px;
  }
  input {
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 42px;
`;

export const ModalButton = styled.div`
  padding: 10px 20px;
  outline: none;
  border: solid 1px
    ${(props) => (props.isDelete ? "#c70000" : props.theme.colors.brandGreen)};
  border-radius: 6px;
  background-color: white;
  color: ${(props) =>
    props.isDelete ? "#c70000" : props.theme.colors.brandGreen};
  cursor: pointer;

  &:last-of-type {
    margin-left: 25px;
  }

  &:hover {
    background-color: ${(props) =>
      props.isDelete ? "#c70000" : props.theme.colors.brandGreen};
    color: white;
  }
`;

export const ModalFlex = styled.div`
  display: flex;
  width: 100%;

  & > * {
    flex-basis: 50%;
    height: 55px !important;
  }
  & > :first-child {
    margin-right: 10px;
  }
`;

export const FilterGroup = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 100px;
  }

  & > h2 {
    margin-bottom: 30px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > div:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;
