import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';

export const refineData = (data) => {
  return data.map((item) => {
    const newObj = {};

    for (const val in item) {
      newObj[val] =
        typeof item[val] === 'object' && item[val] != null
          ? item[val].name
          : item[val];
    }

    return newObj;
  });
};

export const getAllCategories = async (token, returnData) => {
  const response = await axios.get(`${BASE_URL}/categories/`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return returnData ? response.data : response.data.results;
};

export const getAllLocations = async (token, returnData) => {
  const response = await axios.get(`${BASE_URL}/locations/`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return returnData ? response.data : response.data.results;
};

export const getAllClassifications = async (token, returnData) => {
  const response = await axios.get(`${BASE_URL}/classifications/`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return returnData ? response.data : response.data.results;
};

export const getAllLots = async (token, returnData) => {
  const response = await axios.get(`${BASE_URL}/lots/`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return returnData ? response.data : response.data.results;
};

export const getAllTaxes = async (token, returnData) => {
  const response = await axios.get(`${BASE_URL}/taxes/`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return returnData ? response.data : response.data.results;
};

export const getAllDiscounts = async (token, returnData) => {
  const response = await axios.get(`${BASE_URL}/discounts/`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return returnData ? response.data : response.data.results;
};
