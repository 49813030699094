import styled from 'styled-components';

export const ListDisplayContainer = styled.div`
  margin-top: 40px;

  & > header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  .Success {
    background-color: rgba(80, 207, 70, 0.2);
    padding: 2px 10px;
    border-radius: 10px;
  }

  .Unsuccessful {
    background-color: rgba(248, 43, 43, 0.16);
  }

  & > div {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  & > div:nth-child(even) {
    background-color: rgba(196, 196, 196, 0.3);
    border-radius: 10px;
  }
`;
