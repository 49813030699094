import Table from 'components/Table';
import {
  ActivitiesTitleHeading,
  BackLink,
} from 'pages/Dashboard/Activities/StyledActivities';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { TableContainer } from 'shared/styles';
import { theme } from 'theme';

const tableData = {
  colors: ['#274E24', 'black', 'black', 'black', 'black', 'black'],
  head: ['Product', 'Size', 'Sold Quantity', 'Price', ''],
  variables: ['name', 'size', 'sold_quantity', 'price', ''],
  body: null,
};

const ActivityOrder = () => {
  const [data, setData] = useState(tableData);

  const { state } = useLocation();
  const real = state.order && state.order.map((item) => item.product);

 
  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        body: real,
      };
    });
  }, [real]);

  return (
    <div>
      <ActivitiesTitleHeading>
        <span>Order</span>
      </ActivitiesTitleHeading>

      <TableContainer>
        <Table data={data} onClickTableItem={() => {}} dontshow />
      </TableContainer>

      <div style={{ marginTop: '30px', color: theme.colors.brandGreen }}>
        <BackLink to="/dashboard/activities">{'< Back'}</BackLink>
      </div>
    </div>
  );
};

export default ActivityOrder;
