// external libraries
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { customErrorMessage } from 'shared/helpers';

// constants
import { BASE_URL } from 'utils/api/constants';

export const registerUser = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios({
        method: 'post',
        url: `${BASE_URL}/auth/users/`,
        data,
      });

      NotificationManager.success(
        "Your activation link has been sent to your email, you'd be able to login after activation",
        'Successfully registered',
        6000
      );
      resolve('');
    } catch (e) {
      const { errorBody } = customErrorMessage(e);
      reject(errorBody);
    }
  });
};
