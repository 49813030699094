import styled from "styled-components";

export const SettingsContent = styled.div`
  display: flex;
`;

export const SettingsMain = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const SettingsAside = styled.div`
  width: max-content;
`;
