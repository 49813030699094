import React, { useState, useContext, useEffect, useRef } from 'react';

// components
import FormInput from 'components/FormInput';

import {
  SettingsProfileContainer,
  SettingsProfileInputs,
} from './StyledSettingsProfile';

// Context
import { AppContext } from 'context/store';
import { Button, LeftFooter } from 'shared/styles';
import { BASE_URL } from 'utils/api/constants';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import { customError, success } from 'shared/helpers';

const SettingsProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [, setLoading] = useState(false);

  const ref = useRef();

  const {
    store: { user, token },
    actions: { setUser },
  } = useContext(AppContext);

  const updateDetails = async (e) => {
    e.preventDefault();
    setLoading(true);
    ref.current.continuousStart();
    try {
      const user = await axios({
        url: `${BASE_URL}/profile/update/`,
        method: 'PATCH',
        headers: { Authorization: `token ${token}` },
        data: {
          first_name: firstName,
          last_name: lastName,
          phone_number: phone,
        },
      });
      ref.current.complete();
      setUser(user.data);
      setLoading(false);
      success('Details successfully updated');
    } catch (e) {
      customError(e);
      ref.current.complete();
      setLoading(false);
    }
  };

  useEffect(() => {
    setFirstName(user.user.first_name);
    setLastName(user.user.last_name);
    setEmail(user.user.email);
    setPhone(user.user.phone_number);
  }, [user]);

  return (
    <SettingsProfileContainer>
      <LoadingBar color="#CD801F" ref={ref} />
      <h1>User Profile</h1>
      <form onSubmit={updateDetails}>
        <SettingsProfileInputs>
          <FormInput
            label={'FirstName'}
            type={'name'}
            inputValue={firstName}
            setInputValue={setFirstName}
          />
          <FormInput
            label="LastName"
            type="name"
            inputValue={lastName}
            setInputValue={setLastName}
          />
          <FormInput
            label="Email"
            type="email"
            inputValue={email}
            setInputValue={() => {}}
          />
          <FormInput
            label="Phone Number"
            type="text"
            inputValue={phone}
            setInputValue={setPhone}
          />

          {/* <FormInput
          label="Group"
          type="text"
          inputValue={group}
          setInputValue={setGroup}
        /> */}
        </SettingsProfileInputs>

        {/* <h1>Business Information</h1>
      <SettingsProfileInputs>
        <FormInput
          label={"Business Name"}
          type={"name"}
          inputValue={businessName}
          setInputValue={setBusinessName}
        />
        <FormInput
          label="Address"
          type="name"
          inputValue={address}
          setInputValue={setAddress}
        />
      </SettingsProfileInputs> */}

        <LeftFooter>
          <Button type="submit" onClick={updateDetails}>
            Update
          </Button>
        </LeftFooter>
      </form>
    </SettingsProfileContainer>
  );
};

export default SettingsProfile;
