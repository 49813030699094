import React, { useContext, useEffect } from 'react';

// External Libraries
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

// styled components
import { SettingsContent, SettingsMain, SettingsAside } from './StyledSettings';

// components
import SettingsHeader from 'components/SettingsHeader';
import SettingsBar from 'components/SettingsBar';
import SettingsProfile from './SettingsProfile';
import Advanced from './SettingsAdvanced';
import { AppContext } from 'context/store';

const SettingsPage = ({
  match: {
    url,
    params: { settingType },
  },
}) => {
  const {
    store: { user },
    actions: { setSidebar },
  } = useContext(AppContext);

  useEffect(() => {
    setSidebar('Settings');
  }, [setSidebar]);

  return (
    <>
      <SettingsHeader active={settingType} />
      <SettingsContent>
        <SettingsAside>
          <SettingsBar type={settingType} />
        </SettingsAside>
        <SettingsMain>
          <Switch>
            <Route
              exact
              path={`${url}`}
              render={(props) => {
                switch (settingType) {
                  case 'profile':
                    return <SettingsProfile />;
                  // case 'access-control':
                  //   return <AccessControl />;
                  case 'access-control':
                    return user.user.user_type === 'admin' ? (
                      <Advanced />
                    ) : (
                      <Redirect to="/dashboard/settings/profile" />
                    );
                  default:
                    return <SettingsProfile />;
                }
              }}
            />
          </Switch>
        </SettingsMain>
      </SettingsContent>
    </>
  );
};

export default withRouter(SettingsPage);
