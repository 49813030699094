import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import axios from 'axios';
import Notiflix from 'notiflix';
import LoadingBar from 'react-top-loading-bar';
import ReactPaginate from 'react-paginate';

// components
import SearchBox from 'components/SearchBox';
import CustomModal from 'components/Modal';
import Table from 'components/Table';
import AddInventory from './AddInventory';
import EditInventoryItem from './EditInventoryItem';
import ToggleSwitch from 'components/ToggleSwitch';

// shared
import { Button, PaginateContainer, RightFooter } from 'shared/styles';

// Styled component
import {
  InventoryContainer,
  InventoryHeader,
  InventoryFilter,
  InventoryTitleHeading,
  InventoryHeaderOptions,
  InventoryMain,
  InventorySearch,
  InventorySearchBox,
  InventoryTable,
  InventoryAdd,
  FilterGroup,
} from './StyledInventory';

import { getAllInventory } from 'utils/api/inventory';

// Context
import { AppContext } from 'context/store';

// CONST
import { BASE_URL } from 'utils/api/constants';
import {
  getAllCategories,
  getAllLocations,
  getAllLots,
  getAllClassifications,
  refineData,
  getAllDiscounts,
  getAllTaxes,
} from './helpers';
import ItemDisplay from './ItemDisplay';
import Tooltip from 'react-tooltip-lite';
import { Link } from 'react-router-dom';
import { customError } from 'shared/helpers';
import { theme } from 'theme';

// Setups

Notiflix.Confirm.Init({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Confirm.Merge({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Loading.Init({
  zindex: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
});

// main

const Inventory = () => {
  const tableData = {
    colors: ['#274E24', 'black', 'black', 'black', 'black', 'black'],
    head: [
      'Product',
      'Botanical Name',
      'Category',
      'Classification',
      'Size',
      'Location',
      'Lot',
      'Quantity',
      // 'Cost',
      'Price',
      'Edit',
    ],
    variables: [
      'name',
      'botanical_name',
      'category',
      'classification',
      'size',
      'location',
      'lot',
      'quantity',
      // 'cost',
      'price',
    ],
    body: null,
  };

  const {
    store: { token, categories, locations, user },
    actions: {
      setSidebar,
      setCategories,
      setLocations,
      setLots,
      setClassifications,
      setTaxes,
      setDiscounts,
      setTotalCategories,
      setTotalClassifications,
      setTotalDiscounts,
      setTotalLocations,
      setTotalTaxes,
      setTotalLots,
    },
  } = useContext(AppContext);

  if (user.user.user_type === 'admin') {
    tableData.head.splice(-2, 0, 'Cost');
    tableData.variables.splice(-1, 0, 'cost');
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [editItem, setEditItem] = useState('');
  const [isViewItem, setIsViewItem] = useState(false);
  const [data, setData] = useState(tableData);
  const [isFilter, setIsFilter] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [filterLocation, setFilterLocation] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [scrollTableTop, setScrollTableTop] = useState(false);

  const filterData = {
    location: [
      { name: 'All', value: '' },
      ...locations.reduce((acc, curr) => {
        return [...acc, { name: curr.name, value: curr.name }];
      }, []),
    ],

    categories: [
      { name: 'All', value: '' },
      ...categories.reduce((acc, curr) => {
        return [...acc, { name: curr.name, value: curr.name }];
      }, []),
    ],
  };

  async function getInventory() {
    setLoadingInventory(true);
    try {
      const response = await getAllInventory(token);
      setPageCount(Math.ceil(response.count / 15));
      setData((data) => ({ ...data, body: refineData(response.results) }));
      setLoadingInventory(false);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  if (loadingInventory) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  if (hasSearched && !searchTerm) {
    setHasSearched(false);
    getInventory();
  }

  const ref = useRef();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setIsAddNew(false);
    setIsFilter(false);
    setIsViewItem(false);
  }

  function handleNew() {
    setIsAddNew(true);
    openModal();
  }

  function handleEdit(item, notEditable) {
    if (notEditable) {
      setIsViewItem(true);
    }
    setIsAddNew(false);
    setEditItem(item);
    openModal();
  }

  async function handleSearch() {
    if (searchTerm.length === 12 && Number(searchTerm)) {
      setLoadingInventory(true);
      try {
        await axios({
          method: 'GET',
          url: `${BASE_URL}/product/barcode_search/?barcode_digit=${searchTerm}`,
          headers: {
            authorization: `token ${token}`,
          },
        })
          .then(({ data }) => {
            if (data.results && data.results[0]) {
              setData((prevData) => ({
                ...prevData,
                body: refineData(data.results),
              }));
              handleEdit(data.results[0], true);
            }
          })
          .finally(() => {
            setLoadingInventory(false);
            setHasSearched(true);
          });
        return;
      } catch (e) {
        if (e.message) {
          customError(e, e.message);
        } else {
          customError(e);
        }
      }
      return;
    }
    setLoadingInventory(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/list/?name=${searchTerm}&location__name=${filterLocation}&category__name=${filterCategory}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      setPageCount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: refineData(response.data.results) }));
      setLoadingInventory(false);
      setHasSearched(true);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  async function handlePageClick({ selected }) {
    setLoadingInventory(true);
    try {
      const response = await axios(
        `${BASE_URL}/products/?page=${selected + 1}`,
        {
          method: 'get',
          headers: {
            authorization: `token ${token}`,
          },
        }
      );
      setData((data) => ({ ...data, body: refineData(response.data.results) }));
      setLoadingInventory(false);
      setScrollTableTop(true);
      setScrollTableTop(false);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  const handleFilterReset = () => {
    setFilterCategory('');
    setFilterLocation('');
  };

  const handleFilterApply = async () => {
    setLoadingInventory(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/list/?name=${searchTerm}&location__name=${filterLocation}&category__name=${filterCategory}`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      setPageCount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: refineData(response.data.results) }));
      setLoadingInventory(false);
      searchTerm && setHasSearched(true);
      closeModal();
    } catch (e) {
      setLoadingInventory(false);
      closeModal();
    }
  };

  const getCategories = useCallback(getAllCategories, []);
  const getLocations = useCallback(getAllLocations, []);
  const getLots = useCallback(getAllLots, []);
  const getDiscounts = useCallback(getAllDiscounts, []);
  const getTaxes = useCallback(getAllTaxes, []);
  const getClassifications = useCallback(getAllClassifications, []);

  useEffect(() => {
    setSidebar('Inventory');
  }, [setSidebar]);

  useEffect(() => {
    async function getInventoryData() {
      setLoadingInventory(true);
      try {
        const response = await getAllInventory(token);
        setPageCount(Math.ceil(response.count / 15));
        setData((data) => ({ ...data, body: response.results }));
        setLoadingInventory(false);
      } catch (e) {
        setLoadingInventory(false);
      }
    }
    getInventoryData();
  }, [token]);

  useEffect(() => {
    getCategories(token).then((res) => setCategories(res));
  }, [getCategories, setCategories, token]);

  useEffect(() => {
    getClassifications(token).then((res) => setClassifications(res));
  }, [getClassifications, setClassifications, token]);

  useEffect(() => {
    getLocations(token).then((res) => setLocations(res));
  }, [token, getLocations, setLocations]);

  useEffect(() => {
    getLots(token).then((res) => setLots(res));
  }, [getLots, token, setLots]);

  useEffect(() => {
    getTaxes(token).then((res) => setTaxes(res));
  }, [getTaxes, token, setTaxes]);
  useEffect(() => {
    getDiscounts(token).then((res) => setDiscounts(res));
  }, [getDiscounts, token, setDiscounts]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/categories/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalCategories(data.results);
    });

    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/taxes/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalTaxes(data.results);
    });

    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/discounts/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalDiscounts(data.results);
    });
    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/lots/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalLots(data.results);
    });
    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/classifications/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalClassifications(data.results);
    });
    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/locations/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalLocations(data.results);
    });
  }, [
    token,
    setTotalCategories,
    setTotalClassifications,
    setTotalDiscounts,
    setTotalLocations,
    setTotalTaxes,
    setTotalLots,
  ]);

  return (
    <InventoryContainer>
      <LoadingBar color="#CD801F" ref={ref} />
      <InventoryTitleHeading>
        <span>Inventory</span>
        <Tooltip content="Options">
          <Link to="/dashboard/options">
            <span
              className="iconify"
              data-icon="ion:options-outline"
              data-inline="false"
              color={theme.colors.brandGreen}
            ></span>
          </Link>
        </Tooltip>
      </InventoryTitleHeading>
      <InventoryHeader>
        <InventorySearch>
          <InventorySearchBox>
            <SearchBox
              inputValue={searchTerm}
              setInputValue={setSearchTerm}
              onSubmit={handleSearch}
              placeholder="Search or scan barcode"
            />
          </InventorySearchBox>
          <InventoryFilter
            onClick={() => {
              setIsFilter(true);
              openModal();
            }}
          >
            Filters
          </InventoryFilter>
        </InventorySearch>

        <InventoryHeaderOptions>
          <Button onClick={handleNew}>
            <span
              className="iconify"
              data-icon="bx:bx-message-square-add"
              data-inline="false"
            ></span>
            <span>Add</span>
          </Button>
        </InventoryHeaderOptions>
      </InventoryHeader>
      <InventoryMain>
        <InventoryTable>
          <Table
            data={data}
            onClickTableItem={handleEdit}
            scrollTop={scrollTableTop}
          />
        </InventoryTable>

        <PaginateContainer>
          <ReactPaginate
            previousLabel={'prev'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            disabledClassName="disabled"
          />
        </PaginateContainer>

        <CustomModal isOpen={modalIsOpen} onRequestClose={closeModal}>
          {modalIsOpen && (
            <>
              {isFilter ? (
                <div>
                  <FilterGroup>
                    <h2>Location</h2>
                    {filterData.location.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <span style={{ marginRight: '15px' }}>
                            {item.name}
                          </span>
                          <ToggleSwitch
                            checked={filterLocation === item.value}
                            setChecked={(state) =>
                              setFilterLocation(item.value)
                            }
                          />
                        </div>
                      );
                    })}
                  </FilterGroup>

                  <FilterGroup>
                    <h2>Category</h2>
                    {filterData.categories.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <span style={{ marginRight: '15px' }}>
                            {item.name}
                          </span>
                          <ToggleSwitch
                            checked={filterCategory === item.value}
                            setChecked={(state) =>
                              setFilterCategory(item.value)
                            }
                          />
                        </div>
                      );
                    })}
                  </FilterGroup>

                  <RightFooter>
                    <Button isDelete onClick={handleFilterReset}>
                      Reset
                    </Button>
                    <Button onClick={handleFilterApply}>Apply</Button>
                  </RightFooter>
                </div>
              ) : (
                <>
                  {isAddNew ? (
                    <InventoryAdd>
                      <h2>Create Item</h2>
                      <AddInventory
                        closeModal={closeModal}
                        fetchInventory={getInventory}
                      />
                    </InventoryAdd>
                  ) : (
                    <InventoryAdd>
                      {isViewItem ? null : <h2>Edit Item</h2>}
                      {isViewItem ? (
                        <ItemDisplay item={editItem} />
                      ) : (
                        <EditInventoryItem
                          toEdit={editItem}
                          closeModal={closeModal}
                          fetchInventory={getInventory}
                        />
                      )}
                    </InventoryAdd>
                  )}
                </>
              )}
            </>
          )}
        </CustomModal>
      </InventoryMain>
    </InventoryContainer>
  );
};

export default Inventory;
