import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Theming
import { devices } from '../../theme';

export const SettingsBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  margin-right: 90px;

  @media ${devices.lsm} {
    display: none;
  }
`;

export const SettingsBarItem = styled(Link)`
  color: ${(props) => (props.active ? 'black' : 'rgba(0,0,0,0.5)')};
  background-color: ${(props) =>
    props.active ? props.theme.colors.brandLemon : 'white'};
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 7px;
  font-weight: ${(props) => (props.active ? '500' : 'normal')};

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;
