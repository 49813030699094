import React from 'react';
import { TabContainer, TabContent, TabItem } from './StyledTabs';

const Tabs = ({ data, active = 0, setTab }) => {
  return (
    <TabContainer>
      <TabContent>
        {data.map((item, index) => (
          <TabItem
            key={index}
            active={index === active ? true : undefined}
            onClick={() => {
              setTab(index);
            }}
          >
            {item.title}
          </TabItem>
        ))}
      </TabContent>
    </TabContainer>
  );
};

export default Tabs;
