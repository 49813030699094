import React, { useState, useRef } from 'react';

// Styled Components
import { StyledFormGroup, StyledInput, StyledLabel } from './StyledInput';

const FormInput = ({
  label,
  type,
  inputValue,
  setInputValue,
  required,
  placeholder,
  disabled,
}) => {
  // Input state
  const [isInputActive, setIsInputActive] = useState(false);

  // deactivate Input
  const deactivateInput = () => {
    setIsInputActive(false);
  };

  // activate Input
  const activateInput = () => {
    setIsInputActive(true);
  };

  // change Input value
  const changeInput = (e) => {
    setInputValue(e.target.value);
  };

  // input Ref
  const inputRef = useRef(null);

  return (
    <StyledFormGroup disabled={disabled}>
      <StyledLabel
        required={required ? true : undefined}
        shouldMove={
          isInputActive || (!isInputActive && inputValue) ? true : undefined
        }
        onClick={() => {
          setIsInputActive(true);
          inputRef.current.focus();
        }}
      >
        {label}
      </StyledLabel>
      <StyledInput
        onFocus={activateInput}
        onBlur={deactivateInput}
        value={inputValue}
        onChange={changeInput}
        type={type}
        ref={inputRef}
        required={required}
        placeholder={placeholder}
      />
    </StyledFormGroup>
  );
};

export default FormInput;
