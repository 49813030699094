import ExternalTable from 'components/ExternalTable';
import React, { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
  Button,
  PaginateContainer,
  RightFooter,
  TableContainer,
} from 'shared/styles';
import { ExternalContainer, ExternalInventorySearch } from './StyledExternal';
import Header from './Header';
import SearchBox from 'components/SearchBox';
import {
  FilterGroup,
  InventoryFilter,
  InventorySearchBox,
} from 'pages/Dashboard/Inventory/StyledInventory';
import CustomModal from 'components/Modal';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';
import CustomNotiflix from 'components/Notiflix';
import ToggleSwitch from 'components/ToggleSwitch';
import View from './view';
import { AppContext } from 'context/store';

const tableData = {
  colors: ['#274E24', 'black', 'black', 'black', 'black', 'black'],
  head: [
    '',
    'Product',
    'Botanical Name',
    'Category',
    'Classification',
    'Size',
    'Price',
    'View',
  ],
  variables: [
    'image',
    'name',
    'botanical_name',
    'category',
    'classification',
    'size',
    'price',
  ],
  body: null,
};


const External = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilter, setIsFilter] = useState(false);
  const [, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(tableData);
  const [pagecount, setPagecount] = useState(1);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [scrollTableTop, setScrollTableTop] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [toView, setToView] = useState(null);
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');

  const {
    store: { token, totalCategories },
    actions: { setTotalCategories },
  } = useContext(AppContext);

  const initialCategory = [{ name: 'All', value: '' }];

  const categories = totalCategories.map((item) => {
    return {
      name: item.name,
      value: item.name,
    };
  });
  const mainCategory = initialCategory.concat(categories);

  useEffect(() => {
    axios({
      method: 'GET',
      url: BASE_URL + '/unpaginated/categories/',
      headers: {
        authorization: `token ${token}`,
      },
    }).then(({ data }) => {
      setTotalCategories(data.results);
    });
  }, [setTotalCategories, token]);


  function filterItem() {
    setIsOpen(true);
    setIsFilter(true);
    setIsView(false);
  }

  function closeModal() {
    setIsOpen(false);
    setIsView(false);
    setIsFilter(false);
  }

  function viewItem(entry) {
    setIsOpen(true);
    setIsFilter(false);
    setIsView(true);
    setToView(entry);
  }

  function sortData(data) {
    return data.map((item, index) => ({
      ...item,
      classification: item.classification.name,
      location: item.location.name,
      category: item.category.name,
      lot: item.lot.name,
    }));
  }
  const handleFilterApply = async () => {
    setLoadingInventory(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/external/list/?name=${searchTerm}&location_name=${location}&category_name=${category}`,
      });
      setPagecount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: sortData(response.data.results) }));
      setLoadingInventory(false);
      setHasSearched(true);
      closeModal();
    } catch (e) {
      setLoadingInventory(false);
      closeModal();
    }
  };
  const handleFilterReset = () => {
    setCategory('');
    setLocation('');
  };

  async function handleSearch() {
    setLoadingInventory(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/product/external/list/?name=${searchTerm}&location_name=${location}&category_name=${category}`,
      });
      setPagecount(Math.ceil(response.data.count / 15));
      setData((data) => ({ ...data, body: sortData(response.data.results) }));
      setLoadingInventory(false);
      setHasSearched(true);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  const getProducts = () => {
    return axios({
      method: 'GET',
      url: `${BASE_URL}/product/external/list/`,
    });
  };

  async function handlePageClick({ selected }) {
    setLoadingInventory(true);
    try {
      const response = await axios(
        `${BASE_URL}/product/external/list/?page=${selected + 1}`,
        {
          method: 'get',
        }
      );
      setData((tableData) => ({
        ...tableData,
        body: sortData(response.data.results),
      }));
      setLoadingInventory(false);
      setScrollTableTop(true);
      setScrollTableTop(false);
    } catch (e) {
      setLoadingInventory(false);
    }
  }

  if (hasSearched && !searchTerm) {
    setHasSearched(false);
    axios({
      method: 'GET',
      url: `${BASE_URL}/product/external/list/?name=&location__name=${location}&category__name=${category}`,
    }).then(({ data }) => {
      setData((prev) => {
        return {
          ...prev,
          body: sortData(data.results),
        };
      });
      setPagecount(Math.ceil(data.count / 15));
    });
  }

  useEffect(() => {
    getProducts().then(({ data }) => {
      setData((prev) => {
        return {
          ...prev,
          body: sortData(data.results),
        };
      });
      setPagecount(Math.ceil(data.count / 15));
    });
  }, []);

  return (
    <ExternalContainer>
      <Header />
      <h1 align="center" style={{ marginBottom: '30px' }}>
        Inventory
      </h1>
      <ExternalInventorySearch>
        <InventorySearchBox>
          <SearchBox
            inputValue={searchTerm}
            setInputValue={setSearchTerm}
            onSubmit={handleSearch}
          />
        </InventorySearchBox>
        <InventoryFilter onClick={filterItem}>Filters</InventoryFilter>
      </ExternalInventorySearch>
      <TableContainer>
        <ExternalTable
          data={data}
          onClickTableItem={viewItem}
          scrollTop={scrollTableTop}
        />
      </TableContainer>
      <PaginateContainer>
        <ReactPaginate
          previousLabel={'prev'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pagecount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          disabledClassName="disabled"
        />
      </PaginateContainer>
      <CustomModal isOpen={isOpen} onRequestClose={closeModal}>
        {isFilter ? (
          <div>
            <FilterGroup>
              <h2>Category</h2>
              {mainCategory.map((item, idx) => {
                return (
                  <div key={idx}>
                    <span style={{ marginRight: '15px' }}>{item.name}</span>
                    <ToggleSwitch
                      checked={category === item.value}
                      setChecked={(state) => setCategory(item.value)}
                    />
                  </div>
                );
              })}
            </FilterGroup>

            <RightFooter>
              <Button isDelete onClick={handleFilterReset}>
                Reset
              </Button>
              <Button onClick={handleFilterApply}>Apply</Button>
            </RightFooter>
          </div>
        ) : (
          <div>{toView && <View item={toView} />}</div>
        )}
      </CustomModal>
      <CustomNotiflix shouldLoad={loadingInventory} />
    </ExternalContainer>
  );
};

export default External;
