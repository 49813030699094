import React from 'react';
import { ListDisplayContainer } from './StyledListDisplay';

const ListDisplay = ({ data }) => {
  return (
    <ListDisplayContainer>
      <header>
        <span>{data.date}</span>
        <span className={`${data.status}`}>{data.status}</span>
      </header>
      <hr />

      {data.rows.map((item, index) => {
        return (
          <div key={index}>
            <span>{item.key}</span>
            <span>{item.value}</span>
          </div>
        );
      })}
    </ListDisplayContainer>
  );
};

export default ListDisplay;
