// external libraries
import axios from 'axios';
import { customErrorMessage } from 'shared/helpers';

// constants
import { BASE_URL } from 'utils/api/constants';

export const registerVendor = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await axios({
        method: 'post',
        url: `${BASE_URL}/auth/vendors/`,
        data,
      });

      resolve('');
    } catch (e) {
      const { errorBody } = customErrorMessage(e);
      reject(errorBody);
    }
  });
