import React from 'react';

import { ExternalButton, ExternalHeader } from './StyledExternal';

const Header = () => {
  return (
    <ExternalHeader>
      <ul>
        <li className="home">
          <a href="https://www.annursery.com" target="_blank" rel="noreferrer">
            Home
          </a>
        </li>
        <li>
          <ExternalButton>
            <a
              href="https://www.annursery.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </ExternalButton>
        </li>
      </ul>
    </ExternalHeader>
  );
};

export default Header;
