import styled from 'styled-components';

// Theming
import { devices } from '../../theme';

// Assets
import Farm from '@images/activate.svg';

export const StyledWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: url(${Farm}), rgba(255, 255, 255, 0.8);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${devices.lg} {
    background: none;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;

  @media ${devices.lg} {
    width: 100%;
    min-height: 100vh;
  }
`;

export const StyledMain = styled.main`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  @media ${devices.lg} {
    flex-basis: 55%;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const StyledLogo = styled.img`
  width: 70px;
  margin: auto;
`;

export const StyledHeading = styled.h1`
  margin-bottom: 30px;
  margin-top: 50px;
  color: ${(props) => props.theme.colors.brandGreen};
`;

export const StyledFormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSpacer = styled.div`
  display: flex;
  column-gap: 10%;
  width: 100%;
  margin: 0;
  padding: 0;

  & > * {
    flex-grow: 1;
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFooterText = styled.span`
  font-size: small;
`;

export const StyledAside = styled.aside`
  @media ${devices.lg} {
    flex-basis: 45%;
    background: url(${Farm});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;
