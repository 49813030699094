import React, { useContext, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import Notiflix from 'notiflix';

// shared styled components
import { Button, DoubleItems, RightFooter } from 'shared/styles';

// components
import FormInput from 'components/FormInput';
import Dropdown from 'components/Dropdown';

// API
import { createInventoryItem } from './api';

// helpers
import { customError } from 'shared/helpers';
import { AppContext } from 'context/store';
import { CATEGORIES, LOCATIONS, LOTS } from 'utils/api/constants';

const AddInventory = ({ closeModal, fetchInventory }) => {
  // state
  const [product, setProduct] = useState('');
  const [botanicalName, setBotanicalName] = useState('');
  const [classification, setClassification] = useState('');
  const [category, setCategory] = useState('');
  const [color, setColor] = useState('');
  const [quantity, setQuantity] = useState('');
  // const [totalQuantity, setTotalQuantity] = useState("");
  const [lot, setLot] = useState('');
  const [size, setSize] = useState('');
  const [location] = useState('');
  const [cost, setCost] = useState('');
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState('');

  // context
  const {
    store: { token },
  } = useContext(AppContext);

  // loading
  if (loading) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  // constants
  const disableCreateItem = !product || loading;

  const data = {
    name: product,
    botanicalName,
    category,
    color,
    classification,
    quantity,
    lot,
    size,
    location,
    cost,
    price,
  };

  const createItem = async (e) => {
    setLoading(true);
    try {
      await createInventoryItem(token, data);
      setLoading(false);
      NotificationManager.success('Item successfully created', 'Success');
      closeModal();
      fetchInventory();
    } catch (e) {
      setLoading(false);
      customError(e);
      closeModal();
    }
  };

  return (
    <form>
      <DoubleItems>
        <FormInput
          label="Product Name"
          type="name"
          inputValue={product}
          setInputValue={setProduct}
        />
        <FormInput
          label="Botanical Name"
          type="name"
          inputValue={botanicalName}
          setInputValue={setBotanicalName}
        />
      </DoubleItems>

      <DoubleItems>
        <FormInput
          label="Classification"
          type="name"
          inputValue={classification}
          setInputValue={setClassification}
        />
        <Dropdown
          defaulty="Category"
          data={CATEGORIES.map((item, idx) => ({
            id: idx,
            name: item,
          }))}
          func={(item) => setCategory(item.name)}
        />
      </DoubleItems>

      <FormInput
        label="Color"
        type="name"
        inputValue={color}
        setInputValue={setColor}
      />

      <FormInput
        label="Size"
        type="name"
        inputValue={size}
        setInputValue={setSize}
      />
      <FormInput
        label="Quantity"
        type="name"
        inputValue={quantity}
        setInputValue={setQuantity}
      />

      <DoubleItems>
        <Dropdown
          defaulty="Location"
          data={LOCATIONS.map((item, idx) => ({
            id: idx,
            name: item,
          }))}
          func={(item) => setLot(item.name)}
        />

        <Dropdown
          defaulty="Lot"
          data={LOTS.map((item, idx) => ({
            id: idx,
            name: item,
          }))}
          func={(item) => setLot(item.name)}
        />
      </DoubleItems>

      {/* <DoubleItems> */}

      {/* <FormInput
          label="Total Stock Quantity"
          type="name"
          inputValue={totalQuantity}
          setInputValue={setTotalQuantity}
        /> */}
      {/* </DoubleItems> */}
      <DoubleItems>
        <FormInput
          label="Cost"
          type="name"
          inputValue={cost}
          setInputValue={setCost}
        />
        <FormInput
          label="Price"
          type="name"
          inputValue={price}
          setInputValue={setPrice}
        />
      </DoubleItems>
      <RightFooter>
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (!disableCreateItem) {
              createItem();
            } else if (loading) {
              NotificationManager.warning('Please wait...');
            } else {
              NotificationManager.warning('Please fill required fields');
            }
          }}
        >
          Create
        </Button>
      </RightFooter>
    </form>
  );
};

export default AddInventory;
