import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { devices } from 'theme';

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;

  .report__button {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      background-color: white;
      color: ${(props) => props.theme.colors.brandOffGreen};
    }
    button:hover {
      background-color: white;
      color: ${(props) => props.theme.colors.brandGreen};
    }
  }

  @media ${devices.md} {
    flex-direction: row;
  }

  .report__table {
    margin-bottom: 50px;
    @media ${devices.md} {
      margin-bottom: 0px;
      margin-right: 50px;
      flex-grow: 1;
    }
  }
`;
export const ReportScan = styled.input`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
  outline: 0;
  border: 1px solid rgba(39, 78, 36, 0.8);
  padding: 10px 10px;
  border-radius: 5px;

  &:active,
  &:focus {
    border: 1px solid rgba(39, 78, 36, 0.8);
  }
`;

export const ReportSummary = styled.div`
  background-color: ${(props) => props.theme.colors.brandGreen};
  padding: 30px;
  color: white;
  min-width: 250px;
  height: max-content;

  h2 {
    margin-top: 0;
  }
`;

export const ReportSearch = styled.div`
  margin-bottom: 50px;
  width: 80%;
  max-width: 320px;
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;

export const EditQuantity = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;

  h3 {
    width: max-content;
    margin: 0 0 35px 0;
  }

  & > button {
    margin-top: 30px;
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 20px;
    font-weight: bold;
  }
`;

export const EditAction = styled.button`
  background-color: ${(props) => props.color};
  color: white;
  padding: 7px 10px;
  cursor: pointer;
  border: none;
  outline: 0;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  border-radius: 3px;
`;

export const ClearTransaction = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.brandDelete};
  cursor: pointer;
  .iconify {
    width: 30px;
    height: auto;
  }

  span:first-of-type {
    margin-left: 7px;
  }

  &:hover {
    font-weight: 500;
    .iconify {
      width: 32px;
      height: auto;
    }
  }
`;

export const DeviceCodeError = styled(Link)`
  margin-bottom: 20px;
  font-size: small;
  color: #ff0000;
  font-style: oblique;
  font-weight: bold;
`;
