import FormikInput from 'components/FormikInput';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Button, FormErrorMessage, RightFooter } from 'shared/styles';
import * as Yup from 'yup';
import { Heading } from './StyledSettingsAdvanced';
import { handleInvite } from './handlers';
import { AppContext } from 'context/store';
import { NotificationManager } from 'react-notifications';
import { customErrorMessage } from 'shared/helpers';

const Invite = React.forwardRef((props, ref) => {
  const [formError, setFormError] = useState('');
  const { close } = props;

  const {
    store: { token },
  } = useContext(AppContext);

  const handleInvitation = (values, setSubmitting) => {
    ref.current?.continuousStart();
    handleInvite(token, values)
      .then(() => {
        NotificationManager.success('Invitation sent', 'Success');
        close();
      })
      .catch((e) => {
        const { errorBody } = customErrorMessage(e);
        setFormError(errorBody | 'An Error occured');
      })
      .finally(() => {
        setSubmitting(false);
        ref.current?.complete();
      });
  };

  const InvitationSchema = Yup.object({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '' }}
        validationSchema={InvitationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleInvitation(values, setSubmitting);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          getFieldProps,
          setFieldValue,
          isValid,
          isDirty,
        }) => {
          return (
            <Form>
              {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
              <Heading>Invite User</Heading>
              <FormikInput
                label="Name"
                type="name"
                required
                name="firstName"
                errorMessage={
                  errors.name && touched.name ? errors.name : undefined
                }
                {...getFieldProps('name')}
              />

              <FormikInput
                label="Email"
                type="email"
                required
                name="email"
                errorMessage={
                  errors.email && touched.email ? errors.email : undefined
                }
                {...getFieldProps('email')}
              />

              <RightFooter>
                <Button
                  active
                  disabled={isSubmitting || !isValid || isDirty}
                  isDisabled={
                    isSubmitting || !isValid || isDirty ? 1 : undefined
                  }
                  type="submit"
                >
                  Invite
                </Button>
              </RightFooter>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export default Invite;
