// external libraries
import axios from 'axios';

// constants
import { BASE_URL } from 'utils/api/constants';

// main

export const getAllInventory = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/products/`,
        headers: {
          authorization: `token ${token}`,
        },
      });

      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createInventoryItem = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${BASE_URL}/product/create/`,
        headers: {
          authorization: `token ${token}`,
        },
        data,
      });

      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const editInventoryItem = (token, data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios({
        method: 'PUT',
        url: `${BASE_URL}/products/${id}/`,
        headers: {
          authorization: `token ${token}`,
        },
        data,
      });

      resolve('');
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteInventoryItem = (token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios({
        method: 'delete',
        url: `${BASE_URL}/products/${id}/`,
        headers: {
          authorization: `token ${token}`,
        },
      });
      // if successful
      resolve('');
    } catch (e) {
      reject(e);
    }
  });
};
