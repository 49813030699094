import { AppContext } from 'context/store';
import React, { useContext, useState } from 'react';

// Styled components
import { SettingsBarContainer, SettingsBarItem } from './StyledSettingsBar';

const settings = ['profile', 'access-control'];

const SettingsBar = ({ type }) => {
  const [activeIndex, setActiveIndex] = useState(settings.indexOf(type));

  let data = [
    {
      title: 'Profile',
      url: 'profile',
    },
    // {
    //   title: 'Access Control',
    //   url: 'access-control',
    // },
  ];
  const {
    store: { user },
  } = useContext(AppContext);

  if (user.user.user_type === 'admin') {
    data.push({
      title: 'Access Control',
      url: 'access-control',
    });
  }

  return (
    <SettingsBarContainer>
      {data.map((item, index) => (
        <SettingsBarItem
          key={index}
          active={activeIndex === index ? 1 : undefined}
          onClick={() => setActiveIndex(index)}
          to={`/dashboard/settings/${item.url}`}
        >
          {item.title}
        </SettingsBarItem>
      ))}
    </SettingsBarContainer>
  );
};

export default SettingsBar;
