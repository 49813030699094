import React from "react";

// Styles
import "./toggleSwitch.scss";

const ToggleSwitch = ({ checked, setChecked }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => setChecked && setChecked(e.target.checked)}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
