import React, { useState } from 'react';

// External Libraries
// import { withRouter } from "react-router-dom";
// import LoadingBar from "react-top-loading-bar";

// Styled components
import {
  StyledContainer,
  StyledAside,
  StyledButton,
  StyledFooter,
  StyledForm,
  StyledFormHeader,
  StyledLogo,
  StyledMain,
  StyledFooterLink,
  StyledFooterText,
  StyledHeading,
  StyledWrapper,
} from './StyledForgotPassword';

// Components
import FormInput from '@components/FormInput';

// Assets
import Logo from '@images/logo2.svg';

// API
// import {  } from "../../utils/api/users";

const ForgotPassword = ({ history }) => {
  // Component State
  const [email, setEmail] = useState('');
  //   const [isLoading, setIsLoading] = useState(false);

  // Loader ref
  //   const ref = useRef(null);

  return (
    <StyledWrapper>
      {/* <LoadingBar color="#274E24" ref={ref} /> */}
      <StyledContainer>
        <StyledMain>
          <StyledFormHeader>
            <StyledLogo src={Logo} alt="logo" />
            <StyledHeading>Forgot Password</StyledHeading>
          </StyledFormHeader>
          <StyledForm>
            <FormInput
              label="Email"
              type="email"
              inputValue={email}
              setInputValue={setEmail}
            />
            <StyledButton
              type="submit"
              //   onClick={handleLogin}
              //   disabled={isLoading}
            >
              Reset
            </StyledButton>
            <StyledFooter>
              <StyledFooterText>
                New user?{' '}
                <StyledFooterLink to="/register">
                  Create Account
                </StyledFooterLink>
              </StyledFooterText>
            </StyledFooter>
          </StyledForm>
        </StyledMain>
        <StyledAside></StyledAside>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default ForgotPassword;
