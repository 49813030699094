import styled from 'styled-components';

export const ItemImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  margin: 30px auto;
`;

export const ItemImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
