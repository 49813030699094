import React, { useRef, useState } from 'react';

// components
import ToggleSwitch from 'components/ToggleSwitch';
import {
  PermissionGroup,
  SaveButtonContainer,
  SwitchFlex,
} from './StyledSettingsAdvanced';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';
import { Button } from 'shared/styles';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import { customError } from 'shared/helpers';
import LoadingBar from 'react-top-loading-bar';

const typeObject = {
  'Sales Rep': 'internal',
  Vendor: 'external',
  Administrator: 'admin',
};

const transformData = (user) => {
  return {
    ...user,
    is_active: user.is_active === 'Active' ? true : false,
    type: typeObject[user.type],
  };
};

const Edit = ({ user, token, closeModal, reload }) => {
  const [editedUser, setEditedUser] = useState(transformData(user));
  const [originalUser] = useState(transformData(user));
  const [isEditing, setisEditing] = useState(false);

  const ref = useRef();

  const editUser = async () => {
    setisEditing(true);
    ref.current.continuousStart();
    axios({
      method: 'PUT',
      url: `${BASE_URL}/access/${editedUser.id}/`,
      headers: {
        authorization: `token ${token}`,
      },
      data: editedUser,
    })
      .then(() => {
        NotificationManager.success('Permissions edited', '', 4000);
        reload();
        closeModal();
      })
      .catch((e) => customError(e))
      .finally(() => {
        setisEditing(false);
        ref.current?.complete();
      });
  };

  return (
    <div>
      <LoadingBar color="#CD801F" ref={ref} />
      <h3>Status</h3>
      <SwitchFlex>
        <span>Active</span>{' '}
        <ToggleSwitch
          checked={editedUser.is_active}
          setChecked={(e) => {
            setEditedUser((prev) => {
              return {
                ...prev,
                is_active: !prev.is_active,
              };
            });
          }}
        />
      </SwitchFlex>
      <br />
      <h2 style={{ marginBottom: '50px' }}>Permissions</h2>
      <h3>Products</h3>
      <PermissionGroup>
        <span>No access</span>
        <ToggleSwitch
          checked={editedUser.check_product === 'no access'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_product: 'no access',
              };
            })
          }
        />
      </PermissionGroup>
      <PermissionGroup>
        <span>View only</span>
        <ToggleSwitch
          checked={editedUser.check_product === 'view'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_product: 'view',
              };
            })
          }
        />
      </PermissionGroup>
      <PermissionGroup>
        <span>Full access</span>
        <ToggleSwitch
          checked={editedUser.check_product === 'full'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_product: 'full',
              };
            })
          }
        />
      </PermissionGroup>

      <h3>Inventory</h3>
      <PermissionGroup>
        <span>No access</span>
        <ToggleSwitch
          checked={editedUser.check_vendor === 'no access'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_vendor: 'no access',
              };
            })
          }
        />
      </PermissionGroup>
      <PermissionGroup>
        <span>View only</span>
        <ToggleSwitch
          checked={editedUser.check_vendor === 'view'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_vendor: 'view',
              };
            })
          }
        />
      </PermissionGroup>
      <PermissionGroup>
        <span>Full access</span>
        <ToggleSwitch
          checked={editedUser.check_vendor === 'full'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_vendor: 'full',
              };
            })
          }
        />
      </PermissionGroup>

      <h3>Sales</h3>
      <PermissionGroup>
        <span>No access</span>
        <ToggleSwitch
          checked={editedUser.check_sales === 'no access'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_sales: 'no access',
              };
            })
          }
        />
      </PermissionGroup>
      <PermissionGroup>
        <span>View only</span>
        <ToggleSwitch
          checked={editedUser.check_sales === 'view'}
          setChecked={(e) =>
            setEditedUser((prev) => {
              return {
                ...prev,
                check_sales: 'view',
              };
            })
          }
        />
      </PermissionGroup>

      <SaveButtonContainer>
        <Button
          disabled={isEditing || _.isEqual(originalUser, editedUser)}
          active
          onClick={editUser}
        >
          Save
        </Button>
      </SaveButtonContainer>
    </div>
  );
};

export default Edit;
