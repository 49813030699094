export const theme = {
  colors: {
    black: "#000e1a",
    white: "#fff",
    brandGreen: "#274E24",
    brandYellow: "#CD801F",
    brandOffGreen: "rgba(39,78,36, 0.8)",
    brandLemon: "rgba(80, 207, 70, 0.63)",
    brandDelete: "#c70000",
  },
  breakpoints: {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
};

export const devices = {
  xs: `(min-width: ${theme.breakpoints.xs})`,
  sm: `(min-width: ${theme.breakpoints.sm})`,
  md: `(min-width: ${theme.breakpoints.md})`,
  lg: `(min-width: ${theme.breakpoints.lg})`,
  xl: `(min-width: ${theme.breakpoints.xl})`,
  lsm: `(max-width: ${theme.breakpoints.sm})`,
  lmd: `(max-width: ${theme.breakpoints.md})`,
  llg: `(max-width: ${theme.breakpoints.lg})`,
};
