import React, { useContext } from 'react';

// External Libraries
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

// styled components
import {
  DashboardContainer,
  DashboardMain,
  DashboardMainWrapper,
} from './StyledDashboard';

// components
import DesktopSidebar from '@components/Sidebar';
import MobileNavbar from '@components/Navbar';

// dashboard pages
import Home from './Home';
import Inventory from './Inventory';
import Settings from './Settings';
import Info from './Info';
import Vendors from './Vendor';
import Sales from './Sales';
import Report from './Report';

// context
import { AppContext } from 'context/store';

//UTILS
import LowInventory from './LowInventory';
import InventoryOptions from './Options';
import Terminal from './Sales/Terminal';
import Activities from './Activities';
import ActivityOrder from 'pages/Dashboard/ActivityOrder';

const Dashboard = ({ match: { url } }) => {
  const {
    store: { user },
  } = useContext(AppContext);

  if (
    !(user.user.user_type === 'admin' || user.user.user_type === 'internal')
  ) {
    return <Redirect to="/available-products" />;
  }
  return (
    <DashboardContainer>
      <DesktopSidebar />
      <MobileNavbar />
      <DashboardMain>
        <DashboardMainWrapper>
          <Switch>
            <Route exact path={`${url}/inventory`} component={Inventory} />
            <Route exact path={`${url}/report`} component={Report} />
            <Route exact path={`${url}/activities`} component={Activities} />
            <Route
              exact
              path={`${url}/activities/order`}
              component={ActivityOrder}
            />
            <Route
              exact
              path={`${url}/low-inventory-items`}
              component={LowInventory}
            />
            <Route path={`${url}/settings/:settingType`} component={Settings} />
            <Route exact path={`${url}/info`} component={Info} />
            <Route
              exact
              path={`${url}/vendors-and-contacts`}
              component={Vendors}
            />
            <Route path={`${url}/options`} component={InventoryOptions} />
            <Route path={`${url}/sales`} component={Sales} />
            <Route path={`${url}/terminal`} component={Terminal} />
            <Route
              exact
              path={`${url}/`}
              render={(props) => {
                return user.user.user_type === 'admin' ? (
                  <Home />
                ) : (
                  <Redirect to="/dashboard/Inventory" />
                );
              }}
            />
          </Switch>
        </DashboardMainWrapper>
      </DashboardMain>
    </DashboardContainer>
  );
};

export default withRouter(Dashboard);
