import axios from 'axios';
import Paginate from 'components/CustomPagination';
import FormInput from 'components/FormInput';
import CustomModal from 'components/Modal';
import CustomNotiflix from 'components/Notiflix';
import Table from 'components/Table';
import { AppContext } from 'context/store';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { customError } from 'shared/helpers';
import { Button, RightFooter, TableContainer } from 'shared/styles';
import { BASE_URL } from 'utils/api/constants';
import { getAllCategories } from '../Inventory/helpers';
import { OptionsContainer } from './styledInventoryOptions';

const data = {
  colors: ['#274E24', 'black', 'black', 'black', 'black', 'black'],
  head: ['Category Name', 'Edit'],
  variables: ['name', 'edit'],
  body: null,
};

const Categories = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [clickedItem, setClickedItem] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [scrollTableTop, setScrollTableTop] = useState(false);

  const {
    store: { token, categories },
    actions: { setCategories },
  } = useContext(AppContext);

  const getCategories = useCallback(getAllCategories, []);

  const fetchcategories = () => {
    getCategories(token).then((res) => setCategories(res));
  };

  const openItem = (item) => {
    setClickedItem(item);
    setIsOpen(true);
  };

  const editItem = () => {
    setLoading(true);
    axios({
      url: `${BASE_URL}/categories/${clickedItem.id}/`,
      method: 'PUT',
      headers: {
        authorization: `token ${token}`,
      },
      data: {
        name: clickedItem.name,
      },
    })
      .then((res) =>
        NotificationManager.success('Category successfully updated', '', 4000)
      )
      .catch((e) => customError(e))
      .finally(() => {
        setLoading(false);
        setIsOpen(false);
        fetchcategories();
      });
  };

  const startDelete = () => {
    setLoading(true);
    axios({
      url: `${BASE_URL}/categories/${clickedItem.id}/`,
      method: 'DELETE',
      headers: {
        authorization: `token ${token}`,
      },
    })
      .then(() =>
        NotificationManager.success('Category successfully deleted', '', 4000)
      )
      .catch((e) => customError(e))
      .finally(() => {
        setLoading(false);
        setIsOpen(false);
        fetchcategories();
      });
  };

  const deleteItem = () => {
    CustomNotiflix.Confirm.Show(
      'Delete Item',
      'Do you want to completely delete this lot',
      'Yes',
      'No',
      () => {
        startDelete();
      },
      () => {}
    );
  };

  const handleNameChange = (newName) => {
    setClickedItem((currentValue) => ({ ...currentValue, name: newName }));
  };

  async function handlePageClick({ selected }) {
    setLoading(true);
    try {
      const response = await axios(
        `${BASE_URL}/categories/?page=${selected + 1}`,
        {
          method: 'get',
          headers: {
            authorization: `token ${token}`,
          },
        }
      );
      setTableData((tableData) => ({
        ...tableData,
        body: response.data.results,
      }));
      setLoading(false);
      setScrollTableTop(true);
      setScrollTableTop(false);
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    setTableData((previous) => ({ ...previous, body: categories }));
  }, [categories]);

  useEffect(() => {
    getCategories(token, true).then((response) => {
      setPageCount(Math.ceil(response.count / 15));
    });
  }, [token, getCategories, categories]);

  return (
    <OptionsContainer>
      <TableContainer>
        <Table
          data={tableData}
          onClickTableItem={openItem}
          scrollTop={scrollTableTop}
        />
      </TableContainer>
      <Paginate onPageChange={handlePageClick} pageCount={pageCount} />
      <CustomModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <h2>Edit</h2>
        <FormInput
          inputValue={clickedItem.name}
          setInputValue={handleNameChange}
          label="Name"
        />
        <RightFooter>
          <Button onClick={editItem}>Save</Button>
          <Button isDelete={true} onClick={deleteItem}>
            Delete
          </Button>
        </RightFooter>
      </CustomModal>
      <CustomNotiflix shouldLoad={loading} />
    </OptionsContainer>
  );
};

export default Categories;
