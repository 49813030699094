import React, { useState } from 'react';
import Notiflix from 'notiflix';
import { NotificationManager } from 'react-notifications';

import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton,
  // MasterpassButton,
  // ApplePayButton,
  // GooglePayButton,
} from 'react-square-payment-form';
import { PaymentSummaryItem, VStack } from './StyledPayment';

import 'react-square-payment-form/lib/default.css';
import { SalesPageContainer } from 'shared/styles';

import {
  PRODUCTION_ACCESS_TOKEN,
  PRODUCTION_APPLICATION_ID,
  PRODUCTION_LOCATION_ID,
} from 'utils/api/constants';

import { SalesPaymentContainer } from './StyledPayment';

Notiflix.Loading.Init({
  zindex: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
});

const Payment = () => {
  const [errorMsgs, setErrorMsgs] = useState([]);
  const [loadingForm, setLoadingForm] = useState(true);

  if (loadingForm) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  function createPaymentRequest() {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: 'USD',
      countryCode: 'US',
      total: {
        label: 'MERCHANT NAME',
        amount: '1',
        pending: false,
      },
      lineItems: [
        {
          label: 'Subtotal',
          amount: '1',
          pending: false,
        },
      ],
    };
  }

  const cardNonceResponseReceived = (
    errors,
    nonce,
    cardData,
    buyerVerificationToken
  ) => {
    if (errors) {
      setErrorMsgs(errors.map((error) => error.message));
      return;
    }

    setErrorMsgs([]);

    NotificationManager.success(
      'nonce created: ' +
        nonce +
        ', buyerVerificationToken: ' +
        buyerVerificationToken,
      ''
    );
  };

  function createVerificationDetails() {
    return {
      amount: '100.00',
      currencyCode: 'USD',
      intent: 'CHARGE',
      billingContact: {
        familyName: 'Smith',
        givenName: 'John',
        email: 'jsmith@example.com',
        country: 'GB',
        city: 'London',
        addressLines: ["1235 Emperor's Gate"],
        postalCode: 'SW7 4JA',
        phone: '020 7946 0532',
      },
    };
  }

  // const loadingView = <div className="sq-wallet-loading"></div>;
  // const unavailableView = (type) => (
  //   <div className="sq-wallet-unavailable">{type} Unavailable</div>
  // );

  return (
    <SalesPageContainer>
      <SalesPaymentContainer>
        <SquarePaymentForm
          applicationId={PRODUCTION_APPLICATION_ID}
          locationId={PRODUCTION_LOCATION_ID}
          cardNonceResponseReceived={cardNonceResponseReceived}
          createVerificationDetails={createVerificationDetails}
          createPaymentRequest={createPaymentRequest}
          accessToken={PRODUCTION_ACCESS_TOKEN}
          paymentFormLoaded={() => setLoadingForm(false)}
        >
          <h2>Payment Method</h2>
          {/* <ApplePayButton
            loadingView={loadingView}
            unavailableView={unavailableView("Apple Pay")}
          />
          <MasterpassButton
            loadingView={loadingView}
            unavailableView={unavailableView("Masterpass")}
          />
          <GooglePayButton
            loadingView={loadingView}
            unavailableView={unavailableView("Google Play")}
          /> */}

          <fieldset className="sq-fieldset">
            <CreditCardNumberInput />
            <div className="sq-form-third">
              <CreditCardExpirationDateInput />
            </div>

            <div className="sq-form-third">
              <CreditCardPostalCodeInput />
            </div>

            <div className="sq-form-third">
              <CreditCardCVVInput />
            </div>
          </fieldset>
          <h3>Order Summary</h3>
          <PaymentSummaryItem>
            <span>Balance Amount :</span>
            <b>$200</b>
          </PaymentSummaryItem>
          <PaymentSummaryItem>
            <span>VAT (22%) :</span>
            <b>$2.3</b>
          </PaymentSummaryItem>
          <hr />
          <PaymentSummaryItem>
            <VStack>
              <span>
                <b>Total </b>:
              </span>
              <span>incl. (VAT)</span>
            </VStack>
            <b>$202.3</b>
          </PaymentSummaryItem>
          <CreditCardSubmitButton>Pay Now</CreditCardSubmitButton>
        </SquarePaymentForm>
        <div className="sq-error-message">
          {errorMsgs.map((errorMessage) => (
            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
          ))}
        </div>
      </SalesPaymentContainer>
    </SalesPageContainer>
  );
};

export default Payment;
