// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from 'theme';

export const DoubleItems = styled.div`
  display: flex;
  width: 100%;

  & > * {
    flex-basis: 50%;
    height: 55px !important;
    margin-bottom: 20px;
  }
  & > :first-child {
    margin-right: 10px;
  }

  @media ${devices.md} {
    & > :first-child {
      margin-right: 20px;
    }
  }
`;

export const RightFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`;

export const LeftFooter = styled.div`
  display: flex;
  margin-top: 22px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  outline: none;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

  border: solid 1px
    ${(props) =>
      props.isDelete
        ? props.theme.colors.brandDelete
        : props.theme.colors.brandGreen};
  border-radius: 6px;
  color: ${(props) =>
    props.isDelete
      ? props.theme.colors.brandDelete
      : props.theme.colors.brandGreen};
  background-color: white;
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
  background-color: ${props.theme.colors.brandGreen};
  color: white;
  `}

  &:not(:first-of-type) {
    margin-left: 25px;
  }
  outline: 0;

  &:hover {
    background: ${(props) =>
      props.isDelete
        ? props.theme.colors.brandDelete
        : props.theme.colors.brandGreen};
    color: white;
  }
`;

export const PaginateContainer = styled.div`
  margin-top: 15px;

  .pagination {
    display: flex;
    list-style-type: none;
    width: fit-content;
    padding: 10px;
    margin-left: auto;

    a {
      outline: 0;
      border: 0;
    }
    a:focus {
      outline: 0;
      border: 0;
    }

    li:not(:first-of-type) {
      margin-left: 10px;
      cursor: pointer;
    }
    li.previous {
      cursor: pointer;
    }

    .active {
      color: ${(props) => props.theme.colors.brandGreen};
      font-weight: bold;
    }
    .disabled {
      opacity: 0.5;
      cursor: text !important;
    }
  }
`;

export const ModalHeader = styled.div`
  width: max-content;
  margin-left: auto;
  cursor: pointer;

  .iconify {
    width: 25px;
    height: 25px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 500px;
`;

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media ${devices.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const DashboardSearch = styled.div`
  max-width: 400px;
  flex-grow: 1;
  display: flex;
  margin-bottom: 30px;
  @media ${devices.md} {
    margin-bottom: 0;
  }
`;

export const DashboardSearchBox = styled.div`
  margin-right: 10px;
  flex-grow: 1;
`;
export const DashboardFilter = styled.button`
  padding: 10px;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: #f1f1f1;
  color: black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #cdcdcd;
  }
`;

export const DashboardHeaderOptions = styled.div`
  margin: 0;
  display: flex;
  button {
    display: flex;
    background-color: ${(props) => props.theme.colors.brandGreen};
    align-items: center;
    color: white;

    .iconify {
      margin-right: 10px;
    }
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 700px;

  img {
    max-width: 95%;
    max-height: 300px;
    margin: 20px 0 40px;
  }
  p {
    width: 80%;
    line-height: 1.5;
  }
  h2 {
    margin: 10px 0 40px;
  }
  button {
    background-color: ${(props) => props.theme.colors.brandGreen};
    color: white;
    padding: 10px 15px;
    width: 150px;
  }
`;

export const SalesPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NormalButton = styled.button`
  padding: 10px 15px;
  background-color: ${(props) => props.theme.colors.brandGreen};
  color: white;
  font-size: 1em;
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? 'text' : 'pointer')};
  outline: 0;
  border: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.brandGreen};
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.brandGreen};
  }
`;

export const FullButton = styled(NormalButton)`
  width: 100%;
`;

export const ErrorContainer = styled.em`
  color: red;
  font-size: smaller;
  position: absolute;
  bottom: -15px;
  left: 0;
`;

export const FormErrorMessage = styled.div`
  color: red;
  font-size: 1em;
  margin-bottom: 30px;
  font-style: oblique;
  border: solid red 1px;
  background-color: rgba(249, 219, 219, 255);
  padding: 15px;
  border-radius: 3px;
  width: 100%;
  font-size: smaller;
`;
