import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from 'theme';

export const ActivityContainer = styled.div``;

export const ActivitiesMain = styled.div`
  /* border: solid red 1px; */
`;

export const ActivitiesContent = styled.div`
  max-height: calc(100vh - 246px);
  overflow-y: auto;
  padding: 10px;
  /* border: solid blue 1px; */
`;

export const ActivitiesTitleHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  position: relative;
  top: -50px;
  width: max-content;
  margin: 0 auto;

  img {
    width: 35px;
    cursor: pointer;
  }

  span {
    margin-bottom: 30px;
  }

  @media ${devices.lg} {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    top: 0;
    justify-content: space-between;

    span {
      margin-bottom: 70px;
    }
  }
`;

export const BackLink = styled(Link)`
  color: ${(props) => props.theme.colors.brandGreen};
`;
