import React, { useState, useRef } from 'react';

// Styled Components
import { StyledFormGroup, StyledInput, StyledLabel } from './StyledInput';

import { ErrorContainer } from 'shared/styles';

const FormikInput = ({
  label,
  type,
  inputValue,
  setInputValue,
  required,
  name,
  errorMessage,
  ...rest
}) => {
  const newRest = { ...rest };
  delete newRest.onBlur;

  // Input state
  const [isInputActive, setIsInputActive] = useState(false);

  // deactivate Input
  const deactivateInput = (e) => {
    setIsInputActive(false);
  };

  // activate Input
  const activateInput = () => {
    setIsInputActive(true);
  };

  // change Input value
  const changeInput = (e) => {
    setInputValue(e.target.value);
  };

  // input Ref
  const inputRef = useRef(null);

  return (
    <StyledFormGroup>
      <StyledLabel
        required={required ? true : undefined}
        shouldMove={
          isInputActive || inputValue || rest.value ? true : undefined
        }
        onClick={() => {
          setIsInputActive(true);
          inputRef.current.focus();
        }}
      >
        {label}
      </StyledLabel>

      <StyledInput
        onFocus={activateInput}
        onBlur={(e) => {
          deactivateInput();
          rest.onBlur(e);
        }}
        value={inputValue}
        onChange={changeInput}
        type={type}
        ref={inputRef}
        required={required}
        name={name}
        {...newRest}
      />

      {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
    </StyledFormGroup>
  );
};

export default FormikInput;
