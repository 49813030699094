import styled from "styled-components";

// Theming
import { devices } from "../../../theme";

export const InfoContainer = styled.div`
  & > h1 {
    width: max-content;
    font-size: 1.5em;
    margin: 0 auto;
    position: relative;
    top: -50px;
  }

  @media ${devices.lg} {
    & > h1 {
      top: 0;
      margin: 0;
      margin-right: auto;
      margin-bottom: 50px;
    }
  }
`;

export const InfoItem = styled.div`
  margin: 30px auto;
  width: 100%;

  @media ${devices.md} {
    width: 85%;
  }
  @media ${devices.lg} {
    width: 80%;
  }
`;
