const { NotificationManager } = require('react-notifications');

export function customError(e, message) {
  let errorBody = null;
  let errorHead = null;
  if (e.response && e.response.data) {
    const errorObject = e.response.data;
    const errorKey = Object.keys(errorObject);
    errorBody = `${errorObject[errorKey[0]]}`;
    errorHead = `${errorKey[0]}`;
    errorHead = errorHead.split('_').join(' ');
  }
  NotificationManager.error(
    errorBody || message || 'An error occured',
    errorHead || '',
    6000
  );
}

export function success(text) {
  NotificationManager.success(text, 'Success', 4000);
}

export function customErrorMessage(e) {
  let errorBody = null;
  let errorHead = null;
  if (e.response && e.response.data) {
    const errorObject = e.response.data;
    const errorKey = Object.keys(errorObject);
    errorBody = `${errorObject[errorKey[0]]}`;
    errorHead = `${errorKey[0]}`;
    errorHead = errorHead.split('_').join(' ');
  }

  return { errorHead, errorBody };
}
