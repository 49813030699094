import React, { useContext } from 'react';
import Notiflix from 'notiflix';

// Notification styles
import 'react-notifications/lib/notifications.css';

// External Libraries
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

// Pages
import Register from '@pages/Register';
import Login from '@pages/Login';
import Dashboard from '@pages/Dashboard';
import ForgotPassword from 'pages/ForgotPassword';

// Context
import { AppContext } from 'context/store';
import Activate from 'pages/Activate';
import RegisterVendor from 'pages/RegisterVendor';
import External from 'pages/External';
// import firebase from 'firebase/app';

// const firebaseConfig = {
//   apiKey: 'AIzaSyACYSXCAOANYqMxqt6hDFhpHgY9aqJ3qyg',
//   authDomain: 'nursery-20c44.firebaseapp.com',
//   projectId: 'nursery-20c44',
//   storageBucket: 'nursery-20c44.appspot.com',
//   messagingSenderId: '725354286463',
//   appId: '1:725354286463:web:5b67954cb952d39c2a9e4a',
//   measurementId: 'G-23MJBZZWHR',
// };
// firebase.initializeApp(firebaseConfig);

Notiflix.Confirm.Init({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Confirm.Merge({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Loading.Init({
  zindex: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
});

function App() {
  const {
    store: { token },
  } = useContext(AppContext);

  return (
    <div className="App">
      <NotificationContainer />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/activate" component={Activate} />
        <Route
          exact
          path="/signup"
          render={(props) => <Register {...props} userType="internal" />}
        />
        <Route
          exact
          path="/register-admin"
          render={(props) => <Register {...props} userType="admin" />}
        />
        <Route exact path="/register" component={RegisterVendor} />
        <Route path="/dashboard">
          {token ? <Dashboard /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/reset-password" component={ForgotPassword} />
        <Route exact path="/available-products" component={External} />
        <Route exact path="/" component={Login} />
      </Switch>
    </div>
  );
}

export default App;
