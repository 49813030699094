import styled from "styled-components";

export const BarChartContainer = styled.div`
  width: 100%;
  height: 250px;
  text-align: center;

  svg {
    pointer-events: none !important;
  }

  & > span {
    text-transform: uppercase;
    font-size: 0.8em;
  }
`;
