import React, { useContext } from 'react';

// External Libraries
import { withRouter } from 'react-router-dom';

// Styled components
import {
  SidebarContainer,
  SidebarItem,
  SidebarBrand,
  SidebarMain,
  SidebarFooter,
} from './styledSidebar';

// assets
import Logo from '@images/logo.svg';
import { AppContext } from 'context/store';

const Sidebar = ({ match, history }) => {
  const {
    store: { sidebar, user },
    actions: { logout },
  } = useContext(AppContext);

  let data = {
    brand: {
      title: 'A & N Nursery',
      icon: Logo,
      alt: 'Logo',
    },
    main: [
      {
        title: 'Inventory',
        icon: 'bx:bxs-grid',
        url: '/inventory',
        alt: 'Inventory',
      },
      {
        title: 'Sales',
        icon: 'mdi:finance',
        url: '/sales',
        alt: 'Sales',
      },
      {
        title: 'Vendors',
        icon: 'heroicons-outline:users',
        url: '/vendors-and-contacts',
        alt: 'Vendors',
      },
      {
        title: 'Activity',
        icon: 'fluent:shifts-activity-20-filled',
        url: '/activities',
        alt: 'Activity',
      },
    ],

    footer: [
      {
        title: 'Settings',
        icon: 'clarity:settings-line',
        url: '/settings/profile',
        alt: 'Settings',
      },
      {
        title: 'logout',
        icon: 'ri:logout-circle-line',
        url: '/login',
        alt: 'Logout',
      },
    ],
  };

  if (user.user.user_type === 'admin') {
    data.main.unshift({
      title: 'Home',
      icon: 'mdi-light:view-dashboard',
      url: '',
      alt: 'Home',
    });
  }

  return (
    <SidebarContainer>
      <SidebarBrand>
        <img alt={data.brand.alt} src={data.brand.icon} />
        {/* <span>{data.brand.title}</span> */}
      </SidebarBrand>
      <SidebarMain>
        {data.main.map((item, index) => (
          <SidebarItem
            key={index}
            to={`${match.url + item.url}`}
            activetab={sidebar === item.alt ? 1 : undefined}
          >
            <i>
              <span
                className="iconify"
                data-icon={`${item.icon}`}
                data-inline="false"
              ></span>
            </i>
            <span className="tooltiptext">{item.title}</span>
          </SidebarItem>
        ))}
      </SidebarMain>
      <SidebarFooter>
        {data.footer.map((item, index) => (
          <SidebarItem
            key={index}
            to={`${item.title === 'logout' ? '/login' : match.url + item.url}`}
            activetab={sidebar === item.alt ? 1 : undefined}
          >
            <i
              onClick={(e) => {
                if (item.title === 'logout') logout();
              }}
            >
              <span
                className="iconify"
                data-icon={`${item.icon}`}
                data-inline="false"
              ></span>
            </i>
            <span className="tooltiptext">{item.title}</span>
          </SidebarItem>
        ))}
      </SidebarFooter>
    </SidebarContainer>
  );
};

export default withRouter(Sidebar);
