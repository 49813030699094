import styled from "styled-components";

// Theming
import { devices } from "../../theme";

export const DashboardContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${devices.lg} {
    background-color: ${(props) => props.theme.colors.brandGreen};
    padding: 10px;
    padding-left: 0;
  }
`;

export const DashboardMain = styled.div`
  width: 100%;
  background-color: white;
  margin-top: 78px;
  padding: 20px;
  ${"" /* min-height: 100vh; */}

  @media ${devices.llg} {
    padding-top: 0;
  }
  @media ${devices.lg} {
    width: calc(100% - 100px);
    border-radius: 20px;
    margin-top: 0;
  }
`;

export const DashboardMainWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  height: 100%;
  ${"" /* border: ssolid red 1px; */}
`;
