import styled from "styled-components";

export const SalesPaymentContainer = styled.div`
  width: 100%;
  max-width: 450px;

  b {
    color: black;
  }

  & > * {
    width: 100%;
    max-width: 100%;

    & > * {
      margin-bottom: 20px;
    }
  }

  button {
    background-color: ${(props) => props.theme.colors.brandOffGreen};
    outline: 0;
  }

  button:hover {
    background-color: ${(props) => props.theme.colors.brandGreen};
  }

  .sq-input--focus {
    border-color: ${(props) => props.theme.colors.brandOffGreen} !important;
    outline-color: ${(props) => props.theme.colors.brandOffGreen} !important;
  }
`;

export const PaymentSummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
`;

export const PaymentSummaryTotal = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const VStack = styled.div`
  display: flex;
  flex-direction: column;

  span:first-of-type {
    margin-bottom: 10px;
  }
`;
