import React, { useContext } from 'react';

// external Libraries
import { withRouter } from 'react-router-dom';

// Styled components
import {
  StyledMobileNavContainer,
  StyledMobileNavHeader,
  StyledMobileNavContent,
  StyledMobileNavReveal,
  StyledMobileNavClose,
  StyledMobileNavlist,
  StyledMobileNavItem,
} from './StyledMobileNav';

// Assets
import Logo from '@images/logo2.svg';
import { AppContext } from 'context/store';

const MobileNav = ({ data, reveal, setReveal, match }) => {
  const {
    actions: { logout },
  } = useContext(AppContext);

  return (
    <StyledMobileNavContainer>
      <StyledMobileNavReveal reveal={reveal}>
        <StyledMobileNavHeader>
          <div>
            <img src={Logo} alt="logo" /> <span>A & N Nursery</span>
          </div>{' '}
          <i
            onClick={() => {
              setReveal(false);
            }}
          >
            <span
              className="iconify"
              data-icon="topcoat:cancel"
              data-inline="false"
            ></span>
          </i>
        </StyledMobileNavHeader>
        <StyledMobileNavContent>
          <StyledMobileNavClose></StyledMobileNavClose>
          {reveal && (
            <StyledMobileNavlist>
              {data.map((item, index) => {
                return (
                  <StyledMobileNavItem
                    key={index}
                    to={`${item.url === 'home' ? '/' : match.url + item.url}`}
                    onClick={() => {
                      if (item.title === 'Logout') logout();
                      setReveal(false);
                    }}
                  >
                    <i>
                      <span
                        className="iconify"
                        data-icon={`${item.icon}`}
                        data-inline="false"
                      ></span>
                    </i>
                    <span>{item.title}</span>
                  </StyledMobileNavItem>
                );
              })}
            </StyledMobileNavlist>
          )}
        </StyledMobileNavContent>
      </StyledMobileNavReveal>
    </StyledMobileNavContainer>
  );
};

export default withRouter(MobileNav);
