import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';

import Table from 'components/Table';

import { ReportContainer, ReportHeader, ReportData } from './StyledReport';

import BusinessData from '../shared/BusinessData';
import { ModalHeader, PaginateContainer, TableContainer } from 'shared/styles';
import ReactPaginate from 'react-paginate';
import { AppContext } from 'context/store';
import { getAllTransactions } from './helpers';
import ListDisplay from 'components/ListDisplay';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxHeight: '95%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    borderRadius: '10px',
    zIndex: '1000',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.45)',
  },
};

const tableData = {
  head: [
    'Date',
    'Amount',
    'Currency',
    'Status',
    'Card Brand',
    'Reference Id',
    'Receipt Number',
    'View',
  ],
  variables: [
    'created_at',
    'amount_money',
    'currency',
    'payment_status',
    'card_brand',
    'reference_id',
    'receipt_number',
  ],
  body: [],
};

const Report = () => {
  const [data, setData] = useState(tableData);
  const [pageCount] = useState(0);
  const [activeItem, setActiveItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setIsInventoryLoading] = useState(false);
  const [, setScrollTableTop] = useState(false);

  const createModalData = (item) => {
    const tempItem = { ...item };

    const obj = {
      date: tempItem.created_at,
      status: tempItem.payment_status,
      rows: [],
    };

    delete tempItem.created_at;
    delete tempItem.payment_status;

    for (const entry of Object.keys(tempItem)) {
      if (entry === 'View') continue;
      obj.rows.push({
        key: entry.split('_').join(' '),
        value: tempItem[entry],
      });
    }
    return obj;
  };

  const handleView = (item) => {
    setActiveItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveItem(null);
  };

  const {
    store: { token },
    actions: { setSidebar },
  } = useContext(AppContext);

  useEffect(() => {
    setSidebar(null);
  }, [setSidebar]);

  useEffect(() => {
    getAllTransactions(token, 1)
      .then((res) => setData({ ...tableData, body: res.results }))
      .catch((e) => {});
  }, [token]);

  const handlePageClick = async ({ selected }) => {
    setIsInventoryLoading(true);

    getAllTransactions(token, selected + 1)
      .then((res) => {
        setData({ ...tableData, body: res.results });
        setIsInventoryLoading(false);
        setScrollTableTop(true);
        setScrollTableTop(false);
      })
      .catch((e) => {
        setIsInventoryLoading(false);
      });
  };

  return (
    <ReportContainer>
      <ReportHeader>
        <h1>Report</h1>
      </ReportHeader>
      <ReportData>
        <BusinessData />
      </ReportData>
      <TableContainer>
        <Table
          data={data}
          onClickTableItem={handleView}
          emptyText="No transcation completed"
        />
      </TableContainer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <ModalHeader onClick={closeModal}>
          <i>
            <span
              className="iconify"
              data-icon="topcoat:cancel"
              data-inline="false"
            ></span>
          </i>
        </ModalHeader>
        {activeItem && <ListDisplay data={createModalData(activeItem)} />}
      </Modal>
      <PaginateContainer>
        <ReactPaginate
          previousLabel={'prev'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          disabledClassName="disabled"
        />
      </PaginateContainer>
    </ReportContainer>
  );
};

export default Report;
