import React from 'react';
import Notiflix from 'notiflix';

// setups
Notiflix.Confirm.Init({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Confirm.Merge({
  okButtonColor: '#ffffff',
  okButtonBackground: '#c70000',
});

Notiflix.Loading.Init({
  zindex: 0,
  backgroundColor: 'rgba(0,0,0,0.6)',
  messageMaxLength: 70,
});

const CustomNotiflix = ({ shouldLoad }) => {
  if (shouldLoad) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  return <div></div>;
};

CustomNotiflix.Confirm = Notiflix.Confirm;
CustomNotiflix.Loading = Notiflix.Loading;

export default CustomNotiflix;
