import styled from 'styled-components';
import { devices } from 'theme';

export const AdvancedHeader = styled.div`
  margin-top: 50px;
`;

export const Heading = styled.h2`
  width: max-content;
  margin: 20px auto;
`;

export const AdvancedContainer = styled.div`
  width: 100%;

  h2 {
    margin: 0 0 40px 0;
  }
`;
export const PermissionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  & > h2 {
    margin-bottom: 150px;
    border: solid red 1px;
  }

  @media ${devices.sm} {
    min-width: 400px;
  }
`;
export const SwitchFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 150px;
  }
  button[disabled] {
    opacity: 0.5;
    cursor: text;
  }
`;
