import styled from "styled-components";

// theming
import { devices } from "theme";

export const SettingsProfileContainer = styled.div`
  max-width: 700px;
  h1 {
    margin-top: 0;
    font-size: 1.5em;
    margin-bottom: 30px;
    ${"" /* border: solid red 1px; */}
    position: relative;
    top: -4px;
  }
`;

export const SettingsProfileInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  row-gap: 20px;
  &:first-of-type {
    margin-bottom: 40px;
  }

  @media ${devices.md} {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    column-gap: 10%;
    row-gap: 30px;
  }
`;
