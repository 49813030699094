import styled from 'styled-components';
import { devices } from 'theme';

export const BusinessSales = styled.div`
  text-align: center;
  & > div {
    margin-bottom: 50px;
  }
  @media ${devices.sm} {
    & > div {
      margin-bottom: 0px;
    }
    display: grid;
    grid-template-columns: repeat(2, 45%);
    column-gap: 10%;
    row-gap: 50px;
  }
  @media ${devices.md} {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    column-gap: 5%;
    row-gap: 50px;
  }
`;

export const BusinessNetSales = styled.div`
  background: linear-gradient(287.36deg, #9400d3 42.18%, #cd801f 102.4%);
  box-shadow: 0px 29.8163px 59.6326px rgba(0, 0, 0, 0.15);
  border-radius: 14.9082px;
  text-align: center;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    margin: 0;
    font-size: 1.5em;
  }
`;

export const BusinessGrossSales = styled.div`
  background-image: url(${(props) => props.bgImage}),
    linear-gradient(287.36deg, #3245f4 42.18%, #2735ba 102.4%);
  box-shadow: 0px 29.8163px 59.6326px rgba(0, 0, 0, 0.15);
  border-radius: 14.9082px;
  background-repeat: no-repeat;
  background-size: 100% 95%, cover;
  background-position: 0% 490%, center;
  text-align: center;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    margin: 0;
    font-size: 1.5em;
  }
`;

export const BusinessProductSales = styled.div`
  background: url(${(props) => props.bgImage});
  background-color: ${(props) => `rgba(${props.bgColor}, 1)`};
  background-position: calc(100% - 20px) center;
  background-size: 60px;
  background-repeat: no-repeat;
  padding-left: 15px;
  border-radius: 14.9082px;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 37.4978px 74.9956px rgba(0, 0, 0, 0.15);
  p {
    margin: 0;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.5em;
  }
`;
