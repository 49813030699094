import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from 'theme';

export const SalesHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 1.1em;
  position: relative;
  top: -50px;
  width: max-content;
  margin: 0 auto;

  .iconify {
    width: 30px;
    height: auto;
    cursor: pointer;
    color: ${(props) => props.theme.colors.brandGreen};
  }

  @media ${devices.lg} {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    top: 0;
    justify-content: space-between;
  }
`;

export const SalesConnectTerminal = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  cursor: pointer;
  margin-top: 50px;
  color: ${(props) => props.theme.colors.brandGreen};

  @media ${devices.lg} {
    margin-top: 0;
  }
`;

export const SalesTerminalLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const SalesHeader = styled.span`
  width: max-content;
  font-size: 1.5em;

  @media ${devices.lg} {
    top: 0;
    margin: 0;
    margin-right: auto;
    margin-bottom: 50px;
  }
`;

export const SalesContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);
`;
