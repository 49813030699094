import React, { useEffect, useRef } from 'react';

import { TableContainer, TableTd, StyledEdit } from './StyledExternalTable';

const ExternalTable = ({
  data,
  onClickTableItem,
  deleteExternal,
  scrollTop,
  emptyText,
  hasQuantity,
  addFunc,
  removeFunc,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (scrollTop) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [scrollTop]);

  return (
    <>
      <TableContainer colors={data.colors}>
        <thead>
          <tr>
            {data.head.map((item, index) => (
              <th key={index}> {item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!data.body ? (
            <tr>
              <td>
                <em>loading...</em>
              </td>
            </tr>
          ) : (
            <>
              {data.body.length === 0 ? (
                <tr>
                  <td>
                    <em>{emptyText || `No item available`}</em>
                  </td>
                </tr>
              ) : (
                <>
                  {data.body &&
                    data.body.map((entry, index) => {
                      return (
                        <tr key={index}>
                          {data.head.map((item, indexInner) => {
                            let val = entry[data.variables[indexInner]];

                            return indexInner === 0 ? (
                              <TableTd isName key={indexInner}>
                                {
                                  <img
                                    src={
                                      'https://res.cloudinary.com/iyanuashiri/' +
                                      val
                                    }
                                    alt=""
                                    width="60px"
                                    height="60px"
                                  />
                                }
                              </TableTd>
                            ) : (
                              <TableTd key={indexInner}>
                                {indexInner === data.head.length - 1 ? (
                                  <StyledEdit>
                                    <i
                                      onClick={() => {
                                        onClickTableItem(entry);
                                      }}
                                    >
                                      <span
                                        className="iconify edit"
                                        data-icon="bytesize:edit"
                                        data-inline="false"
                                      ></span>{' '}
                                    </i>
                                  </StyledEdit>
                                ) : (
                                  <>
                                    {indexInner === data.head.indexOf('Price')
                                      ? '$' + val
                                      : val}
                                  </>
                                )}
                              </TableTd>
                            );
                          })}
                        </tr>
                      );
                    })}
                </>
              )}
            </>
          )}
        </tbody>
      </TableContainer>
    </>
  );
};

export default ExternalTable;
