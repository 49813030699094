import React, {
  useEffect,
  useReducer,
  createContext,
  useCallback,
  useMemo,
} from 'react';

export const SET_USER = 'SET_USER';
export const SET_DEVICE_CODE = 'SET_DEVICE_CODE';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS';
export const SET_LOTS = 'SET_LOTS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_TAXES = 'SET_TAXES';
export const SET_DISCOUNTS = 'SET_DISCOUNTS';
export const SET_TOTAL_CATEGORIES = 'SET_TOTAL_CATEGORIES';
export const SET_TOTAL_CLASSIFICATIONS = 'SET_TOTAL_CLASSIFICATIONS';
export const SET_TOTAL_LOTS = 'SET_TOTAL_LOTS';
export const SET_TOTAL_LOCATIONS = 'SET_TOTAL_LOCATIONS';
export const SET_TOTAL_TAXES = 'SET_TOTAL_TAXES';
export const SET_TOTAL_DISCOUNTS = 'SET_TOTAL_DISCOUNTS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const LOGOUT = 'LOGOUT';
export const APP_KEY = 'NNSTATE_PRODUCTION_V1';

const addToCart = (oldCart, newItem) => {
  let newCart = [...oldCart];
  if (newCart.find((item) => item.barcode_digit === newItem.barcode_digit)) {
    newCart = newCart.map((item) => {
      if (item.barcode_digit === newItem.barcode_digit) {
        if (item.cart_qty === item.quantity) {
          return {
            ...item,
            cart_qty: item.cart_qty,
          };
        }

        return {
          ...item,
          cart_qty: item.cart_qty + 1,
        };
      } else {
        return item;
      }
    });
  } else {
    newCart.push({
      ...newItem,
      cart_qty: 1,
    });
  }

  return newCart;
};

const removeOneFromCart = (oldCart, itemToRemove, deleteItemFunc) => {
  const newCart = [...oldCart];
  const item = newCart.find(
    (item) => item.barcode_digit === itemToRemove.barcode_digit
  );

  if (item.cart_qty === 1) {
    return newCart;
  }
  return newCart.map((item) => {
    if (item.barcode_digit === itemToRemove.barcode_digit) {
      return {
        ...item,
        cart_qty: item.cart_qty - 1,
      };
    } else {
      return item;
    }
  });
};

const deleteFromCart = (oldCart, item) => {
  return oldCart.filter(
    (eachItem) => eachItem.barcode_digit !== item.barcode_digit
  );
};

const defaultInitialState = {
  user: null,
  token: null,
  sidebar: 1,
  categories: [],
  locations: [],
  lots: [],
  taxes: [],
  discounts: [],
  classifications: [],
  totalCategories: [],
  totalLocations: [],
  totalLots: [],
  totalTaxes: [],
  totalDiscounts: [],
  totalClassifications: [],
  dashboard: {},
  cart: [],
  device_code: '',
};

const initialState =
  JSON.parse(window.localStorage.getItem(APP_KEY)) || defaultInitialState;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case SET_CLASSIFICATIONS:
      return {
        ...state,
        classifications: action.payload,
      };
    case SET_LOTS:
      return {
        ...state,
        lots: action.payload,
      };
    case SET_TAXES:
      return {
        ...state,
        taxes: action.payload,
      };
    case SET_DISCOUNTS:
      return {
        ...state,
        discounts: action.payload,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case SET_TOTAL_CATEGORIES:
      return {
        ...state,
        totalCategories: action.payload,
      };

    case SET_TOTAL_CLASSIFICATIONS:
      return {
        ...state,
        totalClassifications: action.payload,
      };
    case SET_TOTAL_LOTS:
      return {
        ...state,
        totalLots: action.payload,
      };
    case SET_TOTAL_TAXES:
      return {
        ...state,
        totalTaxes: action.payload,
      };
    case SET_TOTAL_DISCOUNTS:
      return {
        ...state,
        totalDiscounts: action.payload,
      };
    case SET_TOTAL_LOCATIONS:
      return {
        ...state,
        totalLocations: action.payload,
      };
    case SET_DEVICE_CODE:
      return {
        ...state,
        device_code: action.payload,
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload,
      };
    case ADD_TO_CART:
      return {
        ...state,
        cart: addToCart(state.cart, action.payload),
      };
    case DELETE_FROM_CART:
      return {
        ...state,
        cart: deleteFromCart(state.cart, action.payload),
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: removeOneFromCart(state.cart, action.payload),
      };

    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };

    case LOGOUT:
      return defaultInitialState;
    default:
      return state;
  }
};

export const AppContext = createContext({
  ...initialState,
  actions: {},
});

export const AppProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const addItemToCart = useCallback((item) => {
    dispatch({
      type: ADD_TO_CART,
      payload: item,
    });
  }, []);

  const removeOneFromItem = useCallback((item) => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: item,
    });
  }, []);

  const clearCart = useCallback(() => {
    dispatch({
      type: CLEAR_CART,
    });
  }, []);

  const deleteItemFromCart = useCallback((item) => {
    dispatch({
      type: DELETE_FROM_CART,
      payload: item,
    });
  }, []);

  const setCategories = useCallback((categories) => {
    dispatch({
      type: SET_CATEGORIES,
      payload: categories,
    });
  }, []);

  const setDashboardData = useCallback((dashboardData) => {
    dispatch({
      type: SET_DASHBOARD_DATA,
      payload: dashboardData,
    });
  }, []);

  const setClassifications = useCallback((classifications) => {
    dispatch({
      type: SET_CLASSIFICATIONS,
      payload: classifications,
    });
  }, []);

  const setLocations = useCallback((locations) => {
    dispatch({
      type: SET_LOCATIONS,
      payload: locations,
    });
  }, []);

  const setTaxes = useCallback((taxes) => {
    dispatch({
      type: SET_TAXES,
      payload: taxes,
    });
  }, []);

  const setDiscounts = useCallback((discounts) => {
    dispatch({
      type: SET_DISCOUNTS,
      payload: discounts,
    });
  }, []);

  const setLots = useCallback((lots) => {
    dispatch({
      type: SET_LOTS,
      payload: lots,
    });
  }, []);

  const setTotalCategories = useCallback((totalcategories) => {
    dispatch({
      type: SET_TOTAL_CATEGORIES,
      payload: totalcategories,
    });
  }, []);

  const setTotalLocations = useCallback((totallocations) => {
    dispatch({
      type: SET_TOTAL_LOCATIONS,
      payload: totallocations,
    });
  }, []);

  const setTotalLots = useCallback((totallots) => {
    dispatch({
      type: SET_TOTAL_LOTS,
      payload: totallots,
    });
  }, []);

  const setTotalClassifications = useCallback((totalclassifications) => {
    dispatch({
      type: SET_TOTAL_CLASSIFICATIONS,
      payload: totalclassifications,
    });
  }, []);
  const setTotalDiscounts = useCallback((totaldiscounts) => {
    dispatch({
      type: SET_TOTAL_DISCOUNTS,
      payload: totaldiscounts,
    });
  }, []);

  const setTotalTaxes = useCallback((totaltaxes) => {
    dispatch({
      type: SET_TOTAL_TAXES,
      payload: totaltaxes,
    });
  }, []);

  const setUser = useCallback((user) => {
    dispatch({
      type: SET_USER,
      payload: user,
    });
  }, []);

  const setDeviceCode = useCallback((code) => {
    dispatch({
      type: SET_DEVICE_CODE,
      payload: code,
    });
  }, []);

  const setToken = useCallback((token) => {
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  }, []);

  const setSidebar = useCallback((index) => {
    dispatch({
      type: SET_SIDEBAR,
      payload: index,
    });
  }, []);

  const logout = useCallback(() => {
    dispatch({
      type: LOGOUT,
    });
  }, []);

  const summaryDataFunc = () => {
    const { cart } = store;
    return cart.reduce(
      (acc, item) => {
        return {
          ...acc,
          totalPrice: acc.totalPrice + item.cart_qty * item.price,
          totalQuantity: acc.totalQuantity + item.cart_qty,
        };
      },
      {
        totalPrice: 0,
        totalQuantity: 0,
      }
    );
  };

  const summaryData = summaryDataFunc();

  const actions = useMemo(
    () => ({
      deleteItemFromCart,
      addItemToCart,
      removeOneFromItem,
      setUser,
      setToken,
      setSidebar,
      setCategories,
      setClassifications,
      setDashboardData,
      setLocations,
      setLots,
      setDeviceCode,
      logout,
      clearCart,
      setTaxes,
      setDiscounts,
      setTotalCategories,
      setTotalClassifications,
      setTotalDiscounts,
      setTotalLocations,
      setTotalTaxes,
      setTotalLots,
    }),
    [
      deleteItemFromCart,
      addItemToCart,
      removeOneFromItem,
      setToken,
      setUser,
      setSidebar,
      logout,
      setCategories,
      setLocations,
      setLots,
      setDeviceCode,
      setClassifications,
      setDashboardData,
      clearCart,
      setDiscounts,
      setTaxes,
      setTotalCategories,
      setTotalClassifications,
      setTotalDiscounts,
      setTotalLocations,
      setTotalTaxes,
      setTotalLots,
    ]
  );

  useEffect(() => {
    window.localStorage.setItem(APP_KEY, JSON.stringify(store));
  }, [store]);

  return (
    <AppContext.Provider
      value={{
        store,
        actions,
        summaryData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
