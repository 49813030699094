import React from 'react';
import { withRouter } from 'react-router-dom';

import Dropdown from 'components/Dropdown';

import {
  SettingsHeaderContainer,
  SettingsHeaderTitle,
  SettingsHeaderDropdown,
} from './StyledSettingsHeader';

const SettingsHeader = ({ history, active }) => {
  const changeDropdown = (item) => {
    history.push(`${item.urlSlug}`);
  };

  const data = [
    {
      name: 'Profile',
      id: 'Profile',
      urlSlug: 'profile',
    },
    {
      name: 'Access Control',
      id: 'Access-control',
      urlSlug: 'access-control',
    },
    // {
    //   name: "Advanced",
    //   id: "Advanced-settings",
    //   urlSlug: "advanced",
    // },
  ];

  return (
    <SettingsHeaderContainer>
      <SettingsHeaderTitle>Settings</SettingsHeaderTitle>
      <SettingsHeaderDropdown>
        <Dropdown data={data} func={changeDropdown} defaulty={active} />
      </SettingsHeaderDropdown>
    </SettingsHeaderContainer>
  );
};

export default withRouter(SettingsHeader);
