import styled from 'styled-components';

export const OptionsContainer = styled.div`
  margin: 30px 0px;
`;

export const InventoryOptionsHeader = styled.div`
  width: max-content;
  margin-left: auto;
  margin-bottom: 50px;
`;
export const InventoryOptionContent = styled.div``;
