import React, { useContext, useEffect, useState } from 'react';

// Styled components
import { NavbarContainer } from './styledNavbar';

// icons
import Logo from '@images/logo2.svg';
import Burger from '@images/burger.svg';

// Components
import MobileNav from 'components/MobileNav';
import { AppContext } from 'context/store';

const Navbar = () => {
  // state
  const [reveal, setReveal] = useState(false);

  // toggle state
  const handleRevealToggle = () => {
    setReveal((prev) => !prev);
  };
  const {
    store: { user },
  } = useContext(AppContext);

  // Data
  const data = [
    {
      title: 'Inventory',
      icon: 'carbon:inventory-management',
      url: '/inventory',
    },
    {
      title: 'Sales',
      icon: 'mdi:finance',
      url: '/sales',
    },
    {
      title: 'Vendors',
      icon: 'heroicons-outline:users',
      url: '/vendors-and-contacts',
    },
    {
      title: 'Activity',
      icon: 'fluent:shifts-activity-20-filled',
      url: '/activities',
      alt: 'Home',
    },
    // {
    //   title: "Info.",
    //   icon: "eva:info-outline",
    //   url: "/info",
    // },
    {
      title: 'Settings',
      icon: 'clarity:settings-line',
      url: '/settings/profile',
    },
    {
      title: 'Logout',
      icon: 'ri:logout-circle-line',
      url: '/logout',
    },
  ];

  if (user.user.user_type === 'admin') {
    data.unshift({
      title: 'Dashboard',
      icon: 'mdi-light:view-dashboard',
      url: '',
    });
  }

  useEffect(() => {
    if (reveal) {
      document.body.classList.add('no-overflow');
    } else {
      document.body.classList.remove('no-overflow');
    }
  }, [reveal]);

  return (
    <>
      <MobileNav reveal={reveal} data={data} setReveal={setReveal} />
      <NavbarContainer>
        <img src={Logo} alt="logo" />
        <img src={Burger} alt="menu" onClick={handleRevealToggle} />
      </NavbarContainer>
    </>
  );
};

export default Navbar;
