import React, { useContext, useEffect, useRef, useState } from 'react';

// components
import Table from 'components/Table';
import { AdvancedContainer } from './StyledSettingsAdvanced';
import {
  Button,
  DashboardFilter,
  DashboardHeader,
  DashboardHeaderOptions,
  DashboardSearch,
  DashboardSearchBox,
  PaginateContainer,
  RightFooter,
  TableContainer,
} from 'shared/styles';

import SearchBox from 'components/SearchBox';
import ReactPaginate from 'react-paginate';
import CustomModal from 'components/Modal';
import Edit from './Edit';
import Invite from './Invite';
import { getInternalUsers } from './handlers';
import { AppContext } from 'context/store';
import LoadingBar from 'react-top-loading-bar';
import { FilterGroup } from 'pages/Dashboard/Inventory/StyledInventory';
import ToggleSwitch from 'components/ToggleSwitch';
import CustomNotiflix from 'components/Notiflix';

const tableData = {
  head: ['First name', 'Last name', 'Email', 'Type', 'Status', 'Edit'],
  variables: [
    'first_name',
    'last_name',
    'email',
    'user_type',
    'is_active',
    'Actions',
  ],
  body: null,
};

const typeObject = {
  internal: 'Sales Rep',
  external: 'Vendor',
  admin: 'Administrator',
};

const Advanced = () => {
  const [data, setData] = useState(tableData);
  const [isOpen, setIsOpen] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [, setIsFilter] = useState(false);
  const [userType, setUserType] = useState('');
  const [toEdit, setToEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFirstName, setIsFirstName] = useState(true);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const ref = useRef();

  const filterData = {
    type: [
      { name: 'All', value: '' },
      { name: 'Admin', value: 'admin' },
      { name: 'Internal User', value: 'internal' },
      { name: 'Vendor', value: 'external' },
    ],

    search: [
      { name: 'First Name', value: true },
      { name: 'Last Name', value: false },
    ],
  };

  const getInternal = () => {
    getInternalUsers(token).then((response) => {
      setPageCount(Math.ceil(response.count / 15));
      setData((prev) => {
        return {
          ...prev,
          body: transformData(response.results),
        };
      });
    });
  };

  const {
    store: { token },
  } = useContext(AppContext);

  function closeModal() {
    setIsOpen(false);
    setIsEdit(false);
    setIsInvite(false);
    setIsFilter(false);
  }
  const handleEdit = (entry) => {
    setIsEdit(true);
    setIsOpen(true);
    setToEdit(entry);
  };

  const handleInvite = () => {
    setIsInvite(true);
    setIsOpen(true);
  };

  const handleFilter = () => {
    setIsFilter(true);
    setIsOpen(true);
  };

  const handleReset = () => {
    setIsFirstName(true);
    setUserType('');
  };

  if (hasSearched && !searchTerm) {
    setHasSearched(false);
    getInternal();
  }

  const handleSearch = () => {
    const firstName = isFirstName ? searchTerm : '';
    const lastName = !isFirstName ? searchTerm : '';
    setShouldLoad(true);
    getInternalUsers(token, firstName, lastName, userType)
      .then((response) => {
        setPageCount(Math.ceil(response.count / 15));
        setData((prev) => {
          return {
            ...prev,
            body: transformData(response.results),
          };
        });
      })
      .finally(() => {
        setShouldLoad(false);
        closeModal();
        searchTerm && setHasSearched(true);
      });
  };

  const transformData = (data) => {
    return data.map((item, index) => {
      return {
        ...item,
        is_active: item.is_active ? 'Active' : 'Inactive',
        type: typeObject[item.type],
      };
    });
  };

  function fetchInternalUsers() {
    getInternalUsers(token).then((response) => {
      setPageCount(Math.ceil(response.count / 15));
      setData((prev) => {
        return {
          ...prev,
          body: transformData(response.results),
        };
      });
    });
  }

  useEffect(() => {
    getInternalUsers(token).then((response) => {
      setPageCount(Math.ceil(response.count / 15));
      setData((prev) => {
        return {
          ...prev,
          body: transformData(response.results),
        };
      });
    });
  }, [token]);

  return (
    <AdvancedContainer>
      <LoadingBar color="#CD801F" ref={ref} />
      <h2>Access Control</h2>
      <DashboardHeader>
        <DashboardSearch>
          <DashboardSearchBox>
            <SearchBox
              setInputValue={setSearchTerm}
              inputValue={searchTerm}
              onSubmit={handleSearch}
            />
          </DashboardSearchBox>
          <DashboardFilter onClick={handleFilter}>Filters</DashboardFilter>
        </DashboardSearch>

        <DashboardHeaderOptions>
          <Button onClick={handleInvite}>
            <span
              className="iconify"
              data-icon="bx:bx-message-square-add"
              data-inline="false"
            ></span>
            <span>Invite</span>
          </Button>
        </DashboardHeaderOptions>
      </DashboardHeader>
      <TableContainer>
        <Table data={data} onClickTableItem={handleEdit} />
      </TableContainer>
      <CustomModal isOpen={isOpen} onRequestClose={closeModal}>
        {isEdit ? (
          <Edit
            user={toEdit}
            token={token}
            closeModal={closeModal}
            reload={fetchInternalUsers}
          />
        ) : (
          <>
            {isInvite ? (
              <Invite onClick={handleInvite} close={closeModal} ref={ref} />
            ) : (
              <div>
                <FilterGroup>
                  <h2>Search by</h2>
                  {filterData.search.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <span style={{ marginRight: '15px' }}>{item.name}</span>
                        <ToggleSwitch
                          checked={isFirstName === item.value}
                          setChecked={(state) => setIsFirstName(item.value)}
                        />
                      </div>
                    );
                  })}
                </FilterGroup>

                <FilterGroup>
                  <h2>User Type</h2>
                  {filterData.type.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <span style={{ marginRight: '15px' }}>{item.name}</span>
                        <ToggleSwitch
                          checked={userType === item.value}
                          setChecked={(state) => setUserType(item.value)}
                        />
                      </div>
                    );
                  })}
                </FilterGroup>
                <RightFooter>
                  <Button isDelete onClick={handleReset}>
                    Reset
                  </Button>
                  <Button onClick={handleSearch}>Apply</Button>
                </RightFooter>
              </div>
            )}
          </>
        )}
      </CustomModal>
      <PaginateContainer>
        <ReactPaginate
          previousLabel={'prev'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={() => {}}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          disabledClassName="disabled"
        />
      </PaginateContainer>
      <CustomNotiflix shouldLoad={shouldLoad} />
    </AdvancedContainer>
  );
};

export default Advanced;
