import styled from 'styled-components';

// Theming
import { devices } from '../../theme';

// Assets
import Register from '@images/register-img.svg';
import { Link } from 'react-router-dom';

export const StyledWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: url(${Register}), rgba(255, 255, 255, 0.8);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${devices.lg} {
    background: none;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  // border: solid red 1px;

  @media ${devices.lg} {
    width: 100%;
    min-height: 100vh;
  }
`;

export const StyledMain = styled.main`
  width: 100%;
  //   padding-top: 50px;
  max-width: 500px;
  margin: 0 auto;
  /* border: solid red 1px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  @media ${devices.lg} {
    flex-basis: 55%;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const StyledLogo = styled.img`
  width: 60px;
  margin: auto;
`;

export const StyledHeading = styled.h1`
  margin-bottom: 30px;
  margin-top: 50px;
  color: ${(props) => props.theme.colors.brandGreen};
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledFormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;
export const StyledFooterText = styled.span`
  font-size: small;
`;
export const StyledFooterLink = styled(Link)`
  color: ${(props) => props.theme.colors.brandGreen};
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.brandGreen};
  }
`;

export const StyledButton = styled.button`
  padding: 10px 15px;
  background-color: ${(props) => props.theme.colors.brandGreen};
  width: 100%;
  height: 55px;
  color: white;
  font-size: 1em;
  border-radius: 10px;
  cursor: pointer;
  outline: 0;
  border: 0;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
`;

export const StyledAside = styled.aside`
  @media ${devices.lg} {
    flex-basis: 45%;
    background: url(${Register});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;
