import React, { useContext, useEffect, useState } from 'react';
import Notiflix from 'notiflix';

import {
  BusinessGrossSales,
  BusinessNetSales,
  BusinessProductSales,
  BusinessSales,
} from './StyledBusinessData';

// Assets
import GrossBG from '@images/gross-bg.png';
import GrossProduct from '@images/gross-product.svg';
import TransactionProduct from '@images/transaction-product.svg';
import SoldProduct from '@images/sold-product.svg';
import Transaction from '@images/transaction.svg';
import { AppContext } from 'context/store';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';

Notiflix.Block.Init({
  zindex: 0,
  backgroundColor: 'transparent',
});

const BusinessData = () => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    Notiflix.Block.Pulse('.sales__data');
  }

  const {
    store: { token, dashboard },
    actions: { setDashboardData },
  } = useContext(AppContext);

  useEffect(() => {
    const getDashboardData = () => {
      setLoading(true);
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios({
            method: 'GET',

            url: `${BASE_URL}/dashboard/`,
            headers: {
              authorization: `token ${token}`,
            },
          });

          setDashboardData(response.data);
        } catch (e) {
          reject(e);
        }
        setLoading(false);
      });
    };
    getDashboardData();
  }, [token, setDashboardData]);

  return (
    <BusinessSales>
      <BusinessNetSales>
        <p>Net Sales</p>
        {!loading ? (
          <h3>{dashboard.net_sales ? `$${dashboard.net_sales}` : `$0`}</h3>
        ) : (
          <h3 className="sales__data">Loading</h3>
        )}
      </BusinessNetSales>
      <BusinessGrossSales bgImage={GrossBG}>
        <p>Gross Profit</p>
        {!loading ? (
          <h3>{dashboard.net_sales ? `$${dashboard.net_sales}` : `$0`}</h3>
        ) : (
          <h3 className="sales__data">Loading</h3>
        )}
      </BusinessGrossSales>
      <BusinessProductSales bgColor="80, 207, 70" bgImage={Transaction}>
        {!loading ? (
          <h3>{dashboard.transactions || `0`}</h3>
        ) : (
          <h3 className="sales__data">Loading</h3>
        )}
        <p>Transactions</p>
      </BusinessProductSales>
      <BusinessProductSales bgColor="148, 0, 211" bgImage={SoldProduct}>
        {!loading ? (
          <h3>{dashboard.products_sold ? `$${dashboard.products_sold}` : 0}</h3>
        ) : (
          <h3 className="sales__data">Loading</h3>
        )}
        <p>Products Sold</p>
      </BusinessProductSales>
      <BusinessProductSales bgColor="50, 69, 244" bgImage={GrossProduct}>
        {!loading ? (
          <h3>{dashboard.net_sales ? `$${dashboard.net_sales}` : `$0`}</h3>
        ) : (
          <h3 className="sales__data">Loading</h3>
        )}
        <p>Gross Profit Margin</p>
      </BusinessProductSales>
      <BusinessProductSales bgColor="195, 107, 60" bgImage={TransactionProduct}>
        {!loading ? (
          <h3>{dashboard.low_inventory || `$0`}</h3>
        ) : (
          <h3 className="sales__data">Loading</h3>
        )}
        <p>Low Inventory prod.</p>
      </BusinessProductSales>
    </BusinessSales>
  );
};

export default BusinessData;
