import React, { useContext, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';

// styled components
import { HomeHeader, HomeContainer, HomeCharts, HomeMain } from './StyledHome';

// components
import BarChart from 'components/BarChart';
import PieChart from 'components/PieChart';
import BusinessData from '../shared/BusinessData';

// context
import { AppContext } from 'context/store';

import Report from '@images/dashboard-report.svg';
import { Link } from 'react-router-dom';
import { getCategoryData, getPriceData, getProductData } from './helpers';

const Home = () => {
  const [productSold, setProductSold] = useState({});
  const [amountMade, setAmountMade] = useState({});
  const [activeCategories, setActiveCategories] = useState([]);

  const {
    actions: { setSidebar },
    store: { token },
  } = useContext(AppContext);

  useEffect(() => {
    setSidebar('Home');
  }, [setSidebar]);

  useEffect(() => {
    getCategoryData(token).then((data) => {
      const mainCategories = Object.keys(data).map((item, index) => {
        return {
          x: index + 1,
          y: data[item],
          label: item,
        };
      });
      setActiveCategories(mainCategories);
    });

    getProductData(token).then((data) => {
      setProductSold(data);
    });
    getPriceData(token).then((data) => {
      setAmountMade(data);
    });
  }, [token]);

  return (
    <HomeContainer>
      <HomeHeader>
        <span>Dashboard</span>
        <Tooltip content="View full report">
          <Link to="/dashboard/report">
            <img src={Report} alt="" />
          </Link>
        </Tooltip>
      </HomeHeader>
      <HomeMain>
        <HomeCharts>
          <BarChart
            title="Sales Last Week vs Sales Two Weeks Ago"
            colors={['#0B4317', '#CD801F']}
            money
            dataOne={[
              {
                x: 'Mon',
                y: Number(amountMade.mondayy?.lastweek || 0),
              },
              {
                x: 'Tue',
                y: Number(amountMade.tuesday?.lastweek || 0),
              },
              {
                x: 'Wed',
                y: Number(amountMade.wednesday?.lastweek || 0),
              },
              {
                x: 'Thur',
                y: Number(amountMade.thursday?.lastweek || 0),
              },
              {
                x: 'Fri',
                y: Number(amountMade.friday?.lastweek || 0),
              },
            ]}
            dataTwo={[
              {
                x: 'Mon',
                y: Number(amountMade.monday?.two_weeks || 0),
              },
              {
                x: 'Tue',
                y: Number(amountMade.tuesday?.two_weeks || 0),
              },
              {
                x: 'Wed',
                y: Number(amountMade.wednesday?.two_weeks || 0),
              },
              {
                x: 'Thur',
                y: Number(amountMade.thursday?.two_weeks || 0),
              },
              {
                x: 'Fri',
                y: Number(amountMade.friday?.two_weeks || 0),
              },
            ]}
          />
          <BarChart
            title="Transactions"
            colors={['#50CF46', 'rgba(148, 0, 211, 0.2)']}
            dataOne={[
              {
                x: 'Mon',
                y: Number(productSold.monday?.lastweek) || 0,
              },
              {
                x: 'Tue',
                y: Number(productSold.tuesday?.lastweek) || 0,
              },
              {
                x: 'Wed',
                y: Number(productSold.wednesday?.lastweek) || 0,
              },
              {
                x: 'Thur',
                y: Number(productSold.thursday?.lastweek) || 0,
              },
              {
                x: 'Fri',
                y: Number(productSold.friday?.lastweek) || 0,
              },
            ]}
            dataTwo={[
              {
                x: 'Mon',
                y: Number(productSold.monday?.lastweek) || 0,
              },
              {
                x: 'Tue',
                y: Number(productSold.tuesday?.lastweek) || 0,
              },
              {
                x: 'Wed',
                y: Number(productSold.wednesday?.lastweek) || 0,
              },
              {
                x: 'Thur',
                y: Number(productSold.thursday?.lastweek) || 0,
              },
              {
                x: 'Fri',
                y: Number(productSold.friday?.lastweek) || 0,
              },
            ]}
          />
          <PieChart data={activeCategories} />
        </HomeCharts>
        <BusinessData />
      </HomeMain>
    </HomeContainer>
  );
};

export default Home;
