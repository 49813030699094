import styled from 'styled-components';

// Theming
import { devices } from '../../../theme';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HomeHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  position: relative;
  top: -50px;
  width: max-content;
  margin: 0 auto;

  img {
    width: 48px;
    cursor: pointer;
  }

  span {
    margin-bottom: 50px;
  }

  @media ${devices.lg} {
    flex-direction: row;
    width: 100%;
    top: 0;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const HomeMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HomeCharts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  /* border: solid red 1px; */
  & > div {
    margin-bottom: 70px;
  }

  @media ${devices.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
