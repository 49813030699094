import styled from 'styled-components';

export const PieChartContainer = styled.div`
  width: 100%;
  height: 250px;
  /* border: solid red 1px; */
  text-align: center;

  svg {
    pointer-events: none !important;
  }

  & > span {
    text-transform: uppercase;
    font-size: 0.8em;
  }
`;
