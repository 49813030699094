import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Theming
import { devices } from '../../theme';

export const SidebarContainer = styled.div`
  width: 100px;
  min-height: 100vh;
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.brandGreen};

  @media ${devices.llg} {
    display: none;
  }
`;

export const SidebarBrand = styled.div`
  text-align: center;
  padding: 20px 0 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    width: 70px;
    text-align: center;
  }

  & > img {
    width: 130px;
    margin-bottom: 15px;
  }
`;

export const SidebarMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SidebarItem = styled(Link)`
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  align-items: center;

  border-left: ${(props) =>
    props.activetab
      ? `5px solid ${props.theme.colors.brandYellow}`
      : 'solid 1px transparent'};
  flex-direction: column;

  & > i {
    cursor: pointer;
    color: white;
    position: relative;

    &:hover {
      & + .tooltiptext {
        color: white;
      }
    }
  }

  & > i {
    .iconify {
      width: 35px;
      height: 35px;
      background: transparent;
    }
  }

  & {
    text-decoration: none;
  }

  .tooltiptext {
    color: white;
  }
`;
