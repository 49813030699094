import React from "react";

import SuccessImg from "@images/payment-success.svg";
import { Button, CenteredContainer, SalesPageContainer } from "shared/styles";

const Success = () => {
  return (
    <SalesPageContainer>
      <CenteredContainer>
        <img alt="" src={SuccessImg} />
        <h2>Payment Successful</h2>
        <Button>Done</Button>
      </CenteredContainer>
    </SalesPageContainer>
  );
};

export default Success;
