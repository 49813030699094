import styled from 'styled-components';

const activeTransition = ` 
   top: 0;
   background-color: white;
   background: ${(props) =>
     props.theme.colors.brandOffGreen}, rgba(255, 255, 255);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
   background-position: 1px;
   opacity:1;
   color: black;
   font-size: small;
   font-weight:600;
   line-height: 2px;
`;

export const StyledFormGroup = styled.div`
  position: relative;
  font-size: 0.9em;
  margin-bottom: 35px;
`;

export const StyledInput = styled.input`
  height: 55px;
  outline: 0;
  border: solid 1px ${(props) => props.theme.colors.brandGreen};
  padding: 0 20px;
  color: ${(props) => props.theme.colors.brandOffGreen} !important;
  border-radius: 10px;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: color 5000s ease-in-out 0s;
  }
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  margin-left: 15px;
  padding: 0 5px;
  color: #c4c4c4;
  cursor: text;

  ${(props) =>
    !props.required
      ? null
      : `
    
    &:after{
     
    content:" *";
    color: red;

    }
    `}

  ${(props) => props.shouldMove && activeTransition}
`;
