import styled from "styled-components";

// Theming
import { devices } from "../../theme";

export const SearchBoxContainer = styled.form`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
  border: 1px solid rgba(39, 78, 36, 0.8);
  padding: 10px 10px;
  border-radius: 5px;
  height: 100%;

  input {
    max-width: 100%;
    font-size: 1em;
  }

  @media ${devices.lsm} {
    /* margin-right: 30px; */
  }

  input {
    border: 0;
    outline: 0;
    flex-grow: 1;
    width: calc(100% - 16px);
  }
  i {
    cursor: pointer;
    .iconify {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      position: relative;
      top: 2px;
    }
  }
`;
