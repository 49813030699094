import React, { useEffect, useRef } from 'react';

import { TableContainer, TableTd } from './StyledTable';

const Table = ({
  data,
  onClickTableItem,
  deleteExternal,
  scrollTop,
  emptyText,
  hasQuantity,
  addFunc,
  removeFunc,
  scrollToBottom,
  dontshow,
}) => {
  const ref = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    if (scrollTop) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    if (scrollToBottom) {
      ref2.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [scrollTop, scrollToBottom]);

  return (
    <TableContainer colors={data.colors}>
      <thead>
        <tr>
          {data.head.map((item, index) => (
            <th key={index}> {item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!data.body ? (
          <tr>
            <td>
              <em>loading...</em>
            </td>
          </tr>
        ) : (
          <>
            {data.body.length === 0 ? (
              <tr>
                <td>
                  <em>{emptyText || `No item available`}</em>
                </td>
              </tr>
            ) : (
              <>
                {data.body &&
                  data.body.map((entry, index) => {
                    return (
                      <tr
                        key={index}
                        ref={
                          index === 0
                            ? ref
                            : index === data.body.length - 1
                            ? ref2
                            : undefined
                        }
                      >
                        {data.head.map((item, index) => {
                          let val = entry[data.variables[index]];

                          if (index === 0) {
                            return (
                              <TableTd
                                key={index}
                                isName={index === 0}
                                onClick={() => {
                                  onClickTableItem &&
                                    onClickTableItem(entry, true);
                                }}
                              >
                                {val || '-'}
                              </TableTd>
                            );
                          }
                          if (index === data.head.length - 1) {
                            return (
                              <TableTd
                                key={index}
                                icon={index === data.head.length - 1}
                                className="table-actions"
                              >
                                <>
                                  {hasQuantity ? (
                                    <>
                                      <i onClick={() => removeFunc(entry)}>
                                        <span
                                          className="iconify"
                                          data-icon="gg:remove"
                                          data-inline="false"
                                        ></span>
                                      </i>
                                      <i onClick={() => addFunc(entry)}>
                                        <span
                                          className="iconify"
                                          data-icon="ant-design:plus-circle-outlined"
                                          data-inline="false"
                                        ></span>
                                      </i>
                                    </>
                                  ) : (
                                    <i onClick={() => onClickTableItem(entry)}>
                                      <span
                                        className="iconify edit"
                                        data-icon="bytesize:edit"
                                        data-inline="false"
                                        style={
                                          dontshow && {
                                            display: 'none',
                                          }
                                        }
                                      ></span>{' '}
                                    </i>
                                  )}
                                </>

                                {deleteExternal && (
                                  <i
                                    onClick={() => {
                                      deleteExternal(entry);
                                    }}
                                  >
                                    <span
                                      className="iconify delete-icon"
                                      data-icon="fluent:delete-16-filled"
                                      data-inline="false"
                                    ></span>
                                  </i>
                                )}
                              </TableTd>
                            );
                          }

                          if (
                            data.variables[index] === 'cost' ||
                            data.variables[index] === 'price'
                          ) {
                            return (
                              <TableTd key={index} isName={index === 0}>
                                ${val || '-'}
                              </TableTd>
                            );
                          }
                          if (typeof val === 'object' && val !== null) {
                            return (
                              <TableTd key={index} isName={index === 0}>
                                {val.name}
                              </TableTd>
                            );
                          }
                          return (
                            <TableTd key={index} isName={index === 0}>
                              {val || '-'}
                            </TableTd>
                          );
                        })}
                      </tr>
                    );
                  })}
              </>
            )}
          </>
        )}
      </tbody>
    </TableContainer>
  );
};

export default Table;
