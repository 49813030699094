import styled from "styled-components";
import { devices } from "theme";

export const ReportContainer = styled.div``;

export const ReportHeader = styled.div`
  & > h1 {
    width: max-content;
    font-size: 1.5em;
    margin: 0 auto;
    position: relative;
    top: -50px;
  }

  @media ${devices.lg} {
    & > h1 {
      top: 0;
      margin: 0;
      margin-right: auto;
      margin-bottom: 50px;
    }
  }
`;

export const ReportData = styled.div`
  margin-bottom: 100px;
`;
