import React from 'react';

// external libraries
import { VictoryPie } from 'victory';

// import styled components
import { PieChartContainer } from './StyledPieChart';

const PieChart = ({ data }) => {
  const refinedData = data.filter((item) => item.y !== 0);
  return (
    <PieChartContainer>
      <span>
        {refinedData.length === 1 ? 'Top Category' : 'Top Categories'}
      </span>
      {/* {data && ( */}
      <>
        <VictoryPie
          data={refinedData}
          innerRadius={100}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          colorScale={['#4E2433', '#CD801F', '#50CF46', '#3245F4', '#274E24']}
        />
      </>
      {/* )} */}
    </PieChartContainer>
  );
};

export default PieChart;
