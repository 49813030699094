import styled from 'styled-components';

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;

  

  thead{    
    th {
    text-align: left;
    font-size: 0.95em; 
    padding: 10px; 
    margin-bottom: 300px;
    background-color: ${(props) => props.theme.colors.brandGreen};
    color: white;
    opacity:1;
    position:sticky; 
    top:0;
  }
  th:first-of-type{
   position:sticky;
   left:0;
   top:0; 
   z-index: 1;
   background: ${(props) => props.theme.colors.brandGreen};
  }
  th:last-of-type{ 
   z-index: 1;
  }

  th:last-of-type{
    text-align: center;
  }

  }

 tr:not(:last-of-type) td{
   border-bottom: 1px solid black;
 
 }

  td{
    padding: 20px 10px;
  }
  td:first-of-type{
    position:sticky; 
    left:0; 
   background:white;

  }

  tr {
    *:last-of-type {
      padding-right: 0;
    }
    *
  }
`;

export const TableTd = styled.td`
  padding: 20px 30px 20px 0;

  .iconify {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    padding: 3px;
    cursor: pointer;
  }

  .edit {
    background-color: rgba(50, 69, 244, 0.26);
  }
  .delete-icon {
    color: #f82b2b;
    background: rgba(248, 43, 43, 0.39);
    margin-left: 10px;
  }

  ${(props) =>
    props.isName &&
    `
    color: ${props.theme.colors.brandOffGreen};
    cursor: pointer;
    &:hover{
      color: ${props.theme.colors.brandGreen};
    }
    `}

  ${(props) => props.icon && `text-align: center`}
`;
