import React, { useState, useRef } from 'react';
// import qs from 'qs';

// External Libraries
import { withRouter } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

// Styled components
import {
  StyledContainer,
  StyledAside,
  StyledFooter,
  StyledFormHeader,
  StyledLogo,
  StyledMain,
  StyledFooterText,
  StyledHeading,
  StyledWrapper,
} from './StyledActivate';

// Components
import { FormErrorMessage, NormalButton, StyledLink } from 'shared/styles';

// Assets
import Logo from '@images/logo2.svg';
import FormInput from 'components/FormInput';
import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';
import { NotificationManager } from 'react-notifications';
import { customErrorMessage } from 'shared/helpers';

const Activate = ({ history }) => {
  // const { search } = useLocation();
  // const { email } = qs.parse(search, { ignoreQueryPrefix: true });

  // Component State
  const [formError, setFormError] = useState('');
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');

  // Loader reference
  const ref = useRef(null);

  // registeration
  const handleActivate = async (e) => {
    e.preventDefault();
    ref.current.continuousStart();
    try {
      await axios({
        method: 'POST',
        url: `${BASE_URL}/auth/activation/`,
        data: {
          email,
          otp,
        },
      });
      NotificationManager.success(
        'Account successfully activated, you can now login',
        'Success',
        7000
      );
      ref.current.complete();
      history.push('/login');
    } catch (e) {
      const { errorBody } = customErrorMessage(e);
      setFormError(errorBody);
      ref.current.complete();
    }
  };

  return (
    <StyledWrapper>
      <LoadingBar color="#CD801F" ref={ref} />
      <StyledContainer>
        <StyledMain>
          <StyledFormHeader>
            <StyledLogo src={Logo} alt="logo" />
            <StyledHeading>Activate your Account</StyledHeading>
          </StyledFormHeader>

          <form onSubmit={handleActivate}>
            {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
            <FormInput
              label="Email"
              required
              setInputValue={setEmail}
              inputValue={email}
            />
            <FormInput
              label="OTP"
              required
              setInputValue={setOtp}
              inputValue={otp}
            />
            <StyledFooter>
              <StyledFooterText>
                Already activated? <StyledLink to="/login">login</StyledLink>
              </StyledFooterText>
              <NormalButton
                disabled={!otp}
                type="submit"
                onClick={handleActivate}
              >
                Activate
              </NormalButton>
            </StyledFooter>
          </form>
        </StyledMain>
        <StyledAside></StyledAside>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default withRouter(Activate);
