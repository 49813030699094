import React, { useContext, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import Notiflix from 'notiflix';
import ImageUploading from 'react-images-uploading';

// shared styled components
import { Button, DoubleItems, RightFooter } from 'shared/styles';

// components
import FormInput from 'components/FormInput';
import Dropdown from 'components/Dropdown';

// API
import { createInventoryItem } from './api';

// helpers
import { customError } from 'shared/helpers';
import { AppContext } from 'context/store';
import { UploadButton, RemoveButton, UpdateButton } from './StyledInventory';

const AddInventory = ({ closeModal, fetchInventory }) => {
  // state
  const [product, setProduct] = useState('');
  const [botanicalName, setBotanicalName] = useState('');
  const [classification, setClassification] = useState('');
  const [category, setCategory] = useState('');
  const [color, setColor] = useState('');
  const [quantity, setQuantity] = useState('');
  const [lot, setLot] = useState('');
  const [size, setSize] = useState('');
  const [location, setLocation] = useState('');
  const [cost, setCost] = useState('');
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState('');
  const [image, setImage] = React.useState([]);

  const maxNumber = 1;

  const onImageUploadChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImage(imageList);
  };

  // context
  const {
    store: {
      token,
      totalCategories,
      totalClassifications,
      totalLots,
      totalLocations,
    },
  } = useContext(AppContext);

  // loading
  if (loading) {
    Notiflix.Loading.Circle();
  } else {
    Notiflix.Loading.Remove();
  }

  // constants
  const disableCreateItem = !product || loading;

  const data = {
    name: product,
    botanicalName,
    category,
    color,
    classification,
    quantity,
    lot,
    size,
    location,
    cost,
    price,
    image: image[0] && image[0].file ? image[0].file : undefined,
  };

  const createItem = async (e) => {
    const formData = new FormData();
    const newCost = Number(cost) ? Number(cost).toFixed(2) : cost;
    const newPrice = Number(price) ? Number(price).toFixed(2) : price;

    formData.append('name', product);
    formData.append('botanical_name', botanicalName);
    formData.append('category', category);
    formData.append('color', color);
    formData.append('classification', classification);
    formData.append('quantity', quantity);
    formData.append('lot', lot);
    formData.append('size', size);
    formData.append('location', location);
    formData.append('cost', newCost);
    formData.append('price', newPrice);
    formData.append('image', data.image);

    // console.log(data.image);
    setLoading(true);
    try {
      await createInventoryItem(token, formData);
      setLoading(false);
      NotificationManager.success('Item successfully created', 'Success');
      closeModal();
      fetchInventory();
    } catch (e) {
      setLoading(false);
      customError(e);
    }
  };

  return (
    <form>
      <DoubleItems>
        <FormInput
          label="Product Name"
          type="name"
          inputValue={product}
          setInputValue={setProduct}
          required
        />
        <FormInput
          label="Botanical Name"
          type="name"
          inputValue={botanicalName}
          setInputValue={setBotanicalName}
        />
      </DoubleItems>

      <DoubleItems>
        <Dropdown
          defaulty="Select"
          label="Classification"
          required
          data={totalClassifications.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          })}
          func={(item) => setClassification(item.id)}
        />
        <Dropdown
          defaulty="Select"
          label="Category"
          required
          data={totalCategories.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          })}
          func={(item) => setCategory(item.id)}
        />
      </DoubleItems>

      <FormInput
        label="Color"
        type="name"
        inputValue={color}
        setInputValue={setColor}
      />

      <FormInput
        label="Size"
        type="name"
        inputValue={size}
        setInputValue={setSize}
        required
      />
      <FormInput
        label="Quantity"
        type="name"
        inputValue={quantity}
        setInputValue={setQuantity}
        required
      />

      <DoubleItems>
        <Dropdown
          defaulty="Select"
          label="Location"
          required
          data={totalLocations.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          })}
          func={(item) => setLocation(item.id)}
        />

        <Dropdown
          defaulty="Select"
          label="Lot"
          required
          data={totalLots.map((item) => ({
            id: item.id,
            name: item.name,
          }))}
          func={(item) => setLot(item.id)}
        />
      </DoubleItems>

      <DoubleItems>
        <FormInput
          label="Cost"
          type="number"
          inputValue={cost}
          setInputValue={setCost}
          required
        />
        <FormInput
          label="Price"
          type="number"
          inputValue={price}
          setInputValue={setPrice}
          required
        />
      </DoubleItems>
      <h3>Upload Image</h3>
      <ImageUploading
        multiple
        value={image}
        onChange={onImageUploadChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <UploadButton
              style={isDragging ? { color: 'red' } : undefined}
              onClick={(e) => {
                e.preventDefault();
                onImageUpload();
              }}
              {...dragProps}
            >
              Click or Drop here
            </UploadButton>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <UpdateButton
                    onClick={(e) => {
                      e.preventDefault();
                      onImageUpdate(index);
                    }}
                  >
                    Update
                  </UpdateButton>
                  <RemoveButton
                    onClick={(e) => {
                      e.preventDefault();
                      onImageRemove(index);
                    }}
                  >
                    Remove
                  </RemoveButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
      <RightFooter>
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (!disableCreateItem) {
              createItem();
            } else if (loading) {
              NotificationManager.warning('Please wait...');
            } else {
              NotificationManager.warning('Please fill required fields');
            }
          }}
        >
          Create
        </Button>
      </RightFooter>
    </form>
  );
};

export default AddInventory;
