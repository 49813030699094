import styled from 'styled-components';

export const TabContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 17px;
  border-bottom: solid 1px black;
`;

export const TabContent = styled.div`
  width: max-content;
  margin-left: auto;
`;

export const TabItem = styled.span`
  padding-bottom: 15px;
  font-weight: bold;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-left: 50px;
  }

  ${(props) =>
    props.active &&
    `
    border-bottom: solid ${props.theme.colors.brandGreen} 5px`}
`;
