const { default: axios } = require('axios');
const { BASE_URL } = require('utils/api/constants');

export const getCategoryData = (token) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/transaction/category`,
    headers: {
      authorization: `token ${token}`,
    },
  }).then((res) => res.data);
};

export const getPriceData = (token) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/transaction/price`,
    headers: {
      authorization: `token ${token}`,
    },
  }).then((res) => res.data);
};

export const getProductData = (token) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/transaction/product`,
    headers: {
      authorization: `token ${token}`,
    },
  }).then((res) => res.data);
};
