import React from "react";
import { StyledCard, CardText, CardCta } from "./StyledCard";

const Card = ({ content, type, cta }) => {
  // console.log(type);
  return (
    <StyledCard>
      <CardText>{content}</CardText>
      <CardCta type={type}>{cta}</CardCta>
    </StyledCard>
  );
};

export default Card;
