import styled from "styled-components";

const colors = {
  SALES: "rgba(80, 207, 70, 0.44)",
  INVENTORY: "rgba(148, 0, 211, 0.2)",
};

export const StyledCard = styled.div`
  padding: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
`;

export const CardText = styled.span`
  margin-right: 15px;
  word-break: break-all;
`;
export const CardCta = styled.span`
  background-color: ${(props) => (props.type ? colors[props.type] : "#3245F4")};
  padding: 10px 15px;
  border-radius: 15px;
  cursor: pointer;
`;
