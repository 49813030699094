import axios from 'axios';
import { BASE_URL } from 'utils/api/constants';

export const getActivities = async (token, page = 1) => {
  const response = await axios.get(`${BASE_URL}/activities/?page=${page}`, {
    headers: {
      authorization: `token ${token}`,
    },
  });
  return response.data;
};
