import axios from 'axios';
import FormInput from 'components/FormInput';
import CustomModal from 'components/Modal';
import CustomNotiflix from 'components/Notiflix';
import Tabs from 'components/Tabs';
import { AppContext } from 'context/store';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { customError } from 'shared/helpers';
import { Button, RightFooter } from 'shared/styles';
import { BASE_URL } from 'utils/api/constants';
import {
  getAllLocations,
  getAllCategories,
  getAllClassifications,
  getAllLots,
  getAllTaxes,
  getAllDiscounts,
} from '../Inventory/helpers';

import Categories from './Categories';
import Classifications from './Classifications';
import Discounts from './Discounts';
import Locations from './Locations';
import Lots from './Lots';

import {
  InventoryOptionsHeader,
  InventoryOptionContent,
} from './styledInventoryOptions';
import Taxes from './Tax';

const InventoryOptions = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [newItemDescription, setNewItemDescription] = useState(undefined);
  const [newItemPercentage, setNewItemPercentage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const {
    store: { token },
    actions: {
      setCategories,
      setLocations,
      setLots,
      setClassifications,
      setSidebar,
      setTaxes,
      setDiscounts,
    },
  } = useContext(AppContext);

  useEffect(() => {
    setSidebar(null);
  }, [setSidebar]);

  const optionsList = [
    {
      title: 'Categories',
      name: 'categories',
      singular: 'Category',
      content: <Categories />,
      getter: getAllCategories,
      setter: setCategories,
    },
    {
      title: 'Classifications',
      name: 'classifications',
      singular: 'Classification',
      content: <Classifications />,
      getter: getAllClassifications,
      setter: setClassifications,
    },
    {
      title: 'Lots',
      name: 'lots',
      singular: 'Lot',
      content: <Lots />,
      getter: getAllLots,
      setter: setLots,
    },
    {
      title: 'Locations',
      name: 'locations',
      singular: 'Location',
      content: <Locations />,
      getter: getAllLocations,
      setter: setLocations,
    },
    {
      title: 'Taxes',
      name: 'taxes',
      singular: 'Tax',
      content: <Taxes />,
      getter: getAllTaxes,
      setter: setTaxes,
      hasOthers: true,
    },
    {
      title: 'Discounts',
      name: 'discounts',
      singular: 'Discount',
      content: <Discounts />,
      getter: getAllDiscounts,
      setter: setDiscounts,
      hasOthers: true,
    },
  ];

  const setItem = () => {
    const { getter, setter } = optionsList[activeTab];
    getter(token).then((res) => setter(res));
  };

  const closeModal = () => {
    setIsOpen(false);
    setNewItemName('');
  };
  const createNewItem = () => {
    setIsLoading(true);

    axios({
      method: 'POST',
      url: `${BASE_URL}/${optionsList[activeTab].name}/`,
      headers: {
        authorization: `token ${token}`,
      },
      data: {
        name: newItemName,
        description: newItemDescription,
        percentage: newItemPercentage,
      },
    })
      .then(() => {
        setItem();
        NotificationManager.success(
          `${optionsList[activeTab].singular} successfully created`,
          '',
          5000
        );
      })
      .catch((e) => customError(e))
      .finally(() => {
        setIsLoading(false);
        setNewItemName('');
        setNewItemDescription('');
        setNewItemPercentage('');
        setIsOpen(false);
      });
  };

  return (
    <div>
      <InventoryOptionsHeader>
        <Button active onClick={() => setIsOpen(true)}>
          Add
        </Button>
      </InventoryOptionsHeader>
      <Tabs active={activeTab} setTab={setActiveTab} data={optionsList} />
      <InventoryOptionContent>
        {optionsList[activeTab].content}
      </InventoryOptionContent>
      <CustomModal isOpen={isOpen} onRequestClose={closeModal}>
        <h3>New {optionsList[activeTab].singular}</h3>
        <FormInput
          inputValue={newItemName}
          setInputValue={setNewItemName}
          label="Name"
        />
        {optionsList[activeTab].hasOthers ? (
          <>
            <FormInput
              inputValue={newItemDescription}
              setInputValue={setNewItemDescription}
              label="Description"
            />
            <FormInput
              inputValue={newItemPercentage}
              setInputValue={setNewItemPercentage}
              label="Percentage (%)"
            />
          </>
        ) : null}
        <RightFooter>
          <Button active onClick={createNewItem}>
            Create
          </Button>
        </RightFooter>
      </CustomModal>
      <CustomNotiflix shouldLoad={isLoading} />
    </div>
  );
};

export default InventoryOptions;
