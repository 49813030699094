import React from 'react';

//styled components
import { SearchBoxContainer } from './StyledSearchBox';

const SearchBox = ({ inputValue, setInputValue, onSubmit, placeholder }) => {
  return (
    <SearchBoxContainer
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <i
        onClick={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <span
          className="iconify"
          data-icon="ant-design:search-outlined"
          data-inline="false"
        ></span>
      </i>
      <input
        placeholder={placeholder || `Search`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      ></input>
    </SearchBoxContainer>
  );
};

export default SearchBox;
