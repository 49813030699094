import React from "react";

import { Button, CenteredContainer, SalesPageContainer } from "shared/styles";

import ErrorImg from "@images/404.png";

const Error = () => {
  return (
    <SalesPageContainer>
      <CenteredContainer>
        <img src={ErrorImg} alt="" />
        <h2>Device not found</h2>
        <Button>Try Again</Button>
      </CenteredContainer>
    </SalesPageContainer>
  );
};

export default Error;
